"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
        flexDirection: 'column',
        overflowX: 'scroll',
        overflowY: 'clip',
        paddingLeft: 0,
    },
    whiteBackground: {
        height: '100%',
        backgroundColor: '#fff',
    },
    greenDotsContainer: {
        position: 'absolute',
        marginVertical: '70%',
        marginHorizontal: '40%',
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    timeline: {
        height: '100%',
        width: 3,
        backgroundColor: '#3db384',
        position: 'absolute',
        top: 72,
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 0,
        color: '#fff',
        textAlign: 'center',
    },
    heading3: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 7.5,
        color: '#fff',
        textAlign: 'center',
        paddingHorizontal: '8%',
        textTransform: 'uppercase',
    },
    text: {
        color: '#fff',
        textAlign: 'center',
        paddingHorizontal: '8%',
    },
    current: {
        backgroundColor: '#3db384',
        marginTop: 0,
        height: '100vh',
        paddingHorizontal: 40,
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderColor: '#fff',
        paddingTop: 129,
        position: 'relative',
        width: 650,
        marginRight: 100,
    },
    mobileCurrent: {
        backgroundColor: '#3db384',
        width: '100%',
        height: 400,
        borderRadius: 200,
        paddingVertical: 48,
        paddingHorizontal: 20,
        marginTop: 40,
    },
    mobileCurrentHeader: {
        alignItems: 'center',
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#fff',
        paddingVertical: 8,
        paddingHorizontal: 30,
        textTransform: 'uppercase',
        marginVertical: 10,
        borderRadius: 25,
        alignSelf: 'center',
        overflow: 'hidden',
    },
    button1: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        paddingVertical: 8,
        paddingHorizontal: 30,
        textTransform: 'uppercase',
        marginVertical: 10,
        borderRadius: 25,
        alignSelf: 'center',
        overflow: 'hidden',
        borderColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    helpButton: {
        backgroundColor: '#3db384',
    },
    buttonInfo: {
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
        paddingVertical: 4,
    },
    buttonText: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#3db384',
    },
    buttonText1: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
    },
    helpButtonText: {
        color: '#fff',
    },
    loadingBar: {
        position: 'relative',
        width: '84%',
        paddingVertical: 5,
        backgroundColor: '#fff',
        marginTop: '8%',
        marginBottom: '4%',
        marginLeft: '8%',
    },
    progressBar: {
        position: 'absolute',
        top: 2,
        left: 2,
        backgroundColor: '#3db384',
        paddingVertical: 3,
    },
    appointmentContainer: {
        position: 'relative',
    },
    mobileCalendar: {
        marginVertical: 20,
        marginHorizontal: 40,
    },
    appointmentDay: {
        left: 0,
        right: 0,
        top: 20,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileAppointment: {
        display: 'flex',
        marginLeft: 80,
    },
    mobileAdresse: {
        display: 'flex',
        flexDirection: 'row',
    },
    addresseText: {
        color: '#fff',
    },
    mobileDivider: {
        height: 1,
        width: '90%',
        backgroundColor: '#fff',
        marginLeft: 20,
        marginBottom: 20,
    },
    mobileHelpContainer: {
        backgroundColor: '#efefef',
        marginVertical: 20,
        paddingTop: 40,
        display: 'flex',
        alignItems: 'center',
        height: 270,
    },
    mobileHelpHeader: {
        color: '#3db384',
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 15,
    },
    mobileHelpText: {
        width: '60%',
        color: '#3db384',
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 15,
    },
    containerHeader: {
        color: '#fff',
        fontWeight: 'bold',
        marginBottom: 2,
        width: 160,
    },
    greenText: {
        color: '#3db384',
        fontSize: 12,
        marginBottom: -3,
    },
    containerText: {
        color: '#fff',
        width: 170,
    },
    OfflineAppointmentContainerText: {
        color: '#ffffffb3',
        width: 35,
    },
    height40: {
        height: 40,
    },
    containerDivider: {
        flexDirection: 'row',
        marginVertical: 10,
    },
    divider: {
        borderColor: 'white',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        flex: 1,
        alignSelf: 'center',
    },
    textDivider: {
        color: 'white',
        paddingHorizontal: 10,
        alignSelf: 'center',
    },
});
