"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    view: {
        height: '100%',
        paddingStart: 25,
        paddingEnd: 25,
        marginTop: 50,
        backgroundColor: 'white',
    },
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop || Breakpoints_1.isPhablet ? 550 : '100%',
        alignItems: !Breakpoints_1.isDesktop ? 'center' : undefined,
    },
    row: {
        flexDirection: 'row',
    },
    firstLabel: {
        width: react_native_1.Platform.OS === 'web' && !Breakpoints_1.isDesktop ? '90%' : 'auto',
        marginBottom: 20,
    },
    marginTop20: {
        marginTop: 20,
    },
    inputContainer: {
        width: react_native_1.Platform.OS === 'web' && !Breakpoints_1.isDesktop ? '90%' : 'auto',
        marginVertical: 20,
        position: 'relative',
    },
    input: {
        marginRight: 0,
    },
    error: {
        color: 'red',
        position: 'absolute',
        width: '100%',
        top: 65,
    },
    success: {
        color: '#3db384',
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: -20,
        width: '100%',
        marginHorizontal: 'auto',
    },
    buttonContainer: {
        marginVertical: 20,
        marginHorizontal: 'auto',
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 30,
        paddingVertical: 10,
        minWidth: 250,
        textAlign: 'center',
    },
    buttonText: {
        color: '#fff',
    },
    backButtonText: {
        color: '#3db384',
    },
    saveMessageContainer: {
        textAlign: 'center',
        flexDirection: "row",
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: 200,
    },
    saveMessageText: {
        textAlign: 'center',
        color: '#3db384',
        position: 'absolute',
        left: 0,
        right: 0,
    },
});
