"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckCircle = void 0;
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
function CheckCircle({ size = 64, color = '#3DB384', styles }) {
    return (React.createElement(react_native_svg_1.default, { width: size, height: size, viewBox: "0 0 84 84", fill: color, style: styles },
        React.createElement(react_native_svg_1.G, { id: "03-Termin-buchen", stroke: "none", strokeWidth: 1, fill: "none", fillRule: "evenodd" },
            React.createElement(react_native_svg_1.G, { id: "01-Step-1-Copy-17", transform: "translate(-165.000000, -120.000000)", fill: color, fillRule: "nonzero" },
                React.createElement(react_native_svg_1.G, { id: "Group", transform: "translate(165.000000, 120.000000)" },
                    React.createElement(react_native_svg_1.Path, { d: "M63.8414053,23.047289 C64.367573,22.4074148 65.3128368,22.3152375 65.952711,22.8414053 C66.5525931,23.3346875 66.6711009,24.1963159 66.2500222,24.8293327 L66.1585947,24.952711 L37.3781395,59.952711 C36.8494297,60.5956766 35.9078433,60.678934 35.2761837,60.1664131 L35.1615559,60.0633248 L17.9420112,42.9301924 C17.3547531,42.3458813 17.3523641,41.3961369 17.9366752,40.8088788 C18.4844668,40.2583243 19.3534401,40.2218149 19.9438077,40.7008594 L20.0579888,40.8035427 L36.108,56.773 L63.8414053,23.047289 Z", id: "Stroke-1" }),
                    React.createElement(react_native_svg_1.Path, { d: "M42,0 C65.1959595,0 84,18.8040405 84,42 C84,65.1959595 65.1959595,84 42,84 C18.8040405,84 0,65.1959595 0,42 C0,18.8040405 18.8040405,0 42,0 Z M42,3 C20.4608948,3 3,20.4608948 3,42 C3,63.5391052 20.4608948,81 42,81 C63.5391052,81 81,63.5391052 81,42 C81,20.4608948 63.5391052,3 42,3 Z", id: "Oval" }))))));
}
exports.CheckCircle = CheckCircle;
