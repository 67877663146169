"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfoAboutMedication = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const react_1 = __importStar(require("react"));
const atome_1 = require("../../../atome");
const organisms_1 = require("../../../organisms");
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const AbortFUPModal_1 = require("./AbortFUPModal");
const hooks_1 = require("../../../../store/hooks");
const reducers_1 = require("../../../../store/reducers");
const UtilityFunctions_1 = require("../../../../helper/UtilityFunctions");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
const InfoAboutMedication = (props) => {
    const { navigation, setCurrentPage, showFUPRModal, setShowFUPRModal } = props;
    const userInformation = (0, hooks_1.useAppSelector)(reducers_1.selectUserInformation);
    const prescriptionInfo = (0, hooks_1.useAppSelector)(reducers_1.selectPrescriptionInfo);
    const [prescriptionTemp, setPrescriptionTemp] = (0, react_1.useState)(prescriptionInfo);
    const dispatch = (0, hooks_1.useAppDispatch)();
    const [isOnlineTerminModalVisible, setOnlineTerminModalVisible] = (0, react_1.useState)(false);
    const onOnlineTerminModalOk = () => {
        setOnlineTerminModalVisible(false);
        navigation.navigate('PatientenTracking', {
            redirectPage: 'OnlineAppointment',
        });
    };
    const onOnlineTerminModalCancel = () => setOnlineTerminModalVisible(false);
    const onlineAppointmentCheckAndRedirect = (0, react_1.useCallback)(() => {
        const WEEK_SPAN = 8.64e7 * 7; // <= milliseconds in a day * 7 days  = milliseconds in a week
        let newestDateEpoch = new Date(0).getTime();
        userInformation?.lifeQuality.forEach((row) => {
            let { queryDate } = row;
            let queryDateEpoch = new Date(queryDate).getTime();
            if (queryDateEpoch > newestDateEpoch) {
                newestDateEpoch = queryDateEpoch;
            }
        });
        let now = Date.now();
        let weekBeforeNow = now - WEEK_SPAN;
        if (newestDateEpoch <= weekBeforeNow) {
            setOnlineTerminModalVisible(true);
            return;
        }
        navigation.navigate('OnlineAppointment');
    }, [userInformation.lifeQuality]);
    const preparePreparationQuestionArrayForNextStep = () => {
        if ((0, UtilityFunctions_1.isInObject)(prescriptionInfo, 'preparationQuestion') &&
            prescriptionInfo.preparationQuestion.length) {
            return;
        }
        const lastMedication = userInformation?.medication[userInformation?.medication.length - 1];
        const preparationQuestion = [];
        lastMedication.preparationListItem.forEach(item => {
            const newObj = {
                preparationListItemId: item.id,
                dailyDosageMax: '',
                singleDosageMax: '',
            };
            preparationQuestion.push(newObj);
        });
        dispatch((0, reducers_1.setPrescriptionInfo)({
            ...prescriptionTemp,
            preparationQuestion: preparationQuestion,
        }));
    };
    const next = () => {
        preparePreparationQuestionArrayForNextStep();
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 3,
            },
        };
        setCurrentPage(reducerType);
    };
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(native_base_1.ScrollView, { bgColor: "#fff", h: "100%", w: "100%" },
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(native_base_1.VStack, { space: 5, alignItems: 'center' },
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "20" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "2xl", w: "80%", textAlign: "center", color: "#3db384", fontWeight: "bold" }, "Angaben zur Medikation")),
                react_1.default.createElement(native_base_1.Center, { mt: "3", w: "90%" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "sm", textAlign: "center" }, "Reicht Ihre Medikation f\u00FCr den verordneten Zeitraum (mindestens 30 Tage) aus?")),
                react_1.default.createElement(native_base_1.HStack, { space: 70, mt: "3" },
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: prescriptionTemp.isEnough, label: "Ja", onPress: () => setPrescriptionTemp({ ...prescriptionTemp, isEnough: true }) }),
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: (0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'isEnough') &&
                            !prescriptionTemp.isEnough, label: "Nein", onPress: () => setPrescriptionTemp({ ...prescriptionTemp, isEnough: false }) })),
                (0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'isEnough') &&
                    !prescriptionTemp.isEnough && (react_1.default.createElement(native_base_1.Center, { style: { borderWidth: 1, borderColor: '#3db384' }, mx: "3", py: "7", px: "10" },
                    react_1.default.createElement(native_base_1.VStack, null,
                        react_1.default.createElement(native_base_1.Center, null,
                            react_1.default.createElement(native_base_1.Text, { color: "#3db384", textAlign: "center" }, "F\u00FCr eine Dosiserh\u00F6hung m\u00FCssen Sie einen Termin zur Videosprechstunde mit Ihrem Arzt zur Anpassung Ihrer Therapie vereinbaren!")),
                        react_1.default.createElement(native_base_1.Center, { mt: "10" },
                            react_1.default.createElement(native_base_1.Button, { w: ['65%', '20%'], alignSelf: 'center', backgroundColor: '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: onlineAppointmentCheckAndRedirect }, "Jetzt Termin buchen"))))),
                prescriptionTemp.isEnough && (react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "10" },
                    react_1.default.createElement(native_base_1.Button, { w: ['36%', '10%'], alignSelf: 'center', backgroundColor: '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: next }, "Weiter")))),
            react_1.default.createElement(AbortFUPModal_1.AbortFUPModal, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal }),
            react_1.default.createElement(organisms_1.TherapyMonitorModal, { visible: isOnlineTerminModalVisible, onOk: onOnlineTerminModalOk, onCancel: onOnlineTerminModalCancel, purpose: "online-termin" }))));
};
exports.InfoAboutMedication = InfoAboutMedication;
