"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    rectangle: {
        borderWidth: 1,
        borderColor: '#e3e3e3',
        width: 350,
        height: 'auto',
        backgroundColor: '#ffff',
        padding: 5,
        marginVertical: 5,
        shadowRadius: 5,
        shadowOpacity: 0.2,
    },
    pharmacyTypeText: {
        color: '#3db384',
        marginVertical: -2,
        fontSize: 13.5,
    },
    starsStyles: {
        position: 'absolute',
        right: -5,
        marginVertical: 1,
    },
    starStyles: {
        marginHorizontal: 0.8,
    },
    titleLeft: {
        color: '#3db384',
        fontSize: 20,
        textAlign: 'left',
        maxWidth: '70%',
    },
    titleRight: {
        color: '#3db384',
        marginTop: 3,
        textAlign: 'right',
    },
    pharmacyDescription: {
        lineHeight: 20,
        margin: 5,
        color: '#3db384',
        fontSize: 13,
        textAlign: 'center',
        marginBottom: 20,
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 15,
    },
    ratingRow: {
        alignSelf: 'center',
        flexDirection: 'row',
        width: 320,
        color: '#e3e3e3',
        marginTop: 5,
        marginBottom: 8,
    },
    detailsLineGreen: {
        alignSelf: 'center',
        borderTopColor: '#3db384',
        borderTopWidth: 1,
        width: '98%',
        marginVertical: 10,
    },
    divider: {
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottomColor: '#3db384',
        borderBottomWidth: 1,
        borderRadius: 0,
        padding: 5,
        width: 320,
        paddingVertical: 15,
        marginVertical: 15,
        marginBottom: 10,
    },
    buttonTextGreen: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
    },
    buttonTextWhite: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#3db384',
    },
    buttonTextWhiteDisabled: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: 'white',
    },
    buttonGreen: {
        alignItems: 'center',
        backgroundColor: '#3db384',
        paddingVertical: 8,
        paddingHorizontal: 20,
        marginHorizontal: 15,
        marginVertical: 10,
        marginLeft: 50,
        borderRadius: 25,
        alignSelf: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        width: !Breakpoints_1.isMobileOrTablet ? 'fit-content' : undefined,
        overflow: 'hidden',
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    buttonWhite: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
        paddingVertical: 8,
        paddingHorizontal: 20,
        marginHorizontal: 15,
        marginVertical: 10,
        borderRadius: 25,
        marginRight: 50,
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        width: react_native_1.Platform.OS === 'web' ? 'fit-content' : undefined,
        overflow: 'hidden',
    },
    buttonWhiteDisabled: {
        alignItems: 'center',
        backgroundColor: '#c1c1c1',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginHorizontal: 15,
        marginVertical: 10,
        borderRadius: 25,
        marginRight: 50,
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        width: react_native_1.Platform.OS === 'web' ? 'fit-content' : undefined,
        overflow: 'hidden',
    },
});
