"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MedicalNews = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const native_base_1 = require("native-base");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const atome_1 = require("../../../atome");
const UtilityFunctions_1 = require("../../../../helper/UtilityFunctions");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
const organisms_1 = require("../../../organisms");
const AbortFUPModal_1 = require("./AbortFUPModal");
const hooks_1 = require("../../../../store/hooks");
const reducers_1 = require("../../../../store/reducers");
const GlobalStyles_1 = require("../../../../helper/GlobalStyles");
function MedicalNews(props) {
    const { navigation, setCurrentPage, showFUPRModal, setShowFUPRModal } = props;
    const prescriptionInfo = (0, hooks_1.useAppSelector)(reducers_1.selectPrescriptionInfo);
    const [prescriptionTemp, setPrescriptionTemp] = (0, react_1.useState)(prescriptionInfo);
    const dispatch = (0, hooks_1.useAppDispatch)();
    const [newMedicines, setNewMedicines] = (0, react_1.useState)();
    const [medicalNews, setMedicalNews] = (0, react_1.useState)();
    const [isButtonDisabled, setIsButtonDisabled] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const areFieldUndefined = !(0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'medicalNews') ||
            !(0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'newMedicines');
        const isNewMediciensTrueAndNoTextGiven = newMedicines === true && prescriptionTemp?.newMedicines?.length === 0;
        const isMedicalNewsTrueAndNoTextGiven = medicalNews === true && prescriptionTemp?.medicalNews?.length === 0;
        const hasError = areFieldUndefined ||
            isNewMediciensTrueAndNoTextGiven ||
            isMedicalNewsTrueAndNoTextGiven;
        hasError ? setIsButtonDisabled(true) : setIsButtonDisabled(false);
    }, [prescriptionTemp, newMedicines, medicalNews]);
    (0, react_1.useEffect)(() => {
        if ((0, UtilityFunctions_1.isInObject)(prescriptionInfo, 'medicalNews') &&
            prescriptionInfo?.medicalNews?.length === 0) {
            setMedicalNews(false);
        }
        if (prescriptionInfo.medicalNews?.length > 0) {
            setMedicalNews(true);
        }
        if ((0, UtilityFunctions_1.isInObject)(prescriptionInfo, 'newMedicines') &&
            prescriptionInfo?.newMedicines?.length === 0) {
            setNewMedicines(false);
        }
        if (prescriptionInfo?.newMedicines?.length > 0) {
            setNewMedicines(true);
        }
    }, []);
    const handleBackButton = () => {
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 6,
            },
        };
        setCurrentPage(reducerType);
    };
    const next = () => {
        dispatch((0, reducers_1.setPrescriptionInfo)(prescriptionTemp));
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 8,
            },
        };
        setCurrentPage(reducerType);
    };
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(atome_1.BetterScrollView, null,
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(native_base_1.VStack, { h: "100%", space: 5, alignItems: 'center', style: GlobalStyles_1.globalStyles.mobileToWebContainer },
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "20" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "2xl", textAlign: "center", color: "#3db384", fontWeight: "bold" }, "Medizinische Neuigkeiten")),
                react_1.default.createElement(native_base_1.Center, { mt: "3", w: "90%" },
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center" }, "Gibt es medizinische Neuigkeiten bzw. waren Sie seit der letzten Sprechstunde in medizinischer Behandlung? (Neue Diagnosen, alternative Therapien, Schwangerschaft?)")),
                react_1.default.createElement(native_base_1.HStack, { space: 70, mt: "3", justifyContent: "center" },
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: medicalNews !== undefined && medicalNews, label: "Ja", onPress: () => setMedicalNews(true) }),
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: medicalNews !== undefined && !medicalNews, label: "Nein", onPress: () => {
                            setMedicalNews(false);
                            setPrescriptionTemp({
                                ...prescriptionTemp,
                                medicalNews: '',
                            });
                        } })),
                medicalNews && (react_1.default.createElement(native_base_1.TextArea, { autoCompleteType: "off", fontSize: "md", w: "90%", h: "15%", bgColor: "#EFEFEE", borderRadius: "3xl", borderWidth: 0, value: prescriptionTemp.medicalNews, placeholder: "Beschreiben Sie diese bitte...", onChangeText: value => {
                        setPrescriptionTemp({
                            ...prescriptionTemp,
                            medicalNews: value,
                        });
                    } })),
                react_1.default.createElement(native_base_1.Center, { my: "5", w: "90%" },
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center" }, "Haben Sie seit der letzten Sprechstunde neben der Cannabinoid-Therapie neue Medikamente gestartet oder Ihren Medikationsplan ver\u00E4ndert?")),
                react_1.default.createElement(native_base_1.HStack, { space: 70, mt: "3", justifyContent: "center" },
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: newMedicines !== undefined && newMedicines, label: "Ja", onPress: () => setNewMedicines(true) }),
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: newMedicines !== undefined && !newMedicines, label: "Nein", onPress: () => {
                            setNewMedicines(false);
                            setPrescriptionTemp({
                                ...prescriptionTemp,
                                newMedicines: '',
                            });
                        } })),
                newMedicines && (react_1.default.createElement(native_base_1.TextArea, { autoCompleteType: "off", fontSize: "md", w: "90%", h: "15%", bgColor: "#EFEFEE", borderRadius: "3xl", borderWidth: 0, value: prescriptionTemp.newMedicines, placeholder: "Beschreiben Sie diese bitte...", onChangeText: value => {
                        setPrescriptionTemp({
                            ...prescriptionTemp,
                            newMedicines: value,
                        });
                    } })),
                react_1.default.createElement(native_base_1.HStack, { space: [4, 10], justifyContent: "center", pb: "250" },
                    react_1.default.createElement(native_base_1.Button, { mt: "12", w: "40%", backgroundColor: '#fff', borderRadius: "3xl", _text: { color: '#3db384', fontWeight: 'bold', underline: true }, onPress: handleBackButton }, "Zur\u00FCck"),
                    react_1.default.createElement(native_base_1.Button, { disabled: isButtonDisabled, mt: "12", w: ['40%', '80%'], backgroundColor: isButtonDisabled ? '#3db38475' : '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: next }, "Weiter"))),
            react_1.default.createElement(AbortFUPModal_1.AbortFUPModal, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal }))));
}
exports.MedicalNews = MedicalNews;
