"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAppData = exports.setAppData = exports.appDataSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isSelectingDocument: false,
};
exports.appDataSlice = (0, toolkit_1.createSlice)({
    name: 'appData',
    initialState,
    reducers: {
        setAppData: (state, action) => {
            return action.payload;
        },
    },
});
exports.setAppData = exports.appDataSlice.actions.setAppData;
const selectAppData = (state) => state.appData;
exports.selectAppData = selectAppData;
