"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessages = void 0;
var ErrorMessages;
(function (ErrorMessages) {
    ErrorMessages["PASSWORD_REQUIREMENTS"] = "Mind. 8 Zeichen, 1 Gro\u00DFbuchstabe, 1 Kleinbuchstabe, 1 Ziffer";
    ErrorMessages["PASSWORD_EQUAL"] = "Die Passw\u00F6rter stimmen nicht \u00FCberein.";
    ErrorMessages["EMPTY_FIELD"] = "Bitte f\u00FCllen Sie dieses Feld aus.";
    ErrorMessages["EMPTY_LEIDEN"] = "W\u00E4hlen Sie mindestens eine Erkrankung aus.";
    ErrorMessages["PHONE_EMPTY"] = "Eine Nummer muss eingetragen werden.";
    ErrorMessages["PHONE_LONG"] = "Rufnummer ist zu lang.";
    ErrorMessages["PHONE_SHORT"] = "Rufnummer ist zu kurz.";
    ErrorMessages["EMAIL_REQUIREMENTS"] = "Keine g\u00FCltige E-Mail-Adresse";
    ErrorMessages["EMAIL_TAKEN"] = "Diese E-Mail-Adresse wird schon verwendet.";
    ErrorMessages["REQUIRE_FIELD"] = "Muss akzeptiert werden";
    ErrorMessages["ERROR"] = "Bitte kontrollieren Sie Ihre Eingaben.";
    ErrorMessages["SHORT_DESCRIPTION"] = "Bitte ausf\u00FChrlicher beschreiben";
})(ErrorMessages = exports.ErrorMessages || (exports.ErrorMessages = {}));
