"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        paddingHorizontal: 5,
    },
    box: {
        backgroundColor: '#fff',
        display: 'flex',
        ...(Breakpoints_1.isDesktop
            ? {
                flexDirection: 'column',
                width: 170,
                height: 190,
                textAlign: 'center',
                padding: 20,
            }
            : {
                flexDirection: 'row',
                width: '100%',
                maxHeight: 70,
                paddingLeft: 30,
                paddingRight: 20,
                marginBottom: 10,
                flex: 1,
            }),
    },
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 20.22,
        elevation: 3,
    },
    boxActive: {
        backgroundColor: '#3db384',
    },
    iconContainer: {
        ...(Breakpoints_1.isDesktop
            ? {
                flex: 3,
                padding: 20,
            }
            : {
                width: 50,
                paddingTop: 15,
                paddingBottom: 15,
            }),
    },
    text: {
        color: '#3db384',
        marginTop: 15,
        fontSize: 15,
        fontWeight: 'bold',
        flex: 1,
        ...(!Breakpoints_1.isDesktop && {
            alignSelf: 'center',
            marginTop: 0,
            marginStart: 20,
        }),
    },
    textActive: {
        color: '#fff',
    },
});
