"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    input: {
        backgroundColor: '#EFEFEE',
        color: '#000',
        borderRadius: 24,
        paddingVertical: 6,
        paddingHorizontal: 12,
        minHeight: 36,
        marginTop: 6,
        marginRight: 25,
        flex: react_native_1.Platform.OS === 'web' ? 1 : 0,
        width: react_native_1.Platform.OS === 'web' ? 'unset' : 300,
        fontFamily: 'Montserrat-Regular',
    },
    error: {
        borderColor: '#ff0000',
        borderWidth: 1,
    },
    alignTop: {
        textAlignVertical: 'top',
    },
});
