"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OnlineFirstAppointment = void 0;
const react_1 = __importStar(require("react"));
const atome_1 = require("../../../atome");
const StepOneOnlineFirstAppointment_1 = require("./OnlineFirstAppointmentSteps/StepOneOnlineFirstAppointment");
const StepTwoOnlineFirstAppointment_1 = require("./OnlineFirstAppointmentSteps/StepTwoOnlineFirstAppointment");
const StepThreeOnlineFirstAppointment_1 = require("./OnlineFirstAppointmentSteps/StepThreeOnlineFirstAppointment");
// @ts-ignore
function OnlineFirstAppointment({ navigation, currentPage, setCurrentPage, }) {
    const [onlineFirstAppointmentInfo, setOnlineFirstAppointmentInfo] = (0, react_1.useState)({});
    let pageToRender;
    switch (currentPage) {
        case 1:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepOneOnlineFirstAppointment_1.StepOneOnlineFirstAppointment, { navigation: navigation, setCurrentPage: setCurrentPage, onlineFirstAppointmentInfo: onlineFirstAppointmentInfo, setOnlineFirstAppointmentInfo: setOnlineFirstAppointmentInfo })));
            break;
        case 2:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepTwoOnlineFirstAppointment_1.StepTwoOnlineFirstAppointment, { navigation: navigation, setCurrentPage: setCurrentPage, onlineFirstAppointmentInfo: onlineFirstAppointmentInfo, setOnlineFirstAppointmentInfo: setOnlineFirstAppointmentInfo })));
            break;
        case 3:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepThreeOnlineFirstAppointment_1.StepThreeOnlineFirstAppointment, { navigation: navigation, setCurrentPage: setCurrentPage, onlineFirstAppointmentInfo: onlineFirstAppointmentInfo, setOnlineFirstAppointmentInfo: setOnlineFirstAppointmentInfo })));
            break;
        default:
            pageToRender = react_1.default.createElement(react_1.default.Fragment, null);
    }
    return pageToRender;
}
exports.OnlineFirstAppointment = OnlineFirstAppointment;
