"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginHorizontal: 10,
        marginVertical: 20,
        width: '95%',
        marginTop: 100,
        alignItems: 'center',
        backgroundColor: '#fff',
        fontFamily: 'Montserrat-Regular',
    },
    containerMobileFromWeb: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        flexDirection: 'column',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '90%',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3Green: {
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Montserrat-Bold',
        marginTop: 40,
        marginBottom: 5,
        color: '#3db384',
        width: '80%',
    },
    heading3Black: {
        marginBottom: 20,
        fontSize: 20,
        fontFamily: 'Montserrat-Bold',
        textAlign: 'center',
        color: '#000',
    },
    heading4: {
        textAlign: 'center',
        fontSize: 18,
        color: '#000',
        fontFamily: 'Montserrat-Regular',
    },
    marginHinweis: {
        marginHorizontal: 15,
        marginTop: 30,
    },
    buttonContainer: {
        flexDirection: 'column',
        marginTop: 30,
        alignItems: 'center',
        justifyContent: 'space-around',
        marginVertical: 20,
    },
    disabledButton: {
        backgroundColor: '#C9C9C9',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        marginVertical: 20,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        marginVertical: 20,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        marginVertical: 20,
    },
    buttonText: {
        fontFamily: 'Montserrat-Bold',
        color: '#fff',
    },
    backButtonText: {
        fontFamily: 'Montserrat-Bold',
        color: '#3db384',
        fontSize: 15,
    },
    modalText: {
        textAlign: 'center',
        fontSize: 15,
        marginBottom: 20,
        paddingHorizontal: 20,
        fontFamily: 'Montserrat-Regular',
    },
    pharmacyText: {
        textAlign: 'center',
        fontSize: 18,
        marginVertical: 30,
        paddingHorizontal: 40,
        fontFamily: 'Montserrat-Regular',
    },
});
