"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Clock = void 0;
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
function Clock({ size = 12, color = '#fff' }) {
    return (React.createElement(react_native_svg_1.default, { width: size, height: size, viewBox: "0 0 13 13" },
        React.createElement(react_native_svg_1.Defs, null,
            React.createElement(react_native_svg_1.Polygon, { id: "path-1", points: "0 0.000481185656 12.99951 0.000481185656 12.99951 12.9999933 0 12.9999933" })),
        React.createElement(react_native_svg_1.G, { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement(react_native_svg_1.G, { id: "Group-6-Copy-2" },
                React.createElement(react_native_svg_1.G, { id: "Group-3" },
                    React.createElement(react_native_svg_1.G, { id: "Clip-2" }),
                    React.createElement(react_native_svg_1.Path, { d: "M1.04698567,6.49857355 C1.04625375,9.4982459 3.49444659,11.9501879 6.49245512,11.9523635 C9.49862747,11.9545804 11.9509466,9.50601041 11.9517454,6.50152406 C11.9525439,3.50149676 9.50457287,1.04957696 6.50645341,1.04740148 C3.50065819,1.04525102 1.04771792,3.49446433 1.04698567,6.49857355 M12.9995104,6.49690973 C13.0004415,10.0918756 10.093677,13.0001708 6.49982031,12.9999933 C2.90487662,12.9998381 -0.000756484642,10.0930292 -2.65870461e-06,6.49755307 C-2.65870461e-06,6.03036011 0.0492749692,5.57484863 0.142673298,5.13587547 C0.76792284,2.19719587 3.37486649,-0.000310002972 6.50470085,0.000480758635 C10.0929005,0.00136877133 12.9986002,2.90777833 12.9995104,6.49690973 L1.99999734,3.43179126", id: "Fill-1", fill: color })),
                React.createElement(react_native_svg_1.Path, { d: "M6.00117697,4.52987741 C6.00122654,3.882738 5.99842567,3.2355749 6.00219321,2.58845917 C6.00479578,2.14304308 6.4581889,1.86654689 6.85727498,2.06566489 C7.08518607,2.17937555 7.17838299,2.37070125 7.17813513,2.61188343 C7.17701974,3.6851832 7.19033005,4.75871981 7.16926159,5.83164063 C7.16348636,6.12504776 7.25492345,6.29619402 7.50888506,6.46018748 C8.25758353,6.94361765 8.98461868,7.45764856 9.72127095,7.95817915 C9.91938878,8.09277979 10.0251524,8.26965777 9.9948882,8.50356872 C9.93951534,8.93133964 9.44988449,9.14158949 9.0778156,8.8946756 C8.64732513,8.60901339 8.22308084,8.31468255 7.79645706,8.02371495 C7.29589539,7.68232245 6.79773801,7.33768512 6.29430112,7.00022429 C6.08968926,6.86306569 5.99879747,6.68313237 6,6.44761086 C6.00325903,5.80838217 6.00112739,5.16912979 6.00117697,4.52987741", id: "Fill-4", fill: color })))));
}
exports.Clock = Clock;
