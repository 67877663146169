"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAppointmentData = exports.setAppointmentData = exports.appointmentSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    doctorName: '',
    doctorId: '',
    startDate: '',
    endDate: '',
    type: '',
    appointmentType: '',
    practiceId: -1,
    comment: '',
    location: {
        name: '',
        address: '',
    },
};
exports.appointmentSlice = (0, toolkit_1.createSlice)({
    name: 'appointment',
    initialState,
    reducers: {
        setAppointmentData: (state, action) => {
            /* eslint-disable */
            return action.payload;
            /* eslint-enable */
        },
    },
});
exports.setAppointmentData = exports.appointmentSlice.actions.setAppointmentData;
const selectAppointmentData = (state) => state.appointment;
exports.selectAppointmentData = selectAppointmentData;
