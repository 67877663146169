"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: Breakpoints_1.isDesktop
            ? 20
            : react_native_1.Platform.OS === 'web' && !Breakpoints_1.isDesktop
                ? 0
                : 35,
        marginBottom: react_native_1.Platform.OS === 'web' && !Breakpoints_1.isDesktop ? 20 : undefined,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
    },
    backgroundColor: {
        backgroundColor: Breakpoints_1.isMobile ? '#fff' : 'auto',
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    marginVertical20: {
        marginVertical: 20,
    },
    marginHorizontal30: {
        marginHorizontal: 30,
    },
    marginHorizontalSlider: {
        marginHorizontal: react_native_1.Platform.OS === 'web' ? 0 : -6.5,
    },
    lineHeight20: {
        lineHeight: 20,
    },
    alignSelfCenter: {
        alignSelf: 'center',
    },
    saveMessageContainer: {
        alignItems: 'center',
        position: 'relative',
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 30,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        fontWeight: Breakpoints_1.isMobile ? 'bold' : 'normal',
        color: '#fff',
    },
    backButtonText: {
        fontWeight: Breakpoints_1.isMobile ? 'bold' : 'normal',
        color: '#3db384',
    },
    slider: {
        height: 20,
        flex: 1,
    },
    lifeQualityLabel: {
        marginTop: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 27 : 0,
        marginBottom: Breakpoints_1.isMobile ? 15 : 'auto',
        fontWeight: Breakpoints_1.isMobile ? 'bold' : 'auto',
        fontSize: Breakpoints_1.isMobile ? 17 : 'auto',
    },
    questionLabel: {
        marginRight: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 15 : 0,
    },
});
