"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        backgroundColor: '#fff',
        width: '100%',
    },
    containerMobileFromWeb: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        flexDirection: 'column',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '90%',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    scrollview: {
        height: '100%',
        backgroundColor: '#fff',
    },
    whiteBackground: {
        backgroundColor: '#fff',
        height: '100%',
    },
    lineHeight20: {
        lineHeight: 20,
    },
    questionText: {
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 20,
    },
    alignSelfCenter: {
        alignSelf: 'center',
    },
    marginRight0: {
        marginRight: 0,
    },
    saveMessageContainer: {
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: 150,
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 30,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        fontWeight: 'bold',
        color: '#fff',
    },
    errorMessageContainer: {
        position: 'absolute',
        bottom: -25,
    },
    errorText: {
        color: '#ff0000',
    },
    input: {
        width: '100%',
    },
    picker: {
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 12,
        marginTop: 15,
    },
    label: {
        marginBottom: 10,
    },
    introText: {
        marginBottom: 20,
    },
});
