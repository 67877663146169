"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MyPharmacy = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const hooks_1 = require("../../../../store/hooks");
const atome_1 = require("../../../atome");
const organisms_1 = require("../../../organisms");
const MyPharmacy_web_styles_1 = require("./MyPharmacy.web.styles");
const reducers_1 = require("../../../../store/reducers");
function MyPharmacy({ navigation }) {
    const userInformation = (0, hooks_1.useAppSelector)(reducers_1.selectUserInformation);
    const [loading, setLoading] = (0, react_1.useState)(false);
    const [name, setName] = (0, react_1.useState)('');
    const [city, setCity] = (0, react_1.useState)('');
    const [street, setStreet] = (0, react_1.useState)('');
    const [houseNumber, setHouseNumber] = (0, react_1.useState)('');
    const [postcode, setPostcode] = (0, react_1.useState)('');
    const [id, setId] = (0, react_1.useState)(undefined);
    const getUserData = async () => {
        setLoading(true);
        if (userInformation.patientPharmacyRelation !== undefined) {
            setName(userInformation.patientPharmacyRelation.name);
            setCity(userInformation.patientPharmacyRelation.city);
            setPostcode(userInformation.patientPharmacyRelation.postcode);
            setStreet(userInformation.patientPharmacyRelation.street);
            setHouseNumber(userInformation.patientPharmacyRelation.houseNumber);
            setId(userInformation.patientPharmacyRelation.id);
        }
        setLoading(false);
    };
    (0, react_1.useEffect)(() => {
        (async () => await getUserData())();
    }, [userInformation]);
    if (loading) {
        return react_1.default.createElement(atome_1.Loading, null);
    }
    return (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
        react_1.default.createElement(react_native_1.SafeAreaView, null,
            react_1.default.createElement(react_native_1.ScrollView, null,
                react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
                react_1.default.createElement(react_native_1.View, { style: MyPharmacy_web_styles_1.styles.container },
                    react_1.default.createElement(atome_1.Icon, { icon: "pharmacy", size: 90 }),
                    react_1.default.createElement(react_native_1.Text, { style: MyPharmacy_web_styles_1.styles.heading3Green }, id !== undefined
                        ? 'Ihre aktuelle Apotheke ist'
                        : 'Sie haben noch keine Apotheke gewählt.'),
                    id !== undefined && (react_1.default.createElement(react_native_1.View, { style: MyPharmacy_web_styles_1.styles.alignCenter },
                        react_1.default.createElement(react_native_1.Text, { style: MyPharmacy_web_styles_1.styles.heading3Black }, name),
                        react_1.default.createElement(react_native_1.Text, { style: MyPharmacy_web_styles_1.styles.heading4 }, (street ? street + ' ' : '') + (houseNumber ?? '')),
                        react_1.default.createElement(react_native_1.Text, { style: MyPharmacy_web_styles_1.styles.heading4 }, (postcode ? postcode + ' ' : '') + (city ?? '')))),
                    react_1.default.createElement(react_native_1.View, { style: MyPharmacy_web_styles_1.styles.buttonContainer },
                        react_1.default.createElement(react_native_1.Pressable, { style: MyPharmacy_web_styles_1.styles.button, onPress: () => {
                                navigation.navigate('PharmacyListPage');
                            } },
                            react_1.default.createElement(react_native_1.Text, { style: MyPharmacy_web_styles_1.styles.buttonText }, id !== undefined ? 'Apotheke ändern' : 'Apotheke wählen'))),
                    react_1.default.createElement(react_native_1.Text, { style: [MyPharmacy_web_styles_1.styles.heading4] }, id !== undefined
                        ? 'Hinweis: Bevor Sie eine neue Apotheke wählen, stellen Sie bitte sicher, dass Ihre aktuelle Medikation lieferbar ist.'
                        : 'Hinweis: Stellen Sie bitte sicher, dass Ihre aktuelle Medikation in der Apotheke lieferbar ist.'))))));
}
exports.MyPharmacy = MyPharmacy;
