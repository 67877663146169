"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isLargeScreen = exports.isLaptopScreen = exports.isMobileOrTablet = exports.isDesktop = exports.isPhablet = exports.isTablet = exports.isMobile = void 0;
const react_native_1 = require("react-native");
const windowSize = react_native_1.Dimensions.get('window');
const isMobile = windowSize.width < 481;
exports.isMobile = isMobile;
const isTablet = windowSize.width > 480 && windowSize.width < 769;
exports.isTablet = isTablet;
const isPhablet = windowSize.width > 768 && windowSize.width < 1280;
exports.isPhablet = isPhablet;
const isDesktop = windowSize.width > 1279;
exports.isDesktop = isDesktop;
const isLaptopScreen = windowSize.width > 1279 && windowSize.width < 1800;
exports.isLaptopScreen = isLaptopScreen;
const isLargeScreen = windowSize.width > 1570;
exports.isLargeScreen = isLargeScreen;
const isMobileOrTablet = isMobile || isTablet;
exports.isMobileOrTablet = isMobileOrTablet;
