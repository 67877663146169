"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepEightSecondaryDiagnosis = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const SecondaryDiagnosis_style_1 = require("../SecondaryDiagnosis.style");
const GlobalStyles_1 = require("../../../../../helper/GlobalStyles");
const atome_1 = require("../../../../atome");
const molecules_1 = require("../../../../molecules");
const Breakpoints_1 = require("../../../../../helper/Breakpoints");
const enums_1 = require("../../../../../enums");
function StepEightSecondaryDiagnosis({ setCurrentPage, isInArray, toggleArray, userInformation, setNeurologicalDiseases, neurologicalDiseases, setNeurologicalDiseasesOther, neurologicalDiseasesOther, }) {
    const [error, setError] = (0, react_1.useState)({
        neurologicalDiseasesOther: false,
    });
    const validate = () => {
        const newError = {
            neurologicalDiseasesOther: isInArray(neurologicalDiseases, 'other') &&
                neurologicalDiseasesOther.length === 0,
        };
        setError(newError);
        return newError;
    };
    const next = () => {
        const newError = validate();
        if (newError.neurologicalDiseasesOther) {
            return;
        }
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                secondaryDiagnosisIndex: 9,
            },
        };
        setCurrentPage(reducerType);
    };
    return (react_1.default.createElement(react_native_1.SafeAreaView, { style: SecondaryDiagnosis_style_1.styles.backgroundWhite },
        react_1.default.createElement(react_native_1.ScrollView, { style: [SecondaryDiagnosis_style_1.styles.scrollview] },
            react_1.default.createElement(react_native_1.View, { style: SecondaryDiagnosis_style_1.styles.container },
                react_1.default.createElement(react_native_1.View, { style: [
                        react_native_1.Platform.OS !== 'web' && GlobalStyles_1.globalStyles.marginTop35,
                        GlobalStyles_1.globalStyles.marginBottom15,
                    ] },
                    react_1.default.createElement(atome_1.Label, null, "Bevor Sie einen Termin zur Erstvorstellung vereinbaren k\u00F6nnen, ben\u00F6tigen wir noch weitere Angaben zu Nebendiagnosen, die ggf. vorliegen.")),
                react_1.default.createElement(react_native_1.View, { style: [GlobalStyles_1.globalStyles.column, GlobalStyles_1.globalStyles.marginRow] },
                    react_1.default.createElement(atome_1.Label, { style: [SecondaryDiagnosis_style_1.styles.textBold] }, 'Weitere Krankheiten \nBereich: Neurologische Erkrankungen'),
                    react_1.default.createElement(react_native_1.View, { style: [GlobalStyles_1.globalStyles.marginTop25] },
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'chronicHeadache'), label: "Chronische Kopfschmerzen", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'chronicHeadache', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'epilepsy'), label: "Epilepsie / Krampfanf\u00E4lle", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'epilepsy', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'tourettesSyndrome'), label: "Tourette-Syndrom", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'tourettesSyndrome', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'parkinsonsDisease'), label: "Morbus Parkinson", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'parkinsonsDisease', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'paraplegia'), label: "Paraplegie / L\u00E4hmungserscheinungen", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'paraplegia', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'migraine'), label: "Migr\u00E4ne", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'migraine', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'multipleSclerosis'), label: "Multiple Sklerose", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'multipleSclerosis', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'adhs'), label: "AD(H)S", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'adhs', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'polyneuropathy'), label: "Polyneuropathie", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'polyneuropathy', setNeurologicalDiseases) }),
                        react_1.default.createElement(molecules_1.Checkbox, { checked: isInArray(neurologicalDiseases, 'other'), label: "Sonstige", disabled: userInformation?.progress.isDataConfirm, onPress: () => toggleArray(neurologicalDiseases, 'other', setNeurologicalDiseases) }),
                        isInArray(neurologicalDiseases, 'other') && (react_1.default.createElement(atome_1.InputField, { onChange: setNeurologicalDiseasesOther, value: neurologicalDiseasesOther, isEditable: !userInformation?.progress.isDataConfirm, style: [Breakpoints_1.isDesktop ? {} : GlobalStyles_1.globalStyles.marginRight0] })),
                        error.neurologicalDiseasesOther && (react_1.default.createElement(react_native_1.Text, { style: [SecondaryDiagnosis_style_1.styles.errorText] }, enums_1.ErrorMessages.EMPTY_FIELD)))),
                react_1.default.createElement(react_native_1.View, { style: [
                        GlobalStyles_1.globalStyles.row,
                        GlobalStyles_1.globalStyles.marginRow,
                        SecondaryDiagnosis_style_1.styles.buttonContainer,
                    ] },
                    react_1.default.createElement(react_native_1.Pressable, { style: SecondaryDiagnosis_style_1.styles.button, onPress: next },
                        react_1.default.createElement(react_native_1.Text, { style: SecondaryDiagnosis_style_1.styles.buttonText }, "Weiter")))))));
}
exports.StepEightSecondaryDiagnosis = StepEightSecondaryDiagnosis;
