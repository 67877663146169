"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        backgroundColor: react_native_1.Platform.OS === 'web' ? 'unset' : 'white',
        maxHeight: '100%',
        alignItems: 'center',
    },
    start: {
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        marginTop: 30,
    },
    iconView: {
        justifyContent: 'center',
    },
    loading: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    containerDotStyle: {
        bottom: 180,
    },
    dotStyle: {
        width: 10,
        height: 10,
        borderRadius: 10,
        marginHorizontal: 8,
        backgroundColor: '#3db384',
    },
    inactiveDotStyle: {
        backgroundColor: '#000000',
    },
    buttonContainer: {
        maxWidth: 250,
        justifyContent: 'center',
    },
    mainButton: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 30,
        paddingVertical: 10,
        minWidth: Breakpoints_1.isDesktop ? 300 : 260,
    },
    mainButtonText: {
        color: '#fff',
        fontSize: 14,
        textAlign: 'center',
    },
    secondaryButton: {
        backgroundColor: '#fff',
        minWidth: Breakpoints_1.isDesktop ? 300 : 100,
        marginVertical: 30,
    },
    secondaryButtonText: {
        color: '#3db384',
        fontSize: 14,
        textAlign: 'center',
        fontWeight: 'bold',
    },
});
