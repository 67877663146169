"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAuth = exports.setAuth = exports.authSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const react_native_1 = require("react-native");
const initialState = {
    isAuthenticated: react_native_1.Platform.OS !== 'web',
    isTermsAgree: false,
    name: '',
    isPrivacyPolicyAgree: false,
    isNewsletterAgree: false,
};
exports.authSlice = (0, toolkit_1.createSlice)({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, action) => {
            return action.payload;
        },
    },
});
exports.setAuth = exports.authSlice.actions.setAuth;
const selectAuth = (state) => state.auth;
exports.selectAuth = selectAuth;
