"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: Breakpoints_1.isDesktop ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: Breakpoints_1.isDesktop ? 20 : undefined,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
        paddingBottom: Breakpoints_1.isDesktop ? 40 : 0,
    },
    boxesContainer: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        flexWrap: Breakpoints_1.isDesktop ? 'wrap' : 'nowrap',
        marginHorizontal: Breakpoints_1.isDesktop ? -15 : 15,
        marginVertical: Breakpoints_1.isMobile ? 30 : 40,
    },
    dataComplete: {
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
        marginRight: Breakpoints_1.isMobile ? 0 : 40,
        backgroundColor: '#3db384',
        paddingTop: Breakpoints_1.isDesktop ? 40 : 25,
        paddingBottom: Breakpoints_1.isDesktop ? 30 : 25,
        paddingHorizontal: 20,
        marginTop: Breakpoints_1.isDesktop ? 10 : 0,
        marginBottom: Breakpoints_1.isDesktop ? 20 : 0,
    },
    dataCompleteText: {
        marginBottom: 20,
        paddingHorizontal: Breakpoints_1.isDesktop ? undefined : 9,
    },
    heading2: {
        fontSize: 36,
        marginLeft: Breakpoints_1.isMobile ? 10 : 0,
        fontWeight: 'bold',
        marginBottom: 30,
        marginTop: 40,
    },
    heading3: {
        fontSize: Breakpoints_1.isMobile ? 19 : 20,
        fontWeight: 'bold',
        marginBottom: 14,
        color: '#fff',
    },
    mobileLabel: {
        width: '90%',
        lineHeight: 22,
        marginLeft: 30,
        paddingRight: 10,
        marginTop: 35,
        fontSize: 14,
        marginBottom: 0,
    },
    text: {
        color: '#fff',
    },
    textUnderline: {
        textDecorationLine: 'underline',
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#fff',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginVertical: 10,
        borderRadius: 25,
        textTransform: 'uppercase',
    },
    buttonText: {
        fontSize: 10,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#3db384',
    },
    backButtonText: {
        color: '#3db384',
        fontSize: 16,
    },
    buttonLoading: {
        alignItems: 'center',
        backgroundColor: '#fff',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginVertical: 10,
        borderRadius: 25,
        textTransform: 'uppercase',
    },
});
