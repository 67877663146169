"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dashboard = void 0;
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
function Dashboard({ color }) {
    return (React.createElement(react_native_svg_1.default, { width: "25px", height: "25px", viewBox: "0 0 25 25" },
        React.createElement(react_native_svg_1.G, { stroke: color, strokeWidth: 1.5, fill: "none", fillRule: "evenodd" },
            React.createElement(react_native_svg_1.Path, { d: "M0.75 0.75H10.25V13.25H0.75z" }),
            React.createElement(react_native_svg_1.Path, { d: "M0.75 16.75H10.25V24.25H0.75z" }),
            React.createElement(react_native_svg_1.Path, { transform: "rotate(-180 19.5 18)", d: "M14.75 11.75H24.25V24.25H14.75z" }),
            React.createElement(react_native_svg_1.Path, { transform: "rotate(-180 19.5 4.5)", d: "M14.75 0.75H24.25V8.25H14.75z" }))));
}
exports.Dashboard = Dashboard;
