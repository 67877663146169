"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: Breakpoints_1.isDesktop ? 20 : 40,
        width: react_native_1.Platform.OS === 'web' ? '100vw' : '100%',
        maxWidth: 1080,
    },
    heading1: {
        color: '#000',
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    heading2: {
        color: Breakpoints_1.isDesktop ? '#fff' : '#000',
        fontSize: Breakpoints_1.isDesktop ? 24 : 18,
        fontWeight: 'bold',
        marginBottom: 40,
        textTransform: Breakpoints_1.isDesktop ? 'uppercase' : 'none',
    },
    heading3: {
        color: Breakpoints_1.isDesktop ? '#fff' : '#3db384',
        position: 'absolute',
        fontSize: Breakpoints_1.isDesktop ? 24 : 30,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    positionRelative: {
        position: 'relative',
    },
    row: {
        flexDirection: 'row',
    },
    column: {
        flexDirection: 'column',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    marginTop5: {
        marginTop: 5,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginTop35: {
        marginTop: 35,
    },
    marginLeft10: {
        marginLeft: 10,
    },
    marginLeft20: {
        marginLeft: 20,
    },
    marginBottom40: {
        marginBottom: 40,
    },
    marginHorizontal35: {
        marginHorizontal: 35,
    },
    lineHeight20: {
        lineHeight: 20,
    },
    colorWhite: {
        color: '#fff',
    },
    textBlack: {
        color: '#000',
    },
    textBold: {
        fontWeight: 'bold',
    },
    calendarIconContainer: {
        height: 90,
    },
    selectedDateDay: {
        left: 0,
        right: 0,
        top: 15,
        bottom: Breakpoints_1.isDesktop ? 0 : 10,
        marginBottom: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    selectedAppointmentContainer: {
        backgroundColor: Breakpoints_1.isDesktop ? '#3db384' : 'auto',
        padding: 30,
        borderRadius: 5,
    },
    selectedAppointmentWrapper: {
        marginTop: Breakpoints_1.isDesktop ? 'auto' : 35,
        width: Breakpoints_1.isDesktop ? '60%' : '70%',
    },
    selectedAppointmentHeader: {
        marginBottom: Breakpoints_1.isDesktop ? 20 : 25,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: Breakpoints_1.isDesktop ? 60 : 20,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: Breakpoints_1.isDesktop ? 50 : 25,
        paddingVertical: 10,
    },
    buttonText: {
        textTransform: Breakpoints_1.isDesktop ? 'uppercase' : 'none',
        fontWeight: Breakpoints_1.isDesktop ? 'normal' : 'bold',
        color: '#fff',
    },
});
