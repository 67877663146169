"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentUpload = exports.DocumentPreview = exports.CaseHistoryBox = exports.DocumentBox = exports.DiseaseBox = exports.Checkbox = void 0;
const Checkbox_1 = require("./Checkbox/Checkbox");
Object.defineProperty(exports, "Checkbox", { enumerable: true, get: function () { return Checkbox_1.Checkbox; } });
const DiseaseBox_1 = require("./DiseaseBox/DiseaseBox");
Object.defineProperty(exports, "DiseaseBox", { enumerable: true, get: function () { return DiseaseBox_1.DiseaseBox; } });
const DocumentBox_1 = require("./DocumentBox/DocumentBox");
Object.defineProperty(exports, "DocumentBox", { enumerable: true, get: function () { return DocumentBox_1.DocumentBox; } });
const CaseHistoryBox_1 = require("./CaseHistoryBox/CaseHistoryBox");
Object.defineProperty(exports, "CaseHistoryBox", { enumerable: true, get: function () { return CaseHistoryBox_1.CaseHistoryBox; } });
const DocumentPreview_1 = require("./DocumentPreview/DocumentPreview");
Object.defineProperty(exports, "DocumentPreview", { enumerable: true, get: function () { return DocumentPreview_1.DocumentPreview; } });
const DocumentUpload_1 = require("./DocumentUpload/DocumentUpload");
Object.defineProperty(exports, "DocumentUpload", { enumerable: true, get: function () { return DocumentUpload_1.DocumentUpload; } });
