"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: react_native_1.Platform.OS === 'web' ? '100vw' : '100%',
        maxWidth: 1080,
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    colorWhite: {
        color: '#fff',
    },
    textBold: {
        fontWeight: 'bold',
    },
    selectedDateDay: {
        left: 0,
        right: 0,
        top: 15,
        bottom: 0,
        marginBottom: 'unset',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    calendarContainer: {
        width: '35%',
        borderRightWidth: 2,
        borderRightColor: '#ccc',
        borderRightStyle: 'solid',
        paddingRight: 50,
        marginRight: 25,
    },
    calendarInformationContainer: {
        width: '65%',
        alignItems: 'stretch',
    },
    slotsContainer: {
        flexWrap: 'wrap',
        marginTop: 10,
        marginLeft: -5,
    },
    slot: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#3db384',
        borderRadius: 5,
        marginVertical: 5,
        marginHorizontal: 5,
        textAlign: 'center',
        alignSelf: 'center',
        width: 120,
    },
    activeSlot: {
        backgroundColor: '#3db384',
        color: '#fff',
    },
    selectedAppointmentContainer: {
        backgroundColor: '#3db384',
        padding: 30,
        borderRadius: 5,
    },
    selectedAppointmentWrapper: {
        width: '60%',
    },
    selectedAppointmentHeader: {
        marginBottom: 20,
    },
    selectedAppointmentText: {
        width: '100%',
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 60,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    disabledButton: {
        opacity: 0.75,
    },
    buttonText: {
        color: '#fff',
    },
    backButtonText: {
        color: '#3db384',
        fontSize: 16,
    },
});
