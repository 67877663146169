"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        marginVertical: 100,
        marginHorizontal: Breakpoints_1.isDesktop ? '20%' : '5%',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
    },
    alignCenter: {
        alignItems: 'center',
    },
    justifyContentSpaceBetween: {
        justifyContent: 'space-between',
    },
    heading2: {
        fontSize: Breakpoints_1.isDesktop ? 29 : 19,
        fontWeight: 'bold',
        marginBottom: 5,
        color: '#000',
    },
    textBold: {
        fontSize: 15,
        color: '#000',
        fontWeight: 'bold',
    },
    label: {
        marginBottom: 5,
    },
    marginRow: {
        marginVertical: Breakpoints_1.isDesktop ? 25 : 10,
    },
    alignItemsStart: {
        alignItems: 'flex-start',
    },
    greenCircleContainer: {
        height: Breakpoints_1.isDesktop ? 135 : 80,
        width: Breakpoints_1.isDesktop ? 135 : 80,
        paddingVertical: Breakpoints_1.isDesktop ? 20 : 20,
        paddingHorizontal: Breakpoints_1.isDesktop ? 10 : 10,
        borderRadius: Breakpoints_1.isDesktop ? 135 : 80,
        backgroundColor: '#3db384',
    },
    greenCircleText: {
        alignItems: 'center',
        textAlign: 'center',
        color: '#fff',
        marginTop: Breakpoints_1.isDesktop ? 15 : 0,
        fontSize: Breakpoints_1.isDesktop ? 52 : 30,
    },
    buttonContainer: {
        flex: 1,
        justifyContent: Breakpoints_1.isDesktop ? 'flex-start' : 'center',
        marginTop: Breakpoints_1.isDesktop ? 170 : 40,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 35,
        paddingVertical: 10,
    },
    buttonText: {
        textTransform: Breakpoints_1.isDesktop ? 'uppercase' : 'none',
        color: '#fff',
    },
    width100: {
        width: '100%',
    },
    deleteButtonContainer: {
        flex: 1,
        justifyContent: Breakpoints_1.isDesktop ? 'flex-start' : 'center',
        marginTop: Breakpoints_1.isDesktop ? undefined : 30,
    },
    deleteDescription: {
        fontSize: 12,
        marginTop: 10,
    },
    deleteButton: {
        borderRadius: 24,
        borderWidth: 1,
    },
    deleteButtonSecondary: {
        backgroundColor: '#fff',
        borderColor: '#DC0000',
        paddingHorizontal: 35,
        paddingVertical: 10,
    },
    deleteButtonSecondaryText: {
        color: '#DC0000',
        fontSize: Breakpoints_1.isDesktop ? undefined : 12,
    },
    deleteButtonPrimary: {
        backgroundColor: '#DC0000',
        borderColor: '#DC0000',
        paddingHorizontal: Breakpoints_1.isDesktop ? 35 : 24,
        paddingVertical: Breakpoints_1.isDesktop ? 10 : 6,
        marginRight: 16,
    },
    deleteButtonSuccess: {
        backgroundColor: '#3db384',
        borderColor: '#3db384',
        paddingHorizontal: Breakpoints_1.isDesktop ? 35 : 24,
        paddingVertical: Breakpoints_1.isDesktop ? 10 : 6,
        marginRight: 16,
    },
    deleteButtonPrimaryText: {
        color: '#fff',
        fontSize: Breakpoints_1.isDesktop ? undefined : 12,
        fontWeight: '700',
    },
    cancelButton: {
        backgroundColor: '#fff',
        borderColor: '#3db384',
        paddingHorizontal: Breakpoints_1.isDesktop ? 35 : 24,
        paddingVertical: Breakpoints_1.isDesktop ? 10 : 6,
        marginRight: 16,
    },
    cancelButtonText: {
        color: '#3db384',
        fontSize: Breakpoints_1.isDesktop ? undefined : 12,
        fontWeight: '700',
    },
    deleteModal: {
        marginVertical: 2,
        marginHorizontal: 2,
    },
    deleteModalWeb: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        margin: 'auto',
    },
    deleteModalTitle: {
        fontSize: Breakpoints_1.isDesktop ? 24 : 20,
        fontWeight: 'bold',
        marginBottom: Breakpoints_1.isDesktop ? 24 : 16,
        color: '#000',
    },
    deleteModalText: {
        fontSize: Breakpoints_1.isDesktop ? 16 : 12,
        marginBottom: Breakpoints_1.isDesktop ? 10 : 16,
    },
    deleteButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: Breakpoints_1.isDesktop ? 24 : 16,
    },
    textLink: {
        textDecorationLine: 'underline',
        cursor: 'pointer',
    },
});
