"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        width: '100%',
        position: 'absolute',
        backgroundColor: 'white',
    },
    loadingContainer: {
        display: 'flex',
        width: 140,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    loadingDot: {
        height: 22,
        width: 22,
        borderRadius: 25,
        backgroundColor: '#3db384',
    },
});
