// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../src/assets/fonts/Montserrat-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../src/assets/fonts/Montserrat-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../src/assets/fonts/Montserrat-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../src/assets/fonts/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./../src/assets/fonts/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: Montserrat-Regular;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: 100;
}
@font-face {
    font-family: Montserrat-Regular;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
    font-weight: 200;
}
@font-face {
    font-family: Montserrat-Regular;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: Montserrat-Regular;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
    font-weight: normal;
}
@font-face {
    font-family: Montserrat-Regular;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./main.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,+DAAwE;IACxE,gBAAgB;AACpB;AACA;IACI,+BAA+B;IAC/B,+DAA8E;IAC9E,gBAAgB;AACpB;AACA;IACI,+BAA+B;IAC/B,+DAAyE;IACzE,gBAAgB;AACpB;AACA;IACI,+BAA+B;IAC/B,+DAA2E;IAC3E,mBAAmB;AACvB;AACA;IACI,+BAA+B;IAC/B,+DAAwE;IACxE,iBAAiB;AACrB","sourcesContent":["@font-face {\n    font-family: Montserrat-Regular;\n    src: url('./../src/assets/fonts/Montserrat-Thin.ttf') format('truetype');\n    font-weight: 100;\n}\n@font-face {\n    font-family: Montserrat-Regular;\n    src: url('./../src/assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');\n    font-weight: 200;\n}\n@font-face {\n    font-family: Montserrat-Regular;\n    src: url('./../src/assets/fonts/Montserrat-Light.ttf') format('truetype');\n    font-weight: 300;\n}\n@font-face {\n    font-family: Montserrat-Regular;\n    src: url('./../src/assets/fonts/Montserrat-Regular.ttf') format('truetype');\n    font-weight: normal;\n}\n@font-face {\n    font-family: Montserrat-Regular;\n    src: url('./../src/assets/fonts/Montserrat-Bold.ttf') format('truetype');\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
