"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    label: {
        color: '#000',
        fontSize: 14,
        flex: react_native_1.Platform.OS === 'web' ? 1 : 0,
        width: Breakpoints_1.isTablet ? '65%' : '100%',
    },
});
