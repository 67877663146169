"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackFunction = void 0;
const UtilityFunctions_1 = require("../../../helper/UtilityFunctions");
const BackFunction = ({ route, state, reactDispatch, navigation, isFemale, isFirstTime, hasOtherDiseases, prescriptionInfo, setShowFUPRModal, }) => {
    if (route.name === 'RegisterProcedure' && !isFirstTime) {
        navigation.navigate('Login');
        return;
    }
    if (route.name === 'RegisterProcedure' && isFirstTime) {
        navigation.navigate('Home');
        return;
    }
    if (route.name === 'ForgotPassword') {
        navigation.navigate('Login');
        return;
    }
    if (route.name === 'Login') {
        navigation.navigate('Home');
        return;
    }
    if (route.name === 'UpdatePersonalData') {
        navigation.navigate('MyAccount');
        return;
    }
    if (route.name === 'Calendly') {
        navigation.navigate('Contact');
        return;
    }
    if (route.name === 'CalendlyWebview') {
        navigation.navigate('Contact');
        return;
    }
    if (route.name === 'Medication' && state.medicationIndex === 1) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { medicationIndex: 1 },
        };
        reactDispatch(reducerType);
        navigation.navigate('CaseHistoryOverview');
        return;
    }
    if (route.name === 'Medication' && state.medicationIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { medicationIndex: 1 },
        };
        reactDispatch(reducerType);
        return;
    }
    // @ts-ignore
    if (route.name === 'Documents' && route.params?.page === 'my-documents') {
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'Documents') {
        navigation.navigate('CaseHistoryOverview');
        return;
    }
    if (route.name === 'CaseHistoryOverview') {
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'PharmacyListPage') {
        const patientComesFromFUPR = (0, UtilityFunctions_1.isInObject)(prescriptionInfo, 'isSamePharmacy') &&
            prescriptionInfo.isSamePharmacy === false;
        patientComesFromFUPR
            ? navigation.navigate('FollowPrescription')
            : navigation.navigate('PharmacyPage');
        return;
    }
    if (route.name === 'PharmacyConfirmation') {
        navigation.navigate('PharmacyListPage');
        return;
    }
    if (route.name === 'PharmacyDetails') {
        navigation.navigate('PharmacyListPage');
        return;
    }
    if (route.name === 'PharmacyCreation') {
        navigation.navigate('PharmacyListPage');
        return;
    }
    if (route.name === 'FirstPresentation' &&
        state.firstPresentationIndex === 1) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { firstPresentationIndex: 1 },
        };
        reactDispatch(reducerType);
        navigation.navigate('CaseHistoryOverview');
        return;
    }
    if (route.name === 'FirstPresentation' &&
        state.firstPresentationIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { firstPresentationIndex: 1 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'FurtherQuestion' && state.furtherQuestionsIndex === 1) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { furtherQuestionsIndex: 1 },
        };
        reactDispatch(reducerType);
        navigation.navigate('CaseHistoryOverview');
        return;
    }
    if (route.name === 'FurtherQuestion' && state.furtherQuestionsIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { furtherQuestionsIndex: 1 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'OnlineAppointment' &&
        state.onlineAppointmentIndex === 1) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { onlineAppointmentIndex: 1 },
        };
        reactDispatch(reducerType);
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'OnlineAppointment' &&
        state.onlineAppointmentIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { onlineAppointmentIndex: 1 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'OnlineAppointment' &&
        state.onlineAppointmentIndex === 3) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { onlineAppointmentIndex: 2 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'AppointmentSuccess' &&
        state.onlineAppointmentIndex === 1) {
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 1) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 1 },
        };
        reactDispatch(reducerType);
        navigation.navigate('CaseHistoryOverview');
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 1 },
        };
        if (!isFemale) {
            navigation.navigate('CaseHistoryOverview');
        }
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 3) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 2 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 4) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 3 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 5) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 4 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 6) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 5 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 7) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 6 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 8) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 7 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 9) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 8 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 10) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 9 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'SecondaryDiagnosis' &&
        state.secondaryDiagnosisIndex === 11) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { secondaryDiagnosisIndex: 2 },
        };
        if (hasOtherDiseases) {
            reducerType.payload.secondaryDiagnosisIndex = 10;
        }
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'LifeQuality') {
        navigation.navigate('CaseHistoryOverview');
        return;
    }
    if (route.name === 'Splash') {
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'FollowPrescription' &&
        state.followUpPrescriptionsIndex !== 12) {
        setShowFUPRModal(true);
        return;
    }
    if (route.name === 'FollowPrescription' &&
        state.followUpPrescriptionsIndex === 12) {
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'OfflineFirstAppointment' &&
        state.offlineFirstAppointmentIndex === 1) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { offlineFirstAppointmentIndex: 1 },
        };
        reactDispatch(reducerType);
        navigation.navigate('Dashboard');
        return;
    }
    if (route.name === 'OfflineFirstAppointment' &&
        state.offlineFirstAppointmentIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { offlineFirstAppointmentIndex: 1 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'OfflineFirstAppointment' &&
        state.offlineFirstAppointmentIndex === 3) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { offlineFirstAppointmentIndex: 2 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'OfflineFirstAppointment' &&
        state.offlineFirstAppointmentIndex === 4) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { offlineFirstAppointmentIndex: 3 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'OnlineFirstAppointment' &&
        state.onlineFirstAppointmentIndex === 2) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { onlineFirstAppointmentIndex: 1 },
        };
        reactDispatch(reducerType);
        return;
    }
    if (route.name === 'OnlineFirstAppointment' &&
        state.onlineFirstAppointmentIndex === 3) {
        let reducerType = {
            type: '[UPDATE]',
            payload: { onlineFirstAppointmentIndex: 2 },
        };
        reactDispatch(reducerType);
        return;
    }
    navigation.navigate('Dashboard');
};
exports.BackFunction = BackFunction;
