"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        height: '100%',
    },
    logo: {
        alignItems: 'center',
        marginVertical: 50,
    },
    labelGreen: {
        color: '#3db384',
        fontFamily: 'Montserrat-Regular',
    },
    label: {
        fontFamily: 'Montserrat-Regular',
        color: 'black',
    },
    labelWithoutIcon: {
        fontFamily: 'Montserrat-Regular',
        color: 'black',
        marginHorizontal: 57,
    },
    divider: {
        borderBottomColor: '#e0e0e0',
        borderBottomWidth: 1,
        borderRadius: 0,
        height: 60,
        marginBottom: 10,
    },
    versionContainer: {
        position: 'absolute',
        left: 20,
        bottom: 15,
    },
    versionText: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 13,
        color: '#c2c2c2',
    },
});
