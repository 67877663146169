"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    mobileContainer: {
        backgroundColor: 'white',
        height: '100%',
    },
    container: {
        flex: 2,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: react_native_1.Platform.OS === 'web' ? 'auto' : undefined,
        marginVertical: 20,
        width: react_native_1.Platform.OS === 'web' ? 1080 : '100%',
        flexDirection: react_native_1.Platform.OS === 'web' ? undefined : 'column',
        alignItems: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        backgroundColor: 'white',
        paddingStart: react_native_1.Platform.OS === 'web' ? undefined : 25,
        paddingEnd: react_native_1.Platform.OS === 'web' ? undefined : 25,
    },
    describeContainer: {
        marginBottom: react_native_1.Platform.OS === 'web' ? 40 : 20,
        marginTop: react_native_1.Platform.OS === 'web' ? 0 : 20,
        width: '100%',
    },
    documentWrapper: {
        flexWrap: react_native_1.Platform.OS === 'web' ? 'wrap' : 'nowrap',
        width: react_native_1.Platform.OS === 'web' ? undefined : '100%',
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    alignCenter: {
        alignItems: 'center',
    },
    headerBox: {
        backgroundColor: '#3db384',
        padding: 20,
        flex: 5.5,
    },
    headerBoxTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: 10,
    },
    headerBoxButton: {
        backgroundColor: '#fff',
        paddingHorizontal: 10,
        paddingVertical: 7.5,
    },
    headerBoxButtonText: {
        fontSize: 12,
        color: '#3db384',
        textAlign: 'center',
    },
    loadingBar: {
        position: 'relative',
        width: '100%',
        paddingVertical: 5,
        borderRadius: 10,
        backgroundColor: '#fff',
        marginVertical: 10,
        marginRight: 40,
    },
    progressBar: {
        position: 'absolute',
        top: 2,
        left: 2,
        borderRadius: 10,
        backgroundColor: '#3db384',
        paddingVertical: 3,
        transition: 'width 0.75s ease-out',
    },
    saveMessageContainer: {
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: react_native_1.Platform.OS === 'web' ? 151 : '100%',
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
        left: react_native_1.Platform.OS === 'web' ? 0 : 100,
        right: 0,
        top: 30,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 60,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
    uploadButton: {
        backgroundColor: 'white',
        borderWidth: 2,
        borderColor: '#3db384',
    },
    backButtonText: {
        color: '#3db384',
    },
    emptyState: {
        marginTop: 40,
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textUnderline: {
        fontWeight: 'bold',
        textDecorationLine: 'underline',
    },
});
