"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DOCTOR_SLOT_SUBTYPE = exports.DOCTOR_SLOT = void 0;
var DOCTOR_SLOT;
(function (DOCTOR_SLOT) {
    DOCTOR_SLOT[DOCTOR_SLOT["FIRST_APPOINTMENT"] = 0] = "FIRST_APPOINTMENT";
    DOCTOR_SLOT[DOCTOR_SLOT["FOLLOW_UP_APPOINTMENT"] = 1] = "FOLLOW_UP_APPOINTMENT";
})(DOCTOR_SLOT = exports.DOCTOR_SLOT || (exports.DOCTOR_SLOT = {}));
var DOCTOR_SLOT_SUBTYPE;
(function (DOCTOR_SLOT_SUBTYPE) {
    DOCTOR_SLOT_SUBTYPE["OFFLINE"] = "offline";
    DOCTOR_SLOT_SUBTYPE["ONLINE"] = "online";
})(DOCTOR_SLOT_SUBTYPE = exports.DOCTOR_SLOT_SUBTYPE || (exports.DOCTOR_SLOT_SUBTYPE = {}));
