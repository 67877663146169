"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pharmacy = void 0;
const native_base_1 = require("native-base");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const atome_1 = require("../../../atome");
const UtilityFunctions_1 = require("../../../../helper/UtilityFunctions");
const hooks_1 = require("../../../../store/hooks");
const AbortFUPModal_1 = require("./AbortFUPModal");
const reducers_1 = require("../../../../store/reducers");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
const organisms_1 = require("../../../organisms");
const GlobalStyles_1 = require("../../../../helper/GlobalStyles");
//@ts-ignore
function Pharmacy(props) {
    const { navigation, setCurrentPage, showFUPRModal, setShowFUPRModal } = props;
    const userInformation = (0, hooks_1.useAppSelector)(reducers_1.selectUserInformation);
    const prescriptionInfo = (0, hooks_1.useAppSelector)(reducers_1.selectPrescriptionInfo);
    const [prescriptionTemp, setPrescriptionTemp] = (0, react_1.useState)(prescriptionInfo);
    const dispatch = (0, hooks_1.useAppDispatch)();
    const [isButtonDisabled, setIsButtonDisabled] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const error = !(0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'isSamePharmacy');
        error ? setIsButtonDisabled(true) : setIsButtonDisabled(false);
    }, [prescriptionTemp]);
    const choosePharmacy = () => {
        dispatch((0, reducers_1.setPrescriptionInfo)(prescriptionTemp));
        navigation.navigate('PharmacyListPage');
    };
    const handleBackButton = () => {
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 7,
            },
        };
        setCurrentPage(reducerType);
    };
    const next = () => {
        dispatch((0, reducers_1.setPrescriptionInfo)(prescriptionTemp));
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 9,
            },
        };
        setCurrentPage(reducerType);
    };
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(native_base_1.ScrollView, { bgColor: "#fff", h: "100%", w: "100%" },
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: true }),
            react_1.default.createElement(native_base_1.VStack, { space: 5, alignItems: 'center', style: GlobalStyles_1.globalStyles.mobileToWebContainer },
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "20" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "2xl", w: "80%", textAlign: "center", fontWeight: "bold", color: "#3db384" }, "Apotheke")),
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "3" },
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center" }, "Sie haben folgende Apotheke gew\u00E4hlt:"),
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center", fontWeight: "bold", mt: "2" }, userInformation?.patientPharmacyRelation?.name ||
                        'Keine Apotheke gewählt'),
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center", mt: "12" }, "Ist diese weiterhin aktuell?")),
                react_1.default.createElement(native_base_1.HStack, { space: 70, mt: "3", justifyContent: "center" },
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: prescriptionTemp.isSamePharmacy, label: "Ja", onPress: () => {
                            setPrescriptionTemp({
                                ...prescriptionTemp,
                                isSamePharmacy: true,
                            });
                        } }),
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: prescriptionTemp.isSamePharmacy === false, label: "Nein", onPress: () => {
                            setPrescriptionTemp({
                                ...prescriptionTemp,
                                isSamePharmacy: false,
                            });
                        } })),
                prescriptionTemp?.isSamePharmacy !== false && (react_1.default.createElement(native_base_1.HStack, { space: [4, 10], justifyContent: "center", pb: "250" },
                    react_1.default.createElement(native_base_1.Button, { mt: "12", w: "40%", backgroundColor: '#fff', borderRadius: "3xl", _text: { color: '#3db384', fontWeight: 'bold', underline: true }, onPress: handleBackButton }, "Zur\u00FCck"),
                    react_1.default.createElement(native_base_1.Button, { disabled: isButtonDisabled, mt: "12", w: ['40%', '80%'], backgroundColor: isButtonDisabled ? '#3db38475' : '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: next }, "Weiter"))),
                prescriptionTemp?.isSamePharmacy === false && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(native_base_1.Text, null, "Bitte w\u00E4hlen sie Ihre neue Apotheke"),
                    react_1.default.createElement(native_base_1.Button, { mt: "8", w: ['40%', '10%'], alignSelf: 'center', backgroundColor: '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: choosePharmacy }, "Apotheke w\u00E4hlen")))),
            react_1.default.createElement(AbortFUPModal_1.AbortFUPModal, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal }))));
}
exports.Pharmacy = Pharmacy;
