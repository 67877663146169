"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        width: !Breakpoints_1.isDesktop && react_native_1.Platform.OS === 'web'
            ? '100%'
            : !Breakpoints_1.isDesktop && react_native_1.Platform.OS !== 'web'
                ? '90%'
                : '33%',
        paddingHorizontal: 15,
        paddingBottom: 30,
    },
    box: {
        backgroundColor: '#fff',
        width: '100%',
        textAlign: 'center',
        marginHorizontal: Breakpoints_1.isMobile && react_native_1.Platform.OS !== 'web' ? '11%' : 'auto',
        paddingHorizontal: 20,
        paddingBottom: Breakpoints_1.isDesktop ? 10 : 0,
        flex: 1,
        flexDirection: 'column',
    },
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 20.22,
        elevation: Breakpoints_1.isMobile ? 8 : 3,
    },
    textContainer: {
        height: Breakpoints_1.isMobile ? 80 : 100,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    text: {
        color: '#3db384',
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: 30,
        display: 'flex',
    },
    progressContainer: {
        width: '100%',
        height: 2,
        backgroundColor: '#ccc',
    },
    progress: {
        height: '100%',
        backgroundColor: '#3db384',
    },
    buttonContainer: {
        height: Breakpoints_1.isMobile ? 90 : 110,
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#3db384',
        marginVertical: 10,
        paddingHorizontal: Breakpoints_1.isDesktop ? 20 : 30,
        paddingVertical: 8,
        borderRadius: 25,
    },
    buttonText: {
        fontSize: Breakpoints_1.isDesktop ? 12 : 14,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
    },
});
