"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        marginVertical: react_native_1.Platform.OS === 'web' ? 20 : 40,
        width: '100%',
    },
    whiteBackground: {
        height: '100%',
        backgroundColor: '#fff',
    },
    heading2: {
        color: '#000',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 40,
    },
    positionRelative: {
        position: 'relative',
    },
    positionAbsolute: {
        position: 'absolute',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    colorWhite: {
        color: '#fff',
    },
    colorBlack: {
        color: '#000',
    },
    textBold: {
        fontWeight: 'bold',
    },
    lineHeight20: {
        lineHeight: 20,
    },
    calendarIconContainer: {
        height: 90,
    },
    selectedDateDay: {
        left: 22,
        top: 26,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#3db384',
        fontSize: 30,
        fontWeight: 'bold',
    },
    calendarContainer: {
        width: '87.3%',
        marginHorizontal: 25,
        marginBottom: 10,
    },
    calendarInformationContainer: {
        width: react_native_1.Platform.OS === 'web' && Breakpoints_1.isMobile ? '85%' : '82%',
        marginHorizontal: 35,
        alignItems: 'stretch',
    },
    slotsContainer: {
        flexWrap: 'wrap',
        marginTop: 20,
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    slot: {
        paddingVertical: 12,
        maxHeight: 40,
        borderStyle: 'solid',
        borderWidth: 1.5,
        borderColor: '#3db384',
        borderRadius: 7,
        marginVertical: 10,
        textAlign: 'center',
        width: 100,
        color: '#000',
        fontSize: 12,
    },
    activeSlot: {
        backgroundColor: '#3db384',
        color: '#fff',
        overflow: 'hidden',
    },
    opacity0: {
        opacity: 0,
    },
    selectedAppointmentContainer: {
        marginHorizontal: 35,
    },
    selectedAppointmentWrapper: {
        width: '70%',
    },
    noAppointmentsText: {
        position: 'absolute',
        bottom: 110,
        left: '22%',
    },
    selectedAppointmentHeader: {
        marginBottom: 25,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 60,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 30,
        paddingVertical: 9,
    },
    disabledButton: {
        opacity: 0.75,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 30,
        paddingVertical: 9,
    },
});
