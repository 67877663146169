"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StandardPharmacy = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const StandardPharmacy_web_styles_1 = require("./StandardPharmacy.web.styles");
const atome_1 = require("../../../../atome");
const react_native_gesture_handler_1 = require("react-native-gesture-handler");
const hooks_1 = require("../../../../../store/hooks");
const reducers_1 = require("../../../../../store/reducers");
const StandardPharmacy = ({ navigation, pharmacy, setConfirmationModal, setChosenPharmacy }) => {
    const userInformation = (0, hooks_1.useAppSelector)(reducers_1.selectUserInformation);
    const [showDetails, setShowDetails] = (0, react_1.useState)(false);
    const disable = pharmacy.id === userInformation.patientPharmacyRelation?.id;
    const Details = () => (react_1.default.createElement(react_native_1.View, { key: pharmacy.id, style: [{ alignItems: 'center' }] },
        react_1.default.createElement(react_native_1.View, { style: StandardPharmacy_web_styles_1.styles.buttonRow },
            react_1.default.createElement(react_native_1.Pressable, { style: StandardPharmacy_web_styles_1.styles.buttonGreen, onPress: () => {
                    navigation.navigate('PharmacyDetails', {
                        chosenPharmacy: pharmacy
                    });
                }, disabled: false },
                react_1.default.createElement(atome_1.Text, { style: [StandardPharmacy_web_styles_1.styles.buttonTextWhite] }, "Details")),
            react_1.default.createElement(react_native_1.Pressable, { style: disable ? StandardPharmacy_web_styles_1.styles.buttonWhiteDisable : StandardPharmacy_web_styles_1.styles.buttonWhite, onPress: () => { setConfirmationModal(true), setChosenPharmacy(pharmacy); }, disabled: disable },
                react_1.default.createElement(atome_1.Text, { style: [disable ? StandardPharmacy_web_styles_1.styles.buttonTextGreenDisabled : StandardPharmacy_web_styles_1.styles.buttonTextGreen] }, disable ? 'Ausgewählte Apotheke' : 'Apotheke Wählen')))));
    return (react_1.default.createElement(react_native_1.View, { style: [StandardPharmacy_web_styles_1.styles.rectangleVerified] },
        react_1.default.createElement(react_native_gesture_handler_1.TouchableOpacity, { onPress: () => setShowDetails(!showDetails), style: StandardPharmacy_web_styles_1.styles.infoRow },
            react_1.default.createElement(atome_1.Text, { style: [StandardPharmacy_web_styles_1.styles.titleLeft] }, pharmacy.name),
            react_1.default.createElement(atome_1.Text, { style: [StandardPharmacy_web_styles_1.styles.titleRight] }, pharmacy.city)),
        showDetails && react_1.default.createElement(Details, null)));
};
exports.StandardPharmacy = StandardPharmacy;
