"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    header: {
        display: 'flex',
        flexDirection: 'row',
        marginHorizontal: 20,
        marginTop: 5,
        marginBottom: Breakpoints_1.isMobile || Breakpoints_1.isTablet ? 0 : 25,
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30,
        ...react_native_1.Platform.select({
            ios: {
                alignSelf: 'center',
            },
            android: {
                alignSelf: 'center',
            },
        }),
    },
    logo: {
        display: 'flex',
        marginTop: 30,
    },
    navbar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        paddingLeft: 120,
    },
    navbarText: {
        fontSize: 16,
        color: '#000',
        fontWeight: 'bold',
        marginRight: 45,
    },
    navbarTextActive: {
        color: '#3db384',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 35,
        paddingHorizontal: 25,
        paddingVertical: 12,
    },
    buttonText: {
        color: '#fff',
        fontSize: 18,
        letterSpacing: -1,
    },
});
