"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    underlined: {
        textDecorationLine: 'underline',
    },
    greenText: {
        color: '#3db384',
    },
});
