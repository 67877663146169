"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfflineFirstAppointmentSuccess = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars */
const react_1 = __importStar(require("react"));
const OfflineFirstAppointment_style_1 = require("../OfflineFirstAppointment.style");
const GlobalStyles_1 = require("../../../../../helper/GlobalStyles");
const react_native_1 = require("react-native");
const atome_1 = require("../../../../atome");
const organisms_1 = require("../../../../organisms");
const Breakpoints_1 = require("../../../../../helper/Breakpoints");
const hooks_1 = require("../../../../../store/hooks");
const appointmentReducer_1 = require("../../../../../store/reducers/appointmentReducer");
function OfflineFirstAppointmentSuccess({ navigation, }) {
    const appointmentDataRedux = (0, hooks_1.useAppSelector)(appointmentReducer_1.selectAppointmentData);
    (0, react_1.useEffect)(() => {
        if (!appointmentDataRedux) {
            // Handle error if appointment is not created from backend
            console.log('Appointment is not created from backend');
        }
    }, [appointmentDataRedux]);
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(react_native_1.ScrollView, { style: [OfflineFirstAppointment_style_1.styles.scrollview] },
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.container },
                react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.marginTop4px },
                    react_1.default.createElement(atome_1.Icon, { icon: 'checkCircleFilled' })),
                react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.header },
                    react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.headerTitle, OfflineFirstAppointment_style_1.styles.greenText] }, "Ihr Termin ist gebucht!")),
                react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.locationsContainer },
                    react_1.default.createElement(react_native_1.View, { style: [OfflineFirstAppointment_style_1.styles.confirmationBox, OfflineFirstAppointment_style_1.styles.shadowContainer] },
                        react_1.default.createElement(react_native_1.View, { style: GlobalStyles_1.globalStyles.marginBottom25 },
                            appointmentDataRedux && (react_1.default.createElement(atome_1.Text, { style: [
                                    OfflineFirstAppointment_style_1.styles.blackText,
                                    OfflineFirstAppointment_style_1.styles.boldText,
                                    GlobalStyles_1.globalStyles.marginBottom25,
                                ] },
                                "Ihr Termin am",
                                ' ',
                                new Date(appointmentDataRedux.startDate).toLocaleString('de-DE', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                }),
                                ", in",
                                ' ',
                                appointmentDataRedux &&
                                    appointmentDataRedux?.location.name,
                                ' ',
                                "wurde erfolgreich gebucht. Eine Best\u00E4tigung wurde Ihnen soeben per E-Mail \u00FCbermittelt.")),
                            react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText, GlobalStyles_1.globalStyles.marginBottom25] }, "Mit der E-Mail erhalten Sie ebenfalls den Aufkl\u00E4rungsbogen und den Behandlungsvertrag mit Ihrem Arzt. Diese ben\u00F6tigen wir vor Ihrem Termin unterschrieben zur\u00FCck. Alle weiteren Informationen finden Sie in der E-Mail.")),
                        react_1.default.createElement(react_native_1.View, { style: [OfflineFirstAppointment_style_1.styles.positionRelative] },
                            react_1.default.createElement(atome_1.Icon, { icon: 'calendar', size: 60, color: '#3db384' }),
                            appointmentDataRedux && (react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.selectedDateDay] }, new Date(appointmentDataRedux?.startDate).toLocaleString('de-DE', {
                                day: '2-digit',
                            })))),
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText, GlobalStyles_1.globalStyles.marginTop25] }, "Praxis:"),
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText, OfflineFirstAppointment_style_1.styles.boldText] },
                            "nowomed",
                            ' ',
                            appointmentDataRedux && appointmentDataRedux?.location.name),
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText, GlobalStyles_1.globalStyles.marginBottom25] }, appointmentDataRedux && appointmentDataRedux?.location.address),
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText] }, "Datum / Uhrzeit:"),
                        appointmentDataRedux && (react_1.default.createElement(atome_1.Text, { style: [
                                OfflineFirstAppointment_style_1.styles.blackText,
                                OfflineFirstAppointment_style_1.styles.boldText,
                                GlobalStyles_1.globalStyles.marginBottom25,
                            ] },
                            new Date(appointmentDataRedux?.startDate).toLocaleString('de-DE', {
                                day: '2-digit',
                                month: 'long',
                                year: 'numeric',
                            }),
                            ",",
                            ' ',
                            new Date(appointmentDataRedux?.startDate).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                            }),
                            ' ',
                            "-",
                            ' ',
                            new Date(appointmentDataRedux.endDate).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false,
                            }),
                            ' ',
                            "Uhr")),
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText] }, "Arzt / \u00C4rztin:"),
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.blackText, OfflineFirstAppointment_style_1.styles.boldText] }, appointmentDataRedux && appointmentDataRedux.doctorName))),
                react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.successButton },
                    react_1.default.createElement(react_native_1.Pressable, { style: OfflineFirstAppointment_style_1.styles.greenButton, onPress: () => navigation.navigate('Dashboard') },
                        react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.greenButtonText] }, "Zum Dashboard")))))));
}
exports.OfflineFirstAppointmentSuccess = OfflineFirstAppointmentSuccess;
