"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    dayContainer: {
        width: `${100 / 7}%`,
        alignItems: 'center',
    },
    day: {
        textAlign: 'center',
        // userSelect: 'none',
        paddingVertical: react_native_1.Platform.OS === 'web' ? 3 : 6,
        paddingHorizontal: react_native_1.Platform.OS === 'web' ? 8 : 6,
        borderRadius: 5,
        color: '#000',
    },
    passedDay: {
        color: '#ccc',
    },
    slotDay: {
        borderColor: '#3db384',
        borderWidth: 1.75,
        borderStyle: 'solid',
    },
    doctorAbsent: {
        borderColor: 'orange',
        borderWidth: 1.75,
        borderStyle: 'solid',
    },
    selectedDay: {
        backgroundColor: '#3db384',
        color: '#fff',
        overflow: 'hidden',
    },
    selectedDayAbsent: {
        backgroundColor: 'orange',
        color: '#fff',
        overflow: 'hidden',
    },
});
