"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    scrollView: {
        paddingStart: 25,
        paddingEnd: 25,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
    },
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
        //padding: 20,
    },
    row: {
        flexDirection: 'row',
    },
    marginRow: {
        marginVertical: 25,
    },
    heading: {
        fontSize: 32,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
        textAlign: 'center',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    introText: {
        marginBottom: 20,
    },
    checkContainer: {
        width: 225,
        height: 225,
        marginVertical: 50,
        marginHorizontal: 'auto',
    },
    buttonContainer: {
        marginVertical: 20,
        marginHorizontal: 'auto',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        textAlign: 'center',
    },
    buttonText: {
        color: '#fff',
    },
    flexRowCenter: {
        alignItems: "center",
        justifyContent: 'center',
    },
    flexRowAlignLeft: {
        alignItems: "center",
        justifyContent: 'flex-start',
    }
});
