"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        flexDirection: 'column',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
    },
    containerMobileFromWeb: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        flexDirection: 'column',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '90%',
    },
    row: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        marginTop: 10,
    },
    marginRight0: {
        marginRight: 0,
        width: react_native_1.Platform.OS !== 'web' ? '100%' : undefined,
    },
    alignSelfCenter: {
        alignSelf: 'center',
    },
    picker: {
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 12,
        marginTop: 15,
    },
    marginNegativeTop20: {
        marginTop: -20,
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 40,
        marginBottom: 0,
        color: '#000',
    },
    textBold: {
        fontWeight: 'bold',
    },
    lineHeight20: {
        lineHeight: 20,
    },
    marginHorizontal25: {
        marginHorizontal: 25,
    },
    marginHorizontalSlider: {
        marginHorizontal: react_native_1.Platform.OS === 'web' ? 0 : -6.5,
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    saveMessageContainer: {
        marginHorizontal: 'auto',
        marginVertical: 20,
        position: 'relative',
    },
    saveMessageText: {
        position: 'absolute',
        alignSelf: 'center',
        color: '#3db384',
    },
    bottom30: {
        bottom: 30,
    },
    bottom10: {
        bottom: 10,
    },
    marginVertical0: {
        marginVertical: 0,
    },
    marginVertical20: {
        marginVertical: 20,
    },
    pickerField: {
        overflow: 'hidden',
        borderRadius: 25,
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'space-around',
        marginVertical: 30,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        fontWeight: 'bold',
        color: '#fff',
    },
    backButtonText: {
        fontWeight: 'bold',
        color: '#3db384',
    },
    diseaseBoxContainer: {
        marginVertical: 30,
        ...(Breakpoints_1.isDesktop && { flexDirection: 'row', justifyContent: 'space-between' }),
    },
    marginNegativeTop50: {
        marginTop: -50,
    },
    greenDotsContainer: {
        position: 'absolute',
        marginVertical: '70%',
        marginHorizontal: '40%',
    },
    slider: {
        height: 20,
        flex: 1,
    },
    compliantsLabel: {
        marginTop: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 27 : 0,
    },
    questionLabel: {
        marginRight: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 15 : 0,
    },
    errorText: {
        position: 'absolute',
        color: '#ff0000',
        bottom: -25,
    },
    errorMessage: {
        position: 'relative',
        bottom: 15,
        color: '#ff0000',
        textAlign: 'center',
    },
});
