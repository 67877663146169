"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FollowPrescription = void 0;
const react_1 = __importStar(require("react"));
const atome_1 = require("../../atome");
const InfoAboutMedication_1 = require("./Steps/InfoAboutMedication");
const IntroPageFUR_1 = require("./Steps/IntroPageFUR");
const DetailsOnMedication_1 = require("./Steps/DetailsOnMedication");
const GeneralSideEffects_1 = require("./Steps/GeneralSideEffects");
const PsychologicalSideEffects_1 = require("./Steps/PsychologicalSideEffects");
const SelfAssessment_1 = require("./Steps/SelfAssessment");
const Pharmacy_1 = require("./Steps/Pharmacy");
const Availability_1 = require("./Steps/Availability");
const SendFollowUpPrescription_1 = require("./Steps/SendFollowUpPrescription");
const SuccessPage_1 = require("./Steps/SuccessPage");
const ReplacePreparation_1 = require("./Steps/ReplacePreparation");
const MedicalNews_1 = require("./Steps/MedicalNews");
// @ts-ignore
function FollowPrescription({ navigation, currentPage, setCurrentPage, showFUPRModal, setShowFUPRModal, }) {
    const [prescriptionInfo, setPrescriptionInfo] = (0, react_1.useState)({
        preparationQuestion: [],
        generalSideEffects: [],
        psychologicalSideEffects: [],
    });
    let pageToRender;
    switch (currentPage) {
        case 1:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(IntroPageFUR_1.IntroPageFUR, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 2:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(InfoAboutMedication_1.InfoAboutMedication, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 3:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(DetailsOnMedication_1.DetailsOnMedication, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 4:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(GeneralSideEffects_1.GeneralSideEffects, { setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal, navigation: navigation })));
            break;
        case 5:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(PsychologicalSideEffects_1.PsychologicalSideEffects, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 6:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(SelfAssessment_1.SelfAssessment, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 7:
            pageToRender = (react_1.default.createElement(MedicalNews_1.MedicalNews, { navigation: navigation, prescriptionInfo: prescriptionInfo, setPrescriptionInfo: setPrescriptionInfo, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal }));
            break;
        case 8:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(Pharmacy_1.Pharmacy, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 9:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(Availability_1.Availability, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 10:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(ReplacePreparation_1.ReplacePreparation, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 11:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(SendFollowUpPrescription_1.SendFollowUpPrescription, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal })));
            break;
        case 12:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(SuccessPage_1.SuccessPage, { navigation: navigation, setCurrentPage: setCurrentPage })));
            break;
        default:
            pageToRender = react_1.default.createElement(react_1.default.Fragment, null);
    }
    return pageToRender;
}
exports.FollowPrescription = FollowPrescription;
