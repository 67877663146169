"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isMobile ? '100%' : 1080,
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    headerBox: {
        backgroundColor: '#3db384',
        padding: 20,
        flex: 5.5,
    },
    headerBoxTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: 10,
    },
    headerBoxButton: {
        backgroundColor: '#fff',
        paddingHorizontal: 10,
        paddingVertical: 7.5,
    },
    headerBoxButtonText: {
        fontSize: 12,
        color: '#3db384',
        textAlign: 'center',
    },
    loadingBar: {
        position: 'relative',
        width: '100%',
        paddingVertical: 5,
        borderRadius: 10,
        backgroundColor: '#fff',
        marginVertical: 10,
        marginRight: 40,
    },
    progressBar: {
        position: 'absolute',
        top: 2,
        left: 2,
        borderRadius: 10,
        backgroundColor: '#3db384',
        paddingVertical: 3,
        transition: 'width 0.75s ease-out',
    },
    saveMessageContainer: {
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: 150,
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 30,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
    backButtonText: {
        color: '#3db384',
    },
    otherDiseases: {
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-out',
    },
    otherDiseasesEnable: {
        maxHeight: 10000,
        transition: 'max-height 0.3s ease-in',
        marginBottom: 55,
    },
    errorText: {
        position: 'absolute',
        color: '#ff0000',
        bottom: -25,
    },
});
