"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    containerMobile: {
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginHorizontal: 10,
        marginVertical: 80,
        width: '95%',
        marginTop: 100,
        alignItems: 'center',
        fontFamily: 'Montserrat-Regular',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    alignCenter: {
        alignItems: 'center',
    },
    fieldSet: {
        width: '30%',
        margin: 30,
        paddingHorizontal: 40,
        paddingBottom: 20,
        borderRadius: 10,
        borderWidth: 2,
        alignItems: 'center',
        borderColor: '#3db384',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3Green: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 19 : 16,
        fontFamily: 'Montserrat-Bold',
        marginVertical: 10,
        color: '#3db384',
    },
    marginTop: {
        marginTop: Breakpoints_1.isDesktop ? '5%' : '15%',
    },
    marginBottom: {
        marginBottom: Breakpoints_1.isDesktop ? '2%' : '5%',
    },
    heading3Black: {
        textAlign: 'center',
        fontSize: 25,
        fontFamily: 'Montserrat-Bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        color: '#000',
    },
    textBold: {
        fontFamily: 'Montserrat-Bold',
    },
    morePharmaciesText: {
        color: '#3db384',
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        textDecorationLine: 'underline',
        marginTop: 20,
        marginBottom: Breakpoints_1.isDesktop ? '1%' : '5%',
        fontFamily: 'Montserrat-Bold',
    },
    button: {
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#3db384',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginVertical: 30,
        borderRadius: 25,
        alignSelf: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        width: !Breakpoints_1.isMobileOrTablet ? 'fit-content' : undefined,
        overflow: 'hidden',
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    buttonText: {
        fontSize: !Breakpoints_1.isMobileOrTablet ? 18 : 13,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
        textAlign: 'center',
        fontFamily: 'Montserrat-Bold',
    },
    textView: {
        width: '90%',
        alignItems: 'center',
        marginVertical: 40,
    },
});
