"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react-hooks/exhaustive-deps */
const react_1 = __importStar(require("react"));
const CalendlyWebview_style_1 = require("./CalendlyWebview.style");
const react_native_webview_1 = require("react-native-webview");
const react_redux_1 = require("react-redux");
const reducers_1 = require("../../../store/reducers");
const native_1 = require("@react-navigation/native");
const react_native_safe_area_context_1 = require("react-native-safe-area-context");
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function CalendlyWebview({ navigation }) {
    const user = (0, react_redux_1.useSelector)(reducers_1.selectUserInformation);
    const [url, setUrl] = (0, react_1.useState)('');
    // Rerender the webview everytime we get back on this page
    // by setting the url again
    // so the user can book another appointment
    (0, native_1.useFocusEffect)((0, react_1.useCallback)(() => {
        setUrl(`https://calendly.com/nowomed/support-callback?name=${user.firstname + ' ' + user.lastname}&email=${user.email}&a1=${user.mobile ? user.mobile : user.landline}&primary_color=3db384&hide_event_type_details=1&hide_gdpr_banner=1`);
        return () => {
            setUrl('');
        };
    }, [url]));
    return (react_1.default.createElement(react_native_safe_area_context_1.SafeAreaView, { style: CalendlyWebview_style_1.styles.container },
        react_1.default.createElement(react_native_webview_1.WebView
        // onLoadProgress={({nativeEvent}) => {
        //   if (nativeEvent.title === 'Buchungsdetails eingeben - Calendly') {
        //   }
        // }}
        , { 
            // onLoadProgress={({nativeEvent}) => {
            //   if (nativeEvent.title === 'Buchungsdetails eingeben - Calendly') {
            //   }
            // }}
            style: [CalendlyWebview_style_1.styles.calendlyContainer], source: { uri: url } })));
}
exports.default = CalendlyWebview;
