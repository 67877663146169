"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    row: {
        flexDirection: 'row',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        marginHorizontal: Breakpoints_1.isMobile ? 25 : 50,
        marginTop: 45,
        marginBottom: 25,
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 10,
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    chevron: {
        transform: [{ rotate: '0deg' }],
        transition: 'transform 0.25s ease-out',
    },
    chevronRotate: {
        transform: [{ rotate: '180deg' }],
    },
    user: {
        alignItems: 'center',
        marginBottom: 14,
        position: 'relative',
    },
    userText: {
        marginHorizontal: 15,
    },
    userMenu: {
        position: 'absolute',
        display: 'none',
        width: '100%',
        top: 27,
        paddingVertical: 10,
        marginHorizontal: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
    },
    userMenuDisplay: {
        display: 'flex',
    },
    padding10: {
        padding: 10,
    },
});
