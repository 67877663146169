"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        alignSelf: 'center',
        alignContent: 'center',
        alignItems: 'center',
        flex: 1,
        height: 'auto',
        width: Breakpoints_1.isLaptopScreen ? '70%' : '55%',
        flexDirection: 'column',
        marginVertical: 10,
        backgroundColor: '#fff',
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    label: {
        marginBottom: 10,
    },
    input: {
        width: react_native_1.Platform.OS !== 'web' ? '100%' : undefined,
    },
    textView: {
        alignItems: 'center',
        marginTop: 50,
        marginBottom: 20
    },
    heading3Green: {
        fontSize: 17,
        fontWeight: 'bold',
        marginVertical: 15,
        color: '#3db384'
    },
    heading3Black: {
        fontSize: 17,
        fontWeight: 'bold',
        marginVertical: 15,
    },
    heading4: {
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        color: '#000',
    },
    buttonSave: {
        width: 150,
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: 60
    },
    backButton: {
        alignSelf: 'center',
        marginVertical: 20
    },
    redText: {
        color: 'red',
        marginTop: 5,
    },
    inputRows: {
        flexDirection: 'row',
        width: '50%'
    }
});
