"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OfflineFirstAppointment = void 0;
const react_1 = __importStar(require("react"));
const atome_1 = require("../../../atome");
const StepOneOfflineFirstAppointment_1 = require("./OfflineFirstAppointmentSteps/StepOneOfflineFirstAppointment");
const StepTwoOfflineFirstAppointment_1 = require("./OfflineFirstAppointmentSteps/StepTwoOfflineFirstAppointment");
const StepThreeOfflineFirstAppointment_1 = require("./OfflineFirstAppointmentSteps/StepThreeOfflineFirstAppointment");
const StepFourOfflineFirstAppointment_1 = require("./OfflineFirstAppointmentSteps/StepFourOfflineFirstAppointment");
// @ts-ignore
function OfflineFirstAppointment({ navigation, currentPage, setCurrentPage, }) {
    const [firstAppointmentInfo, setFirstAppointmentInfo] = (0, react_1.useState)({});
    let pageToRender;
    switch (currentPage) {
        case 1:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepOneOfflineFirstAppointment_1.StepOneOfflineFirstAppointment, { setCurrentPage: setCurrentPage, firstAppointmentInfo: firstAppointmentInfo, setFirstAppointmentInfo: setFirstAppointmentInfo, navigation: navigation })));
            break;
        case 2:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepTwoOfflineFirstAppointment_1.StepTwoOfflineFirstAppointment, { navigation: navigation, setCurrentPage: setCurrentPage, firstAppointmentInfo: firstAppointmentInfo, setFirstAppointmentInfo: setFirstAppointmentInfo })));
            break;
        case 3:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepThreeOfflineFirstAppointment_1.StepThreeOfflineFirstAppointment, { navigation: navigation, setCurrentPage: setCurrentPage, firstAppointmentInfo: firstAppointmentInfo, setFirstAppointmentInfo: setFirstAppointmentInfo })));
            break;
        case 4:
            pageToRender = (react_1.default.createElement(atome_1.ProtectedPage, { navigation: navigation },
                react_1.default.createElement(StepFourOfflineFirstAppointment_1.StepFourOfflineFirstAppointment, { navigation: navigation, firstAppointmentInfo: firstAppointmentInfo })));
            break;
        default:
            pageToRender = react_1.default.createElement(react_1.default.Fragment, null);
    }
    return pageToRender;
}
exports.OfflineFirstAppointment = OfflineFirstAppointment;
