"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    scrollview: {
        height: '100%',
        paddingStart: 25,
        paddingEnd: 25,
        backgroundColor: 'white',
    },
    container: {
        flex: 1,
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop || Breakpoints_1.isPhablet ? 550 : '100%',
    },
    row: {
        flexDirection: 'row',
    },
    alignCenter: {
        textAlign: 'center',
    },
    firstLabel: {
        width: react_native_1.Platform.OS === 'web' && !Breakpoints_1.isDesktop ? '90%' : 'auto',
        marginBottom: 20,
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    inputContainer: {
        width: react_native_1.Platform.OS === 'web' && !Breakpoints_1.isDesktop ? '90%' : 'auto',
        marginVertical: 20,
        position: 'relative',
    },
    error: {
        color: 'red',
        position: 'absolute',
        width: '100%',
        top: 65,
    },
    errorDelete: {
        color: 'red',
        width: '100%',
        margin: 'auto',
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? undefined : 12,
    },
    errorDeleteContainer: {
        width: '80%',
    },
    errorPharmacyUserContainer: {
        width: '90%',
    },
    errorServer: {
        color: 'red',
        position: 'absolute',
        width: '100%',
        top: 105,
    },
    errorUnverified: {
        textAlign: 'center',
        color: 'red',
        position: 'absolute',
        width: '100%',
        top: Breakpoints_1.isDesktop ? -45 : -33,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginTop50: {
        marginTop: 50,
    },
    marginTop100: {
        marginTop: 100,
    },
    marginBottom20: {
        marginBottom: 20,
    },
    marginBottomXL: {
        marginBottom: 40,
    },
    buttonContainer: {
        marginVertical: 20,
        marginHorizontal: 'auto',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        minWidth: Breakpoints_1.isDesktop ? 300 : 100,
        textAlign: 'center',
    },
    buttonText: {
        color: '#fff',
    },
    backButtonText: {
        color: '#3db384',
    },
    input: {
        marginRight: 0,
    },
    heading4: {
        fontSize: 16,
        marginVertical: 20,
        color: '#000',
    },
});
