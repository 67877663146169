"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    rectangleVerified: {
        borderWidth: 1,
        borderColor: '#e3e3e3',
        width: 450,
        height: 'auto',
        backgroundColor: '#ffff',
        padding: 5,
        marginVertical: 5,
        shadowRadius: 5,
        shadowOpacity: 0.2,
    },
    titleLeft: {
        color: '#3db384',
        fontSize: 20,
        textAlign: 'left',
        maxWidth: '70%',
    },
    titleRight: {
        color: '#3db384',
        marginTop: 3,
        textAlign: 'right',
    },
    positionLeft: {
        position: 'absolute',
        left: 0,
    },
    positionRight: {
        position: 'absolute',
        right: 0,
    },
    pharmacyDescriptionGreen: {
        marginVertical: 20,
        lineHeight: 30,
        margin: 10,
        color: '#3db384',
        fontSize: 15,
        textAlign: 'center',
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 20,
    },
    ratingRow: {
        flexDirection: 'row',
        paddingHorizontal: 50,
        marginVertical: 20,
        width: '95%',
        color: '#e3e3e3',
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        textAlign: 'center',
    },
    starStyles: {
        marginHorizontal: 0.8,
    },
    offWhiteColor: {
        color: '#e3e3e3',
    },
    greenColor: {
        color: '#3db384',
    },
    detailsLineGreen: {
        alignSelf: 'center',
        borderTopColor: '#3db384',
        borderTopWidth: 1,
        width: '98%',
    },
    dividerGreen: {
        borderBottomColor: '#3db384',
        borderBottomWidth: 1,
        borderRadius: 0,
        padding: 5,
        width: 'auto',
        paddingBottom: 15,
    },
    buttonTextWhite: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
    },
    buttonTextGreen: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#3db384',
    },
    buttonTextGreenDisabled: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
    },
    buttonGreen: {
        alignItems: 'center',
        backgroundColor: '#3db384',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginHorizontal: 15,
        marginVertical: 10,
        marginLeft: 50,
        borderRadius: 25,
        alignSelf: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        width: !Breakpoints_1.isMobileOrTablet ? 'fit-content' : undefined,
        overflow: 'hidden',
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    buttonWhite: {
        alignItems: 'center',
        backgroundColor: '#fff',
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginHorizontal: 15,
        marginVertical: 10,
        borderRadius: 25,
        marginRight: 50,
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        width: react_native_1.Platform.OS === 'web' ? 'fit-content' : undefined,
        overflow: 'hidden',
    },
    buttonWhiteDisabled: {
        alignItems: 'center',
        backgroundColor: '#c1c1c1',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginHorizontal: 15,
        marginVertical: 10,
        borderRadius: 25,
        marginRight: 50,
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        width: react_native_1.Platform.OS === 'web' ? 'fit-content' : undefined,
        overflow: 'hidden',
    },
});
