"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    entryContainer: {
        marginRight: !Breakpoints_1.isMobileOrTablet ? 90 : undefined,
        alignItems: 'center',
        width: !Breakpoints_1.isMobileOrTablet ? 220 : '100%',
        marginTop: !Breakpoints_1.isMobileOrTablet ? 220 : 40,
        display: 'flex',
        flexDirection: !Breakpoints_1.isMobileOrTablet ? 'column' : 'row',
        justifyContent: !Breakpoints_1.isMobileOrTablet ? undefined : 'space-between',
        paddingStart: !Breakpoints_1.isMobileOrTablet ? undefined : 12,
        paddingEnd: !Breakpoints_1.isMobileOrTablet ? undefined : 12,
    },
    title: {
        fontSize: Breakpoints_1.isMobileOrTablet ? 14 : 16,
        fontWeight: 'bold',
        lineHeight: !Breakpoints_1.isMobileOrTablet ? 22 : 16,
        textAlign: !Breakpoints_1.isMobileOrTablet ? 'center' : 'right',
        width: !Breakpoints_1.isMobileOrTablet ? '100%' : '40%',
        height: !Breakpoints_1.isMobileOrTablet ? 40 : 54,
        paddingRight: !Breakpoints_1.isMobileOrTablet ? undefined : 12,
        paddingTop: !Breakpoints_1.isMobileOrTablet ? undefined : 10,
    },
    circle: {
        width: !Breakpoints_1.isMobileOrTablet ? 110 : 70,
        height: !Breakpoints_1.isMobileOrTablet ? 110 : 70,
        marginVertical: 25,
        borderRadius: 100,
        borderColor: '#fff',
        borderWidth: 5,
    },
    circleSmall: {
        width: !Breakpoints_1.isMobileOrTablet ? 70 : 50,
        height: !Breakpoints_1.isMobileOrTablet ? 70 : 50,
        marginVertical: 45,
    },
    circleInner: {
        width: '100%',
        height: '100%',
        borderRadius: 100,
        borderColor: '#3db384',
        backgroundColor: '#fff',
        borderWidth: 3,
    },
    circleInnerChecked: {
        backgroundColor: '#3db384',
        alignItems: 'center',
        paddingTop: !Breakpoints_1.isMobileOrTablet ? 29 : 16,
    },
    subTitle: {
        fontWeight: 'bold',
        marginBottom: !Breakpoints_1.isMobileOrTablet ? 17 : 0,
        textAlign: 'center',
        paddingLeft: !Breakpoints_1.isMobileOrTablet ? undefined : 12,
    },
    infoText: {
        fontSize: !Breakpoints_1.isMobileOrTablet ? 14 : 12,
        lineHeight: !Breakpoints_1.isMobileOrTablet ? 20 : undefined,
        textAlign: !Breakpoints_1.isMobileOrTablet ? 'center' : 'left',
        width: !Breakpoints_1.isMobileOrTablet ? undefined : '100%',
        marginLeft: !Breakpoints_1.isMobileOrTablet ? undefined : 12,
        paddingBottom: !Breakpoints_1.isMobileOrTablet ? undefined : 20,
    },
    greenText: {
        color: '#3db384',
    },
    mobileInfo: {
        width: '40%',
        paddingRight: 8,
    },
});
