"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PsychologicalSideEffects = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const FollowPrescription_style_1 = require("../FollowPrescription.style");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
const organisms_1 = require("../../../organisms");
const AbortFUPModal_1 = require("./AbortFUPModal");
const hooks_1 = require("../../../../store/hooks");
const reducers_1 = require("../../../../store/reducers");
const GlobalStyles_1 = require("../../../../helper/GlobalStyles");
function PsychologicalSideEffects(props) {
    const { navigation, setCurrentPage, showFUPRModal, setShowFUPRModal } = props;
    const prescriptionInfo = (0, hooks_1.useAppSelector)(reducers_1.selectPrescriptionInfo);
    const [prescriptionTemp, setPrescriptionTemp] = (0, react_1.useState)(prescriptionInfo);
    const dispatch = (0, hooks_1.useAppDispatch)();
    const [isButtonDisabled, setIsButtonDisabled] = (0, react_1.useState)(false);
    const [groupValues, setGroupValues] = (0, react_1.useState)([]);
    (0, react_1.useEffect)(() => {
        const noPsychologicalSideEffectsSelected = !prescriptionTemp?.psychologicalSideEffects?.length;
        noPsychologicalSideEffectsSelected
            ? setIsButtonDisabled(true)
            : setIsButtonDisabled(false);
    }, [prescriptionTemp]);
    const handleBackButton = () => {
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 4,
            },
        };
        setCurrentPage(reducerType);
    };
    const next = () => {
        dispatch((0, reducers_1.setPrescriptionInfo)(prescriptionTemp));
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 6,
            },
        };
        setCurrentPage(reducerType);
    };
    const handleCheckboxesChange = (value) => {
        if (value.includes('keine')) {
            //@ts-ignore
            setGroupValues(['keine']);
        }
        else {
            setGroupValues(value);
        }
    };
    (0, react_1.useEffect)(() => {
        setPrescriptionTemp({
            ...prescriptionTemp,
            psychologicalSideEffects: groupValues,
        });
    }, [groupValues]);
    (0, react_1.useEffect)(() => {
        setGroupValues(prescriptionInfo.psychologicalSideEffects);
    }, []);
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(native_base_1.ScrollView, { bgColor: "#fff", h: "100%", w: "100%" },
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(native_base_1.VStack, { space: 5, style: GlobalStyles_1.globalStyles.mobileToWebContainer },
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "20" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "2xl", textAlign: "center", fontWeight: "bold", color: "#3db384" }, "Psychische Nebenwirkungen")),
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "3", mb: "3" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "sm", w: "90%", textAlign: "center" }, "Bitte teilen Sie Ihrem behandelnden Arzt mit, ob im Rahmen Ihrer Cannabinoid-Therapie Nebenwirkungen und negative Erfahrungen aufgetreten sind.")),
                react_1.default.createElement(native_base_1.Checkbox.Group, { bgColor: "#f8f8f8", onChange: value => handleCheckboxesChange(value), value: groupValues, accessibilityLabel: "choose numbers" },
                    react_1.default.createElement(native_base_1.VStack, { space: 5, mt: "5", mb: "3", p: "5", w: '95%' },
                        react_1.default.createElement(native_base_1.Text, { fontWeight: "bold", mb: "1" }, "Psychische Nebenwirkungen"),
                        react_1.default.createElement(native_base_1.Checkbox, { borderColor: "#3db384", colorScheme: 'green', value: 'alteredPerception' },
                            react_1.default.createElement(native_base_1.Text, { ml: 2 }, "Ver\u00E4nderte Wahrnehmung / Bewusstseinsver\u00E4nderung")),
                        react_1.default.createElement(native_base_1.Checkbox, { borderColor: "#3db384", colorScheme: 'green', value: 'thoughtDisorders' },
                            react_1.default.createElement(native_base_1.Text, { ml: 2 }, "Denkst\u00F6rungen / Ged\u00E4chtnisst\u00F6rungen")),
                        react_1.default.createElement(native_base_1.Checkbox, { borderColor: "#3db384", colorScheme: 'green', value: 'anxiety' },
                            react_1.default.createElement(native_base_1.Text, { ml: 2 }, "Angst und Paniksituationen")),
                        react_1.default.createElement(native_base_1.Checkbox, { borderColor: "#3db384", colorScheme: 'green', value: 'hallucinations' },
                            react_1.default.createElement(native_base_1.Text, { ml: 2 }, "Halluzinationen / Psychosen")),
                        react_1.default.createElement(native_base_1.Checkbox, { borderColor: "#3db384", colorScheme: 'green', value: 'toleranceDevelopment' },
                            react_1.default.createElement(native_base_1.Text, { ml: 2 }, "Starke Toleranzentwicklung")),
                        react_1.default.createElement(native_base_1.View, { style: FollowPrescription_style_1.styles.separator },
                            react_1.default.createElement(native_base_1.Checkbox, { borderColor: "#3db384", colorScheme: 'green', value: 'keine' },
                                react_1.default.createElement(native_base_1.Text, { ml: 2 }, "Nein, ich habe keine psychischen Nebenwirkungen"))))),
                react_1.default.createElement(native_base_1.HStack, { space: 10, mb: "10", justifyContent: "center" },
                    react_1.default.createElement(native_base_1.Button, { mt: "12", w: ['40%', '10%'], alignSelf: 'center', backgroundColor: '#fff', borderRadius: "3xl", _text: { color: '#3db384', fontWeight: 'bold', underline: true }, onPress: handleBackButton }, "Zur\u00FCck"),
                    react_1.default.createElement(native_base_1.Button, { isDisabled: isButtonDisabled, mt: "12", w: ['40%', '10%'], alignSelf: 'center', backgroundColor: isButtonDisabled ? '#3db38475' : '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: next }, "Weiter"))),
            react_1.default.createElement(AbortFUPModal_1.AbortFUPModal, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal }))));
}
exports.PsychologicalSideEffects = PsychologicalSideEffects;
