"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.store = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const reducers_1 = require("./reducers");
const pharmacyReducer_1 = require("./reducers/pharmacyReducer");
const appointmentReducer_1 = require("./reducers/appointmentReducer");
const followUpPrescriptionSlice_1 = require("./reducers/followUpPrescriptionSlice");
exports.store = (0, toolkit_1.configureStore)({
    reducer: {
        auth: reducers_1.authSlice.reducer,
        user: reducers_1.userSlice.reducer,
        appData: reducers_1.appDataSlice.reducer,
        pharmacy: pharmacyReducer_1.pharmacySlice.reducer,
        appointment: appointmentReducer_1.appointmentSlice.reducer,
        followUpPrescription: followUpPrescriptionSlice_1.followUpPrescriptionSlice.reducer,
    },
});
