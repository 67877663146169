"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepThreeOfflineFirstAppointment = void 0;
/* eslint-disable react-native/no-inline-styles */
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const atome_1 = require("../../../../atome");
const organisms_1 = require("../../../../organisms");
const UtilityFunctions_1 = require("../../../../../helper/UtilityFunctions");
const OfflineFirstAppointment_style_1 = require("../OfflineFirstAppointment.style");
const GlobalStyles_1 = require("../../../../../helper/GlobalStyles");
const Breakpoints_1 = require("../../../../../helper/Breakpoints");
// @ts-ignore
function StepThreeOfflineFirstAppointment({ setCurrentPage, firstAppointmentInfo, setFirstAppointmentInfo, navigation, }) {
    const [selectedSlot, setSelectedSlot] = (0, react_1.useState)();
    const next = () => {
        if (selectedSlot) {
            setFirstAppointmentInfo({
                ...firstAppointmentInfo,
                doctorName: getDoctorName(selectedSlot.doctorId),
                doctorId: selectedSlot.doctorId,
                selectedSlot: selectedSlot,
            });
        }
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                offlineFirstAppointmentIndex: 4,
            },
        };
        setCurrentPage(reducerType);
    };
    function sortDates(array) {
        return array.sort((a, b) => {
            if (new Date(a.start) < new Date(b.start)) {
                return -1;
            }
            if (new Date(a.start) > new Date(b.start)) {
                return 1;
            }
            return 0;
        });
    }
    const getDoctorName = (id) => {
        const names = firstAppointmentInfo.location.doctors.split(', ');
        const index = firstAppointmentInfo.location.doctorsIds.indexOf(parseInt(id));
        const trimArray = names.map((element) => {
            return element.trim();
        });
        return trimArray[index];
    };
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(react_native_1.ScrollView, { style: [OfflineFirstAppointment_style_1.styles.scrollview] },
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.container },
                react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.marginTop4px },
                    react_1.default.createElement(atome_1.Icon, { icon: "calendarWithDays", size: 70, color: "#3db384" })),
                react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.header },
                    react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.headerTitle, OfflineFirstAppointment_style_1.styles.greenText] },
                        "Wann soll Ihr Erstgespr\u00E4ch in ",
                        firstAppointmentInfo.location.name,
                        ' ',
                        "stattfinden?")),
                react_1.default.createElement(react_native_1.View, { style: [OfflineFirstAppointment_style_1.styles.timeContainer] },
                    react_1.default.createElement(react_native_1.View, { style: [OfflineFirstAppointment_style_1.styles.timeDiv, { backgroundColor: '#00000010' }] },
                        react_1.default.createElement(react_native_1.View, { style: [GlobalStyles_1.globalStyles.row] },
                            react_1.default.createElement(atome_1.Text, { style: [GlobalStyles_1.globalStyles.marginTop5, OfflineFirstAppointment_style_1.styles.timeDivText] },
                                (0, UtilityFunctions_1.toDay)(firstAppointmentInfo.selectedDate.getDay()),
                                ' ',
                                new Date(firstAppointmentInfo.selectedDate).toLocaleString('de-DE', {
                                    day: '2-digit',
                                    month: 'long',
                                    year: 'numeric',
                                }))),
                        react_1.default.createElement(react_native_1.View, { style: [GlobalStyles_1.globalStyles.marginTop20] },
                            react_1.default.createElement(react_native_1.Pressable, null,
                                react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.greenLinkText], onPress: () => {
                                        let reducerType = {
                                            type: '[UPDATE]',
                                            payload: { offlineFirstAppointmentIndex: 2 },
                                        };
                                        setCurrentPage(reducerType);
                                        return;
                                    } }, "Datum \u00E4ndern")))),
                    react_1.default.createElement(react_native_1.View, null,
                        react_1.default.createElement(atome_1.Text, { style: [
                                OfflineFirstAppointment_style_1.styles.timeDivText,
                                GlobalStyles_1.globalStyles.marginTop30,
                                GlobalStyles_1.globalStyles.marginBottom10,
                                Breakpoints_1.isDesktop && GlobalStyles_1.globalStyles.paddingHorizontal15,
                            ] }, "Bitte w\u00E4hlen Sie eine Uhrzeit f\u00FCr Ihren Termin."),
                        react_1.default.createElement(react_native_1.View, null, sortDates(firstAppointmentInfo.doctorSlots).map((slot, i) => (react_1.default.createElement(react_native_1.View, { key: i }, selectedSlot &&
                            slot.start === selectedSlot.start &&
                            slot.doctorId === selectedSlot.doctorId ? (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: () => setSelectedSlot(undefined), style: [
                                OfflineFirstAppointment_style_1.styles.timeDiv,
                                OfflineFirstAppointment_style_1.styles.timeDivClicked,
                                { backgroundColor: '#3DB384', marginVertical: 5 },
                            ] },
                            react_1.default.createElement(react_native_1.View, { style: [
                                    GlobalStyles_1.globalStyles.row,
                                    GlobalStyles_1.globalStyles.marginBottom20,
                                ] },
                                react_1.default.createElement(atome_1.Text, { style: [
                                        GlobalStyles_1.globalStyles.marginTop5,
                                        OfflineFirstAppointment_style_1.styles.timeDivTextClicked,
                                    ] },
                                    new Date(slot.start).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                    }),
                                    ' ',
                                    "-",
                                    ' ',
                                    new Date(slot.end).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                    }),
                                    ' ',
                                    "Uhr")),
                            react_1.default.createElement(react_native_1.View, { style: [
                                    GlobalStyles_1.globalStyles.row,
                                    GlobalStyles_1.globalStyles.marginBottom20,
                                ] },
                                react_1.default.createElement(react_native_1.View, { style: { marginTop: 2, marginRight: 10 } },
                                    react_1.default.createElement(atome_1.Icon, { icon: "user", size: 16, color: "white" })),
                                react_1.default.createElement(atome_1.Text, { style: [{ color: 'white' }] },
                                    "Arzt: ",
                                    getDoctorName(slot.doctorId))),
                            react_1.default.createElement(react_native_1.View, { style: OfflineFirstAppointment_style_1.styles.buttonContainer },
                                react_1.default.createElement(react_native_1.Pressable, { style: OfflineFirstAppointment_style_1.styles.button, onPress: next },
                                    react_1.default.createElement(atome_1.Text, { style: [OfflineFirstAppointment_style_1.styles.buttonText] }, "Termin buchen"))))) : (react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: () => setSelectedSlot(slot), style: [
                                OfflineFirstAppointment_style_1.styles.timeDiv,
                                { marginVertical: 5, backgroundColor: 'white' },
                            ] },
                            react_1.default.createElement(react_native_1.View, { style: [
                                    GlobalStyles_1.globalStyles.row,
                                    GlobalStyles_1.globalStyles.marginBottom20,
                                ] },
                                react_1.default.createElement(atome_1.Text, { style: [
                                        GlobalStyles_1.globalStyles.marginTop5,
                                        OfflineFirstAppointment_style_1.styles.timeDivText,
                                    ] },
                                    new Date(slot.start).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                    }),
                                    ' ',
                                    "-",
                                    ' ',
                                    new Date(slot.end).toLocaleTimeString([], {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false,
                                    }),
                                    ' ',
                                    "Uhr")),
                            react_1.default.createElement(react_native_1.View, { style: [
                                    GlobalStyles_1.globalStyles.row,
                                    GlobalStyles_1.globalStyles.marginBottom20,
                                ] },
                                react_1.default.createElement(react_native_1.View, { style: { marginTop: 2, marginRight: 10 } },
                                    react_1.default.createElement(atome_1.Icon, { icon: "user", size: 16, color: "black" })),
                                react_1.default.createElement(atome_1.Text, null,
                                    "Arzt: ",
                                    getDoctorName(slot.doctorId)))))))))))))));
}
exports.StepThreeOfflineFirstAppointment = StepThreeOfflineFirstAppointment;
