"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginVertical: 100,
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    containerMobileFromWeb: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        flexDirection: 'column',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '90%',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3Green: {
        textAlign: 'center',
        fontSize: 18,
        marginVertical: 40,
        color: '#3db384',
        width: '80%',
        fontFamily: 'Montserrat-Bold',
    },
    heading3Black: {
        marginBottom: 20,
        fontSize: 20,
        color: '#000',
        textAlign: 'center',
        fontFamily: 'Montserrat-Bold',
    },
    heading4: {
        textAlign: 'center',
        fontSize: 18,
        marginBottom: 0,
        color: '#000',
        fontFamily: 'Montserrat-Regular',
    },
    tipText: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 0,
        color: '#000',
        fontFamily: 'Montserrat-Regular',
    },
    marginHinweis: {
        marginTop: 30,
    },
    buttonContainer: {
        flexDirection: 'column',
        marginTop: 80,
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        fontWeight: 'bold',
        color: '#fff',
    },
    backButtonText: {
        fontWeight: 'bold',
        color: '#3db384',
    },
});
