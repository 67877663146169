"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    picker: {
        borderWidth: 0,
        backgroundColor: '#EFEFEE',
        borderRadius: 24,
        paddingVertical: 6,
        paddingHorizontal: 12,
        minHeight: 36,
        marginTop: 6,
        marginRight: 25,
        minWidth: 125,
        flex: 1,
        '-webkit-appearance': 'none',
        color: '#000000',
        fontSize: 14,
        fontFamily: 'Montserrat-Regular',
    },
    error: {
        borderColor: '#ff0000',
        borderWidth: 1,
    },
});
