"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: 1080,
    },
    row: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        marginTop: 10,
    },
    marginRight0: {
        marginRight: 0,
        width: react_native_1.Platform.OS !== 'web' ? '100%' : undefined,
    },
    alignSelfCenter: {
        alignSelf: 'center',
    },
    picker: {
        width: '100%',
        paddingVertical: 10,
        paddingHorizontal: 12,
        marginTop: 15,
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 40,
        marginBottom: 0,
        color: '#000',
    },
    diseaseBoxContainer: {
        marginVertical: 30,
        marginBottom: 20,
        ...(Breakpoints_1.isDesktop && { flexDirection: 'row', justifyContent: 'space-between' }),
    },
    saveMessageContainer: {
        marginVertical: 30,
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: 150,
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    marginVertical20: {
        marginVertical: 20,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 30,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
    backButtonText: {
        color: '#3db384',
    },
    slider: {
        flex: 1,
        height: 20,
    },
    compliantsLabel: {
        marginTop: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 27 : 0,
    },
    questionLabel: {
        marginRight: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 15 : 0,
    },
    errorText: {
        position: 'absolute',
        color: '#ff0000',
        bottom: -25,
    },
    marginNegativeTop20: {
        marginTop: -20,
    },
});
