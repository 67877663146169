"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        alignSelf: 'center',
        flex: 1,
        height: '100%',
        width: '40%',
        flexDirection: 'column',
        marginVertical: 100,
        backgroundColor: '#fff',
    },
    pharmacyNameText: {
        color: '#3db384',
        fontWeight: 'bold',
        fontSize: 20,
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3Green: {
        fontSize: 15,
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#3db384',
    },
    ratingRow: {
        flexDirection: 'row',
        color: '#e3e3e3',
        marginTop: 40,
    },
    detailsLine: {
        borderTopColor: '#e3e3e3',
        borderTopWidth: 2,
        marginVertical: 10,
    },
    premiumText: {
        color: '#3db384',
        paddingRight: 42,
        fontSize: 13.5,
        marginVertical: -2,
    },
    imageStyle: {
        flex: 1,
        resizeMode: 'contain',
    },
    buttonSave: {
        width: 250,
        alignSelf: 'center',
        marginVertical: 60,
    },
    backButton: {
        alignSelf: 'center',
        marginTop: 100,
    },
});
