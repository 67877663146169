"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    scrollView: {},
    whiteBackground: {
        backgroundColor: '#fff',
    },
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: '100%',
        paddingStart: 25,
        paddingEnd: 25,
        maxWidth: react_native_1.Platform.OS === 'web' ? 1080 : '100%',
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    fontBold: {
        fontWeight: 'bold',
    },
    fontWeight100: {
        fontWeight: '100',
    },
    fontSize24: {
        fontSize: 24,
    },
    fontSize120: {
        fontSize: 120,
    },
    row: {
        flexDirection: 'row',
    },
    column: {
        flexDirection: 'column',
    },
    marginRow: {
        marginVertical: Breakpoints_1.isMobile ? 15 : 25,
    },
    marginTop25: {
        marginTop: 25,
    },
    marginBottom0: {
        marginBottom: 0,
    },
    marginBottom15: {
        marginBottom: 15,
    },
    marginBottom25: {
        marginBottom: 25,
    },
    marginRight0: {
        marginRight: 0,
    },
    marginLeft40: {
        marginLeft: 40,
    },
    flex025: {
        flex: 0.25,
    },
    flex1: {
        flex: 1,
    },
    flex5: {
        flex: 5,
    },
    flex15: {
        flex: 1.5,
    },
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    saveMessageContainer: {
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: 150,
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
        left: 0,
        right: 0,
    },
    buttonContainer: {
        justifyContent: 'center',
        marginVertical: 30,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
    backButtonText: {
        color: '#3db384',
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    slider: {
        height: 20,
        flex: 1,
    },
    marginTopText: {
        marginTop: 27,
    },
    questionLabel: {
        marginRight: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 15 : 0,
        marginTop: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 27 : 0,
        marginBottom: Breakpoints_1.isMobile ? 15 : 'auto',
        fontWeight: Breakpoints_1.isMobile ? 'bold' : 'auto',
        fontSize: Breakpoints_1.isMobile ? 17 : 'auto',
    },
    marginLeftText: {
        marginLeft: Breakpoints_1.isPhablet || Breakpoints_1.isDesktop ? 30 : 0,
    },
    textAlignCenter: {
        textAlign: 'center',
    },
});
