"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        marginVertical: 55,
        paddingHorizontal: 25,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'transparent'
    },
    calendlyContainer: {
        flex: 1,
        height: '100%',
        width: 400,
        marginVertical: 20,
        zIndex: 10,
    },
    greenButton: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 24,
        paddingVertical: 8,
        marginTop: 24,
    },
    greenButtonText: {
        color: '#fff',
        fontSize: 14,
    },
    centerButton: {
        alignItems: 'center'
    },
    backButtonText: {
        color: '#3db384',
    },
    whiteBackground: {
        backgroundColor: '#fff',
        height: '100%',
    },
    alignCenter: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    heading2: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 10,
        color: '#000',
    },
    descriptionText: {
        lineHeight: 20,
        textAlign: "center"
    },
    headerBox: {
        backgroundColor: '#3db384',
        padding: 20,
        flex: 5.5,
    },
    buttonContainer: {
        marginVertical: 40,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 30,
        paddingVertical: 10,
    },
    buttonText: {
        fontWeight: 'bold',
        color: '#fff',
    },
});
