"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        height: '100vh',
        width: '100%',
        backgroundColor: '#fff',
        flexDirection: !Breakpoints_1.isMobileOrTablet ? 'row' : 'column',
        overflowX: Breakpoints_1.isMobileOrTablet ? 'undefined' : 'scroll',
        overflowY: Breakpoints_1.isMobileOrTablet ? 'overflow' : 'clip',
        position: !Breakpoints_1.isMobileOrTablet ? 'absolute' : undefined,
    },
    whiteBackground: {
        height: '100%',
        backgroundColor: '#fff',
    },
    greenDotsContainer: {
        position: 'absolute',
        marginVertical: '70%',
        marginHorizontal: '40%',
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: !Breakpoints_1.isMobileOrTablet ? 'row' : 'column',
        alignItems: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        position: 'relative',
    },
    timeline: {
        height: !Breakpoints_1.isMobileOrTablet ? 2 : '100%',
        width: !Breakpoints_1.isMobileOrTablet ? '100%' : 3,
        backgroundColor: '#3db384',
        position: 'absolute',
        top: !Breakpoints_1.isMobileOrTablet ? 338 : 72,
        marginLeft: !Breakpoints_1.isMobileOrTablet ? 100 : undefined,
    },
    heading2: {
        fontSize: !Breakpoints_1.isMobileOrTablet ? 36 : 24,
        fontWeight: 'bold',
        marginBottom: !Breakpoints_1.isMobileOrTablet ? 40 : 0,
        color: '#fff',
        textAlign: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
    },
    heading3: {
        fontSize: !Breakpoints_1.isMobileOrTablet ? 20 : 18,
        fontWeight: 'bold',
        marginVertical: 7.5,
        color: '#fff',
        textAlign: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        paddingHorizontal: !Breakpoints_1.isMobileOrTablet ? undefined : '8%',
        textTransform: !Breakpoints_1.isMobileOrTablet ? undefined : 'uppercase',
    },
    text: {
        color: '#fff',
        textAlign: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        paddingHorizontal: !Breakpoints_1.isMobileOrTablet ? undefined : '8%',
    },
    paddingHorizontal4pct: {
        paddingHorizontal: '4%',
    },
    area: {
        paddingTop: !Breakpoints_1.isMobileOrTablet ? 100 : undefined,
    },
    current: {
        backgroundColor: '#3db384',
        marginTop: 0,
        height: '100vh',
        paddingHorizontal: 40,
        borderLeftWidth: 4,
        borderRightWidth: 4,
        borderColor: '#fff',
        paddingTop: 129,
        position: 'relative',
        width: 650,
        marginRight: 100,
    },
    mobileCurrent: {
        backgroundColor: '#3db384',
        width: '100%',
        height: 400,
        borderRadius: 200,
        paddingVertical: 48,
        paddingHorizontal: 20,
        marginTop: 40,
    },
    mobileCurrentDeclinedPatient: {
        backgroundColor: '#FF991A',
        width: '100%',
        height: 400,
        borderRadius: 200,
        paddingVertical: 48,
        paddingHorizontal: 20,
        marginTop: 40,
    },
    mobileCurrentHeader: {
        alignItems: 'center',
    },
    button: {
        alignItems: 'center',
        backgroundColor: '#fff',
        paddingVertical: 8,
        paddingHorizontal: 30,
        textTransform: 'uppercase',
        marginVertical: 10,
        borderRadius: 25,
        alignSelf: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        width: !Breakpoints_1.isMobileOrTablet ? 'fit-content' : undefined,
        overflow: 'hidden',
    },
    button1: {
        alignItems: 'center',
        backgroundColor: 'transparent',
        paddingVertical: 8,
        paddingHorizontal: 30,
        textTransform: 'uppercase',
        marginVertical: 10,
        borderRadius: 25,
        width: 'fit-content',
        overflow: 'hidden',
        borderColor: '#fff',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    helpButton: {
        backgroundColor: '#3db384',
    },
    buttonInfo: {
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
        paddingVertical: 4,
    },
    buttonText: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#3db384',
    },
    buttonText1: {
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
    },
    helpButtonText: {
        color: '#fff',
    },
    loadingBar: {
        position: 'relative',
        width: !Breakpoints_1.isMobileOrTablet ? '100%' : '84%',
        paddingVertical: 5,
        backgroundColor: '#fff',
        marginTop: !Breakpoints_1.isMobileOrTablet ? 65 : '8%',
        marginBottom: !Breakpoints_1.isMobileOrTablet ? 40 : '4%',
        marginLeft: !Breakpoints_1.isMobileOrTablet ? undefined : '8%',
    },
    progressBar: {
        position: 'absolute',
        top: 2,
        left: 2,
        backgroundColor: '#3db384',
        paddingVertical: 3,
        ...react_native_1.Platform.select({
            web: { transition: 'width 0.75s ease-out' },
        }),
    },
    appointmentContainer: {
        position: 'relative',
    },
    mobileCalendar: {
        marginVertical: 20,
        marginHorizontal: 50,
    },
    appointmentDay: {
        left: 0,
        right: 0,
        top: !Breakpoints_1.isMobileOrTablet ? 15 : 20,
        bottom: 0,
        marginBottom: !Breakpoints_1.isMobileOrTablet ? 'unset' : undefined,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileAppointment: {
        display: 'flex',
        marginLeft: 90,
    },
    mobileAdresse: {
        display: 'flex',
        flexDirection: 'row',
    },
    addresseText: {
        color: '#fff',
    },
    mobileDivider: {
        height: 1,
        width: '90%',
        backgroundColor: '#fff',
        marginLeft: 20,
        marginBottom: 20,
    },
    helpContainer: {
        position: 'absolute',
        bottom: 40,
        left: 0,
        right: 0,
        paddingHorizontal: 30,
    },
    mobileHelpContainer: {
        backgroundColor: '#efefef',
        marginVertical: 20,
        paddingTop: 40,
        display: 'flex',
        alignItems: 'center',
        height: 270,
    },
    mobileHelpHeader: {
        color: '#3db384',
        fontWeight: 'bold',
        fontSize: 14,
        marginBottom: 15,
    },
    mobileHelpText: {
        width: '60%',
        color: '#3db384',
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 15,
    },
    containerHeader: {
        color: '#fff',
        fontWeight: 'bold',
        marginBottom: 5,
        width: !Breakpoints_1.isMobileOrTablet ? undefined : 150,
    },
    containerText: {
        color: '#ffffffb3',
        lineHeight: !Breakpoints_1.isMobileOrTablet ? 25 : undefined,
    },
    containerDivider: {
        flexDirection: 'row',
        marginVertical: 10,
    },
    divider: {
        borderColor: 'white',
        borderBottomWidth: 1,
        borderStyle: 'solid',
        flex: 1,
        alignSelf: 'center',
    },
    textDivider: {
        color: 'white',
        paddingHorizontal: 10,
        alignSelf: 'center',
    },
});
