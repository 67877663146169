"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfAssessment = void 0;
const native_base_1 = require("native-base");
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const atome_1 = require("../../../atome");
const UtilityFunctions_1 = require("../../../../helper/UtilityFunctions");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
const organisms_1 = require("../../../organisms");
const AbortFUPModal_1 = require("./AbortFUPModal");
const hooks_1 = require("../../../../store/hooks");
const reducers_1 = require("../../../../store/reducers");
const GlobalStyles_1 = require("../../../../helper/GlobalStyles");
function SelfAssessment(props) {
    const { navigation, setCurrentPage, showFUPRModal, setShowFUPRModal } = props;
    const [isButtonDisabled, setIsButtonDisabled] = (0, react_1.useState)(false);
    const prescriptionInfo = (0, hooks_1.useAppSelector)(reducers_1.selectPrescriptionInfo);
    const [prescriptionTemp, setPrescriptionTemp] = (0, react_1.useState)(prescriptionInfo);
    const dispatch = (0, hooks_1.useAppDispatch)();
    (0, react_1.useEffect)(() => {
        const error = !(0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'isSafeHandling') ||
            !(0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'roadTraffic');
        error ? setIsButtonDisabled(true) : setIsButtonDisabled(false);
    }, [prescriptionTemp]);
    const handleBackButton = () => {
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 5,
            },
        };
        setCurrentPage(reducerType);
    };
    const handleIsSafeHandlingChange = (value) => {
        setPrescriptionTemp({
            ...prescriptionTemp,
            isSafeHandling: value,
        });
    };
    const handleRoadTrafficChange = (value) => {
        setPrescriptionTemp({
            ...prescriptionTemp,
            roadTraffic: value,
        });
    };
    const next = () => {
        dispatch((0, reducers_1.setPrescriptionInfo)(prescriptionTemp));
        let reducerType = {
            type: '[UPDATE]',
            payload: {
                followUpPrescriptionsIndex: 7,
            },
        };
        setCurrentPage(reducerType);
    };
    return (react_1.default.createElement(react_native_1.SafeAreaView, null,
        react_1.default.createElement(native_base_1.ScrollView, { bgColor: "#fff", h: "100%", w: "100%" },
            Breakpoints_1.isDesktop && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(native_base_1.VStack, { space: 5, alignItems: 'center', style: GlobalStyles_1.globalStyles.mobileToWebContainer },
                react_1.default.createElement(native_base_1.Center, { w: "100%", mt: "20" },
                    react_1.default.createElement(native_base_1.Text, { fontSize: "2xl", textAlign: "center", fontWeight: "bold", color: "#3db384" }, "Selbsteinsch\u00E4tzung")),
                react_1.default.createElement(native_base_1.Center, { w: "90%", mt: "3" },
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center" }, "Ich f\u00FChle mich im Umgang und in der Anwendung meiner aktuellen Medikation sicher. Eine Videosprechstunde mit meinem behandelnden Arzt ist derzeit nicht notwendig.")),
                react_1.default.createElement(native_base_1.HStack, { space: 70, mt: "3", justifyContent: "center" },
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: prescriptionTemp.isSafeHandling, label: "Ja", onPress: () => handleIsSafeHandlingChange(true) }),
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: (0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'isSafeHandling') &&
                            !prescriptionTemp.isSafeHandling, label: "Nein", onPress: () => handleIsSafeHandlingChange(false) })),
                react_1.default.createElement(native_base_1.Center, { my: "5", w: "90%" },
                    react_1.default.createElement(native_base_1.Text, { textAlign: "center" }, "Ich f\u00FChle mich mit meiner aktuellen Medikation sicher im Rahmen der Selbsteinsch\u00E4tzung (Selbstevaluation) am Stra\u00DFenverkehr teilzunehmen.")),
                react_1.default.createElement(native_base_1.VStack, { mt: "3", justifyContent: "center" },
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: (0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'roadTraffic') &&
                            prescriptionTemp.roadTraffic === 'yes', label: "Ja", onPress: () => handleRoadTrafficChange('yes') }),
                    react_1.default.createElement(atome_1.RadioButton, { isSelected: (0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'roadTraffic') &&
                            prescriptionTemp.roadTraffic === 'no', label: "Nein", onPress: () => handleRoadTrafficChange('no') }),
                    react_1.default.createElement(atome_1.RadioButton, { label: "Ich fahre kein Auto", isSelected: (0, UtilityFunctions_1.isInObject)(prescriptionTemp, 'roadTraffic') &&
                            prescriptionTemp.roadTraffic === 'noCar', onPress: () => handleRoadTrafficChange('noCar') })),
                react_1.default.createElement(native_base_1.HStack, { space: [4, 10], justifyContent: "center", pb: "250" },
                    react_1.default.createElement(native_base_1.Button, { mt: "12", w: "40%", backgroundColor: '#fff', borderRadius: "3xl", _text: { color: '#3db384', fontWeight: 'bold', underline: true }, onPress: handleBackButton }, "Zur\u00FCck"),
                    react_1.default.createElement(native_base_1.Button, { disabled: isButtonDisabled, mt: "12", w: ['40%', '80%'], backgroundColor: isButtonDisabled ? '#3db38475' : '#3db384', borderRadius: "3xl", _text: { color: '#fff', fontWeight: 'bold' }, onPress: next }, "Weiter"))),
            react_1.default.createElement(AbortFUPModal_1.AbortFUPModal, { navigation: navigation, setCurrentPage: setCurrentPage, showFUPRModal: showFUPRModal, setShowFUPRModal: setShowFUPRModal }))));
}
exports.SelfAssessment = SelfAssessment;
