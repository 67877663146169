"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectUserInformation = exports.setUserInformation = exports.userSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState_1 = require("./initialState");
const initialState = initialState_1.initialUserInformation;
exports.userSlice = (0, toolkit_1.createSlice)({
    name: 'user',
    initialState,
    reducers: {
        setUserInformation: (state, action) => {
            /* eslint-disable */
            return action.payload;
            /* eslint-enable */
        },
    },
});
exports.setUserInformation = exports.userSlice.actions.setUserInformation;
const selectUserInformation = (state) => state.user;
exports.selectUserInformation = selectUserInformation;
