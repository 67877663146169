"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        marginVertical: Breakpoints_1.isDesktop ? 10 : 50,
        marginHorizontal: Breakpoints_1.isDesktop ? '20%' : '5%',
        // width: isDesktop ? '40%' : undefined,
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        height: '100%',
        width: '100%',
        backgroundColor: '#fff',
    },
    heading1: {
        fontSize: 22,
        fontWeight: 'bold',
        marginTop: 45,
        color: '#000',
    },
    heading2: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: Breakpoints_1.isDesktop ? 65 : 45,
        color: '#000',
    },
    input: {
        marginRight: 0,
        width: react_native_1.Platform.OS !== 'web' ? '100%' : undefined,
    },
    label: {
        marginBottom: 10,
    },
    streetField: {
        minWidth: 350,
        maxWidth: 350,
    },
    emailField: {
        minWidth: 450,
        maxWidth: 450,
    },
    emailFieldNotAllowed: {
        minWidth: 450,
        maxWidth: 450,
        cursor: 'not-allowed',
    },
    houseNumberInputField: {
        minWidth: 170,
        maxWidth: 170,
    },
    phoneNumberField: {
        minWidth: 350,
        maxWidth: 350,
    },
    countryInputField: {
        minWidth: 250,
        maxWidth: 250,
        maxHeight: 20,
    },
    picker: {
        width: '100%',
        paddingVertical: 10,
    },
    descriptionText: {
        color: '#000',
        fontSize: 16,
        lineHeight: 25,
        marginBottom: 25,
    },
    marginRow: {
        marginVertical: Breakpoints_1.isDesktop ? 25 : 10,
    },
    bottomMinus38: {
        bottom: -38,
    },
    width250: {
        width: '250px',
    },
    buttonContainer: {
        justifyContent: Breakpoints_1.isDesktop ? 'flex-start' : 'center',
        marginTop: 40,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: Breakpoints_1.isDesktop ? 30 : 40,
        paddingVertical: 10,
    },
    buttonText: {
        textTransform: Breakpoints_1.isDesktop ? 'uppercase' : 'none',
        color: '#fff',
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: Breakpoints_1.isDesktop ? 30 : 40,
        paddingVertical: 10,
    },
    saveMessageContainer: {
        marginHorizontal: 'auto',
        position: 'relative',
        minWidth: react_native_1.Platform.OS === 'web' ? 151 : '100%',
    },
    saveMessageText: {
        color: '#3db384',
        position: 'absolute',
        left: react_native_1.Platform.OS === 'web' ? 0 : 100,
        right: 0,
        top: 10,
    },
    errorText: {
        position: 'absolute',
        color: '#ff0000',
        bottom: -20,
        fontSize: Breakpoints_1.isDesktop ? 16 : 12,
    },
});
