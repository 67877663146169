"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalStyles = exports.COLORS = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("./Breakpoints");
exports.COLORS = {
    black: '#000',
    white: '#fff',
    green: '#3db384',
};
exports.globalStyles = react_native_1.StyleSheet.create({
    mobileToWebContainer: react_native_1.Platform.OS === 'web'
        ? {
            maxWidth: '800px',
            flex: 1,
            alignSelf: 'center',
        }
        : {},
    font14: {
        fontSize: 14,
    },
    font16: {
        fontSize: 16,
    },
    marginVertical0: {
        marginVertical: 0,
    },
    marginVertical15: {
        marginVertical: 15,
    },
    marginVertical25: {
        marginVertical: 25,
    },
    marginVertical35: {
        marginVertical: 35,
    },
    marginVertical40: {
        marginVertical: 40,
    },
    marginVertical50: {
        marginVertical: 50,
    },
    marginRow: {
        marginVertical: 25,
    },
    marginTop0: {
        marginTop: 0,
    },
    marginTop5: {
        marginTop: 5,
    },
    marginTop10: {
        marginTop: 10,
    },
    marginTop15: {
        marginTop: 15,
    },
    marginTop20: {
        marginTop: 20,
    },
    marginTop25: {
        marginTop: 25,
    },
    marginTop30: {
        marginTop: 30,
    },
    marginTop35: {
        marginTop: 35,
    },
    marginTop40: {
        marginTop: 40,
    },
    marginTop50: {
        marginTop: 50,
    },
    marginTop60: {
        marginTop: 60,
    },
    marginTop75: {
        marginTop: 75,
    },
    marginTop80: {
        marginTop: 80,
    },
    marginLeftMinus7: {
        marginLeft: -7,
    },
    marginLeft10: {
        marginLeft: 10,
    },
    marginLeft15: {
        marginLeft: 15,
    },
    marginLeft20: {
        marginLeft: 20,
    },
    marginLeft40: {
        marginLeft: 40,
    },
    marginRight10: {
        marginRight: 10,
    },
    marginLeft25: {
        marginLeft: 25,
    },
    marginRight0: {
        marginRight: 0,
    },
    marginRight25: {
        marginRight: 25,
    },
    marginRight50: {
        marginRight: 50,
    },
    marginBottom0: {
        marginBottom: 0,
    },
    marginBottom5: {
        marginBottom: 5,
    },
    marginBottom10: {
        marginBottom: 10,
    },
    marginBottom12: {
        marginBottom: 12,
    },
    marginBottom15: {
        marginBottom: 15,
    },
    marginBottom20: {
        marginBottom: 20,
    },
    marginBottom25: {
        marginBottom: 25,
    },
    marginBottom30: {
        marginBottom: 30,
    },
    marginBottom35: {
        marginBottom: 35,
    },
    marginBottom40: {
        marginBottom: 40,
    },
    marginBottom50: {
        marginBottom: 50,
    },
    marginBottom70: {
        marginBottom: 70,
    },
    paddingLeft60: {
        paddingLeft: 60,
    },
    paddingRight5: {
        paddingRight: 5,
    },
    paddingHorizontal4pct: {
        paddingHorizontal: '4%',
    },
    paddingHorizontal15: {
        paddingHorizontal: 15,
    },
    paddingHorizontal25: {
        paddingHorizontal: 25,
    },
    paddingHorizontal30: {
        paddingHorizontal: 30,
    },
    paddingHorizontal100: {
        paddingHorizontal: 100,
    },
    marginHorizontal15: {
        marginHorizontal: 15,
    },
    marginHorizontal25: {
        marginHorizontal: 25,
    },
    marginHorizontal35: {
        marginHorizontal: 35,
    },
    marginNegativeBottom20: {
        marginBottom: -20,
    },
    width100: {
        width: '100%',
    },
    width80: {
        width: '80%',
    },
    width50: {
        width: '50%',
    },
    width40: {
        width: '40%',
    },
    flex0: {
        flex: 0,
    },
    flex1: {
        flex: 1,
    },
    flex2: {
        flex: 2,
    },
    flex3: {
        flex: 3,
    },
    flex4: {
        flex: 4,
    },
    flex5: {
        flex: 5,
    },
    flex15: {
        flex: 1.5,
    },
    row: {
        flexDirection: 'row',
    },
    column: {
        flexDirection: 'column',
    },
    positionRelative: {
        position: 'relative',
    },
    positionAbsolute: {
        position: 'absolute',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    alignCenter: {
        alignItems: 'center',
    },
    alignTop: {
        alignItems: 'flex-start',
    },
    alignRight: {
        alignItems: 'flex-end',
    },
    modalContainer: {
        marginVertical: 2,
        marginHorizontal: 2,
    },
    modalContainerWeb: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        margin: 'auto',
    },
    modalTitle: {
        fontSize: Breakpoints_1.isDesktop ? 24 : 20,
        fontWeight: 'bold',
        marginBottom: Breakpoints_1.isDesktop ? 24 : 16,
        color: '#000',
    },
    modalButtonsContainer: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        marginTop: Breakpoints_1.isDesktop ? 24 : 16,
        justifyContent: Breakpoints_1.isDesktop ? 'flex-start' : 'space-around',
    },
    button: {
        backgroundColor: exports.COLORS.green,
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        marginRight: 10,
    },
    buttonSecondary: {
        backgroundColor: exports.COLORS.white,
        borderColor: exports.COLORS.green,
        borderWidth: 1,
        marginLeft: 10,
        marginRight: 0,
    },
    buttonTextStyle: {
        color: exports.COLORS.white,
        textAlign: 'center',
    },
    buttonTextStyleSecondary: {
        color: exports.COLORS.black,
        textAlign: 'center',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textUnBold: {
        fontWeight: 'normal',
    },
    greenText: {
        color: exports.COLORS.green,
    },
    followUpRecipeButton: {
        borderRadius: 24,
        paddingVertical: 10,
        marginTop: 15,
        height: 38,
        padding: Breakpoints_1.isDesktop ? 12 : 0,
        marginRight: Breakpoints_1.isDesktop ? 10 : 0,
    },
    followUpRecipeButtonPrimary: {
        backgroundColor: exports.COLORS.green,
    },
    followUpRecipeButtonSecondary: {
        backgroundColor: exports.COLORS.white,
        borderColor: exports.COLORS.green,
        borderWidth: 1,
    },
    saveButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    cookieText: {
        fontSize: 13,
    },
    underlined: {
        textDecorationLine: 'underline',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
});
