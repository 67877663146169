"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
        paddingBottom: 40,
    },
    scrollView: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 14,
    },
    row: {
        flexDirection: 'row',
    },
    marginRow: {
        marginVertical: 17.5,
    },
    marginBottom15: {
        marginBottom: 15,
    },
    alignCenter: {
        alignItems: 'center',
    },
    buttonContainer: {
        justifyContent: 'center',
        paddingBottom: 50,
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
    disabledButton: {
        opacity: 0.75,
    },
});
