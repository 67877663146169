"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.goBack = exports.navigationRef = void 0;
const native_1 = require("@react-navigation/native");
const NavigationFunctions_1 = require("../components/organisms/NavigationDrawer/NavigationFunctions");
exports.navigationRef = (0, native_1.createNavigationContainerRef)();
function goBack(state, reactDispatch, isFemale, hasOtherDiseases, prescriptionInfo, setShowFUPRModal) {
    if (exports.navigationRef.isReady()) {
        const route = exports.navigationRef.getCurrentRoute();
        if (route) {
            (0, NavigationFunctions_1.BackFunction)({
                route: route,
                state,
                reactDispatch,
                navigation: exports.navigationRef,
                isFemale,
                hasOtherDiseases,
                prescriptionInfo,
                setShowFUPRModal,
            });
        }
    }
}
exports.goBack = goBack;
