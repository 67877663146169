"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const size = 25;
exports.styles = react_native_1.StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginRight: 25,
        marginBottom: 15,
    },
    radioButton: {
        height: size,
        width: size,
        borderRadius: size / 2,
        backgroundColor: '#EFEFEE',
        alignItems: 'center',
        justifyContent: 'center',
    },
    active: {
        height: size / 2.5,
        width: size / 2.5,
        borderRadius: 6,
        backgroundColor: '#3db384',
    },
    label: {
        alignSelf: 'center',
        marginLeft: 10,
    },
});
