"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const GlobalStyles_1 = require("../../../helper/GlobalStyles");
exports.styles = react_native_1.StyleSheet.create({
    modalContainer: {
        position: 'absolute',
    },
    row: {
        flexDirection: 'row',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.6)',
    },
    modalView: {
        margin: 20,
        backgroundColor: GlobalStyles_1.COLORS.white,
        borderRadius: 4,
        padding: 35,
        alignItems: 'center',
        shadowColor: GlobalStyles_1.COLORS.black,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        maxWidth: 600,
    },
    button: {
        backgroundColor: GlobalStyles_1.COLORS.green,
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
        marginRight: 10,
    },
    buttonSecondary: {
        backgroundColor: GlobalStyles_1.COLORS.white,
        borderColor: GlobalStyles_1.COLORS.green,
        borderWidth: 1,
        marginLeft: 10,
        marginRight: 0,
    },
    textStyle: {
        color: GlobalStyles_1.COLORS.white,
        textAlign: 'center',
    },
    textStyleSecondary: {
        color: GlobalStyles_1.COLORS.black,
        textAlign: 'center',
    },
    modalTextContainer: {
        marginBottom: 20,
        maxHeight: 600,
    },
});
