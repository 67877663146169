"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginTop: 80,
        alignItems: 'center',
        marginVertical: 55,
    },
    containerMobile: {
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginHorizontal: 10,
        width: '95%',
        marginTop: 50,
        alignItems: 'center',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3Green: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 19 : 16,
        fontWeight: 'bold',
        marginVertical: 10,
        color: '#3db384',
    },
    heading4: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        color: '#000',
    },
    morePharmaciesText: {
        color: '#3db384',
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        textDecorationLine: 'underline',
        marginTop: '1%',
        marginBottom: Breakpoints_1.isDesktop ? '1%' : '5%',
        fontWeight: "bold"
    },
    button: {
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: '#3db384',
        paddingVertical: 8,
        paddingHorizontal: 30,
        marginVertical: 30,
        borderRadius: 25,
        alignSelf: !Breakpoints_1.isMobileOrTablet ? undefined : 'center',
        width: !Breakpoints_1.isMobileOrTablet ? 'fit-content' : undefined,
        overflow: 'hidden',
        borderColor: '#3db384',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    buttonText: {
        fontSize: !Breakpoints_1.isMobileOrTablet ? 18 : 13,
        lineHeight: 21,
        letterSpacing: 0.25,
        color: '#fff',
        textAlign: 'center',
    },
    textView: {
        width: Breakpoints_1.isDesktop ? '30%' : '70%',
        alignItems: 'center',
        marginTop: 50,
        marginBottom: 20
    },
    backButton: {
        width: Breakpoints_1.isDesktop ? '30%' : '70%',
        alignItems: 'center',
        marginVertical: 20,
    },
});
