"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    scale: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexGrow: 0,
        marginBottom: 10,
        paddingStart: 2,
        paddingEnd: 3,
    },
    slider: {
        flex: 1,
        flexGrow: 0
    },
    error: {
        borderColor: '#ff0000',
        borderWidth: 1,
    },
});
