"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Folgerezept = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const react_native_1 = require("react-native");
const react_1 = __importStar(require("react"));
const atome_1 = require("../../atome");
const Breakpoints_1 = require("../../../helper/Breakpoints");
const Dashboard_web_style_1 = require("../../../features/dashboard/web/Dashboard.web.style");
const GlobalStyles_1 = require("../../../helper/GlobalStyles");
const organisms_1 = require("../../organisms");
const date_fns_1 = require("date-fns");
function Folgerezept({ userInformation, setShowFolgerezept, navigateTo, }) {
    const [isTherapyModalVisible, setTherapyModalVisible] = (0, react_1.useState)(false);
    const remainingPrescriptions = userInformation.availableFollowUpPrescription;
    const lastRequest = userInformation?.followUpPrescriptionRequest &&
        userInformation?.followUpPrescriptionRequest[userInformation.followUpPrescriptionRequest.length - 1];
    const lastRequestDate = lastRequest
        ? new Date(lastRequest.updateDate)
        : undefined;
    const lastAppointment = userInformation?.appointment[userInformation.appointment.length - 1];
    const lastAppointmentDate = lastAppointment
        ? new Date(lastAppointment.startDate)
        : undefined;
    // find if the last request or last appointment is most recent
    const tempDate = lastRequestDate && lastAppointmentDate
        ? lastRequestDate >= lastAppointmentDate
            ? new Date(lastRequestDate)
            : new Date(lastAppointmentDate)
        : lastRequestDate
            ? new Date(lastRequest.updateDate)
            : new Date(lastAppointment.startDate);
    // the patients can ask for prescription if 3
    // days have passed since either the lastAppointment or
    // lastRequest (most recent out of 2)
    const finalDate = tempDate ? (0, date_fns_1.startOfDay)((0, date_fns_1.addDays)(tempDate, 3)) : undefined;
    const today = (0, date_fns_1.startOfToday)();
    const dateAcceptedByDoctor = lastRequest?.updateDate
        ? new Date(lastRequest.updateDate)
        : undefined;
    const hours48 = dateAcceptedByDoctor && (0, date_fns_1.startOfDay)((0, date_fns_1.addDays)(dateAcceptedByDoctor, 2));
    const isLastRequestAcceptedAndWithin48Hours = (lastRequest && lastRequest?.status === 2 && hours48 && today <= hours48) ||
        false;
    const isLastRequestStatusUndefined = lastRequest && lastRequest?.status === undefined;
    (0, react_1.useEffect)(() => {
        if (remainingPrescriptions >= 1 ||
            isLastRequestStatusUndefined ||
            isLastRequestAcceptedAndWithin48Hours) {
            setShowFolgerezept(true);
        }
        else {
            setShowFolgerezept(false);
        }
    }, [userInformation.availableFollowUpPrescription]);
    const onlineAppointmentCheckAndRedirect = () => {
        const WEEK_SPAN = 8.64e7 * 7; // <= milliseconds in a day * 7 days  = milliseconds in a week
        let newestDateEpoch = new Date(0).getTime();
        userInformation?.lifeQuality.forEach((row) => {
            let { queryDate } = row;
            let queryDateEpoch = new Date(queryDate).getTime();
            if (queryDateEpoch > newestDateEpoch) {
                newestDateEpoch = queryDateEpoch;
            }
        });
        let now = Date.now();
        let weekBeforeNow = now - WEEK_SPAN;
        if (newestDateEpoch <= weekBeforeNow) {
            setTherapyModalVisible(true);
            return;
        }
        navigateTo('FollowPrescription');
    };
    const onTherapyModalOk = () => {
        setTherapyModalVisible(false);
        navigateTo('PatientenTracking', { redirectPage: 'FollowPrescription' });
    };
    const onTherapyModalCancel = () => setTherapyModalVisible(false);
    const PrescriptionIsSend = () => (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.heading3,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginBottom0
                    : GlobalStyles_1.globalStyles.marginBottom30,
            ] }, "Ihr Folgerezept ist unterwegs"),
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.text,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginTop15
                    : GlobalStyles_1.globalStyles.marginTop25,
            ] }, "Ihr Folgerezept wird ausgestellt und Ihrer Apotheke zugesandt.")));
    const RequestMade = () => (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.heading3,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginBottom0
                    : GlobalStyles_1.globalStyles.marginBottom30,
            ] }, "Ihr Folgerezept"),
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.text,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginTop15
                    : GlobalStyles_1.globalStyles.marginTop25,
            ] }, "Ihr Folgerezept ist angefordert und wird von Ihrem Arzt gepr\u00FCft.")));
    const RequestAccepted = () => (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(atome_1.Text, { style: [Dashboard_web_style_1.styles.heading3] }, "Ihr Folgerezept"),
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.text,
                Breakpoints_1.isMobileOrTablet ? GlobalStyles_1.globalStyles.marginTop0 : GlobalStyles_1.globalStyles.marginTop25,
            ] }, "Ihr Folgerezept ist in Bearbeitung. Nach Druck und Versand werden Sie informiert.")));
    const MakeRequest = () => (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.heading3,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginTop10
                    : GlobalStyles_1.globalStyles.marginBottom50,
            ] }, "Folgerezept anfordern"),
        react_1.default.createElement(react_native_1.TouchableOpacity, { style: [Dashboard_web_style_1.styles.button, GlobalStyles_1.globalStyles.marginTop15], onPress: () => {
                onlineAppointmentCheckAndRedirect();
                // navigateTo('FollowPrescription')
            } },
            react_1.default.createElement(atome_1.Text, { style: [Dashboard_web_style_1.styles.buttonText] }, "Rezept anfordern"))));
    const AfterThisDateYouCanMakeTheRequest = () => (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.heading3,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginTop10
                    : GlobalStyles_1.globalStyles.marginBottom50,
            ] }, "Folgerezept anfordern"),
        react_1.default.createElement(atome_1.Text, { style: [
                Dashboard_web_style_1.styles.text,
                Breakpoints_1.isMobileOrTablet
                    ? GlobalStyles_1.globalStyles.marginTop10
                    : GlobalStyles_1.globalStyles.marginTop25,
            ] },
            "Ab dem ",
            '',
            finalDate && (0, date_fns_1.format)(new Date(finalDate), 'dd.MM.yyyy'),
            " ",
            '',
            "k\u00F6nnen Sie ein Folgerezept anfordern.")));
    const TopComponentDividerOfDashBoardBubble = () => (react_1.default.createElement(react_native_1.View, { style: [Dashboard_web_style_1.styles.containerDivider] },
        react_1.default.createElement(react_native_1.View, { style: [Dashboard_web_style_1.styles.divider] }),
        react_1.default.createElement(atome_1.Text, { style: [Dashboard_web_style_1.styles.textDivider] }, "Oder"),
        react_1.default.createElement(react_native_1.View, { style: [Dashboard_web_style_1.styles.divider] })));
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(react_native_1.View, { style: [
                Breakpoints_1.isMobileOrTablet ? GlobalStyles_1.globalStyles.marginTop5 : GlobalStyles_1.globalStyles.marginTop20,
            ] }, (remainingPrescriptions >= 1 ||
            isLastRequestAcceptedAndWithin48Hours) && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(TopComponentDividerOfDashBoardBubble, null),
            react_1.default.createElement(react_native_1.View, { style: [Breakpoints_1.isMobileOrTablet ? {} : GlobalStyles_1.globalStyles.marginTop10] }, (finalDate && finalDate <= today) ||
                isLastRequestStatusUndefined ||
                isLastRequestAcceptedAndWithin48Hours ? (react_1.default.createElement(react_1.default.Fragment, null, isLastRequestStatusUndefined ? (react_1.default.createElement(RequestMade, null)) : (react_1.default.createElement(react_1.default.Fragment, null, isLastRequestAcceptedAndWithin48Hours ? (react_1.default.createElement(react_1.default.Fragment, null,
                !lastRequest?.prescriptionDone && (react_1.default.createElement(RequestAccepted, null)),
                lastRequest?.prescriptionDone && (react_1.default.createElement(PrescriptionIsSend, null)))) : (react_1.default.createElement(MakeRequest, null)))))) : (react_1.default.createElement(AfterThisDateYouCanMakeTheRequest, null)))))),
        react_1.default.createElement(organisms_1.TherapyMonitorModal, { visible: isTherapyModalVisible, onOk: onTherapyModalOk, onCancel: onTherapyModalCancel, purpose: "follow-up-prescription" })));
}
exports.Folgerezept = Folgerezept;
