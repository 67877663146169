"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectPharmacies = exports.setPharmacies = exports.pharmacySlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = [];
exports.pharmacySlice = (0, toolkit_1.createSlice)({
    name: 'pharmacy',
    initialState,
    reducers: {
        setPharmacies: (state, action) => {
            /* eslint-disable */
            return action.payload;
            /* eslint-enable */
        },
    },
});
exports.setPharmacies = exports.pharmacySlice.actions.setPharmacies;
const selectPharmacies = (state) => state.pharmacy;
exports.selectPharmacies = selectPharmacies;
