"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        paddingHorizontal: 35,
        marginVertical: 20,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    spaceAround: {
        justifyContent: 'space-around',
    },
    saveMessageContainer: {
        position: 'relative',
    },
    saveMessageText: {
        position: 'absolute',
        bottom: 2,
        color: '#3db384',
        alignSelf: 'center',
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'space-around',
        marginVertical: 10,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        fontWeight: 'bold',
        color: '#fff',
    },
    backButtonText: {
        fontWeight: 'bold',
        color: '#3db384',
    },
    otherDiseases: {
        maxHeight: 0,
        overflow: 'hidden',
        transition: 'max-height 0.3s ease-out',
    },
    otherDiseasesEnable: {
        maxHeight: 10000,
        transition: 'max-height 0.3s ease-in',
        marginBottom: 55,
    },
    errorText: {
        position: 'absolute',
        color: '#ff0000',
        bottom: -25,
    },
    errorMessage: {
        position: 'absolute',
        color: '#ff0000',
        alignSelf: 'center',
    },
    textBold: {
        fontWeight: 'bold',
    },
    backgroundWhite: {
        backgroundColor: '#fff',
    },
    scrollview: {
        height: '100%',
        backgroundColor: '#fff',
    },
});
