"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginHorizontal: 20,
        marginVertical: 20,
        width: '90%',
        marginTop: 50,
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    rowSpaceBetween: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    whiteBackground: {
        backgroundColor: '#fff',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    label: {
        marginBottom: 10,
    },
    input: {
        width: react_native_1.Platform.OS !== 'web' ? '100%' : undefined,
    },
    textView: {
        alignItems: 'center',
        marginTop: 50,
        marginBottom: 20,
    },
    heading3Green: {
        fontSize: 17,
        fontFamily: 'Montserrat-Bold',
        marginVertical: 15,
        color: '#3db384',
    },
    heading3Black: {
        fontSize: 17,
        fontFamily: 'Montserrat-Bold',
        marginVertical: 15,
    },
    heading4: {
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        color: '#000',
    },
    buttonSave: {
        alignSelf: 'center',
        textAlign: 'center',
        marginVertical: 60,
        fontFamily: 'Montserrat-Bold',
    },
    backButton: {
        alignSelf: 'center',
        marginVertical: 10,
    },
    redText: {
        color: 'red',
        marginTop: 5,
    },
});
