"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loading = void 0;
const react_1 = __importStar(require("react"));
const Loading_style_1 = require("./Loading.style");
const react_native_1 = require("react-native");
const duration = 750;
function Loading() {
    const [animationDirectionOne, setAnimationDirectionOne] = (0, react_1.useState)(true);
    const [animationDirectionTwo, setAnimationDirectionTwo] = (0, react_1.useState)(true);
    const [animationDirectionThree, setAnimationDirectionThree] = (0, react_1.useState)(true);
    const [isFirstStart, setIsFirstStart] = (0, react_1.useState)(true);
    const [isMounted, setIsMounted] = (0, react_1.useState)(true);
    const dotAnimation1 = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    const dotAnimation2 = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    const dotAnimation3 = (0, react_1.useRef)(new react_native_1.Animated.Value(0)).current;
    const animationOne = () => {
        if (isMounted) {
            if (animationDirectionOne) {
                react_native_1.Animated.timing(dotAnimation1, {
                    toValue: 1,
                    duration: duration,
                    useNativeDriver: false,
                }).start(() => {
                    setAnimationDirectionOne(false);
                });
            }
            if (!animationDirectionOne) {
                react_native_1.Animated.timing(dotAnimation1, {
                    toValue: 0,
                    duration: duration,
                    useNativeDriver: false,
                }).start(() => {
                    setAnimationDirectionOne(true);
                });
            }
        }
    };
    const animationTwo = () => {
        if (isMounted) {
            if (animationDirectionTwo) {
                react_native_1.Animated.timing(dotAnimation2, {
                    toValue: 1,
                    duration: duration,
                    useNativeDriver: false,
                }).start(() => {
                    setAnimationDirectionTwo(false);
                });
            }
            if (!animationDirectionTwo) {
                react_native_1.Animated.timing(dotAnimation2, {
                    toValue: 0,
                    duration: duration,
                    useNativeDriver: false,
                }).start(() => {
                    setAnimationDirectionTwo(true);
                });
            }
        }
    };
    const animationThree = () => {
        if (isMounted) {
            if (animationDirectionThree) {
                react_native_1.Animated.timing(dotAnimation3, {
                    toValue: 1,
                    duration: duration,
                    useNativeDriver: false,
                }).start(() => {
                    setAnimationDirectionThree(false);
                });
            }
            if (!animationDirectionThree) {
                react_native_1.Animated.timing(dotAnimation3, {
                    toValue: 0,
                    duration: duration,
                    useNativeDriver: false,
                }).start(() => {
                    setAnimationDirectionThree(true);
                });
            }
        }
    };
    (0, react_1.useEffect)(() => {
        if (!isFirstStart) {
            animationOne();
        }
    }, [dotAnimation1, animationDirectionOne]);
    (0, react_1.useEffect)(() => {
        if (!isFirstStart) {
            animationTwo();
        }
    }, [dotAnimation2, animationDirectionTwo]);
    (0, react_1.useEffect)(() => {
        if (!isFirstStart) {
            animationThree();
        }
    }, [dotAnimation3, animationDirectionThree]);
    (0, react_1.useEffect)(() => {
        animationOne();
        setTimeout(() => {
            animationTwo();
        }, duration / 2);
        setTimeout(() => {
            animationThree();
            setIsFirstStart(false);
        }, duration);
        return () => {
            setIsMounted(false);
        };
    }, []);
    return (react_1.default.createElement(react_native_1.SafeAreaView, { style: [Loading_style_1.styles.container] },
        react_1.default.createElement(react_native_1.View, { style: [Loading_style_1.styles.loadingContainer] },
            react_1.default.createElement(react_native_1.Animated.View, { style: [
                    Loading_style_1.styles.loadingDot,
                    {
                        opacity: dotAnimation1.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.4, 1],
                        }),
                        transform: [
                            {
                                scale: dotAnimation1.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.2],
                                }),
                            },
                        ],
                    },
                ] }),
            react_1.default.createElement(react_native_1.Animated.View, { style: [
                    Loading_style_1.styles.loadingDot,
                    {
                        opacity: dotAnimation2.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.4, 1],
                        }),
                        transform: [
                            {
                                scale: dotAnimation2.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.2],
                                }),
                            },
                        ],
                    },
                ] }),
            react_1.default.createElement(react_native_1.Animated.View, { style: [
                    Loading_style_1.styles.loadingDot,
                    {
                        opacity: dotAnimation3.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0.4, 1],
                        }),
                        transform: [
                            {
                                scale: dotAnimation3.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1, 1.2],
                                }),
                            },
                        ],
                    },
                ] }))));
}
exports.Loading = Loading;
