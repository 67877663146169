"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const GlobalStyles_1 = require("../../../../helper/GlobalStyles");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    scrollview: {
        overflowY: 'hidden',
        paddingStart: 25,
        paddingEnd: 25,
    },
    logo: {
        display: 'flex',
        marginTop: 30,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        marginHorizontal: 20,
        marginTop: 5,
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30,
        ...react_native_1.Platform.select({
            ios: {
                alignSelf: 'center',
            },
            android: {
                alignSelf: 'center',
            },
        }),
    },
    container: {
        flex: 1,
        marginHorizontal: 'auto',
        marginVertical: Breakpoints_1.isDesktop ? 20 : 5,
        width: Breakpoints_1.isDesktop ? 1080 : '100%',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    diseaseBoxContainer: {
        marginVertical: 30,
        marginBottom: 50,
        ...(Breakpoints_1.isDesktop && { flexDirection: 'row', justifyContent: 'space-between' }),
    },
    row: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        marginTop: 10,
    },
    marginRight0: {
        marginRight: 0,
    },
    alignSelfCenter: {
        alignSelf: 'center',
    },
    spaceBetween: {
        justifyContent: 'space-between',
    },
    buttonContainer: {
        justifyContent: 'flex-end',
        alignItems: Breakpoints_1.isMobileOrTablet ? 'center' : undefined,
        paddingBottom: 50,
    },
    buttonWebContainer: {
        justifyContent: 'flex-start',
        alignItems: Breakpoints_1.isMobileOrTablet ? 'center' : undefined,
        paddingBottom: 50,
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
    errorText: {
        position: 'absolute',
        color: '#ff0000',
    },
    errorTextPassword2: {
        position: 'absolute',
        color: '#ff0000',
    },
    errorTextCheckbox: {
        position: 'absolute',
        color: '#ff0000',
        bottom: -10,
    },
    errorTextBottom25: {
        bottom: 25,
    },
    emailField: {
        minWidth: 350,
    },
    phoneField: {
        minWidth: 250,
    },
    callContainer: {
        width: '100%',
        backgroundColor: '#f8f8f8',
        marginTop: 50,
        marginBottom: 20,
        paddingLeft: 20,
    },
    callLabel: {
        marginTop: 30,
        fontSize: 16,
    },
    passwordLabel: {
        fontSize: 18,
    },
    callCheckLabel: {
        marginTop: -15,
    },
    picker: {
        paddingVertical: 10,
        paddingHorizontal: 12,
        marginTop: 15,
    },
    input: {
        marginRight: 0,
    },
    passWebInput: {
        marginRight: 50,
        minWidth: 300,
    },
    label: {
        marginBottom: 10,
    },
    marginNegativeTop40: {
        marginTop: -40,
    },
    box: {
        display: 'flex',
        width: Breakpoints_1.isDesktop ? '49%' : '99%',
        height: 290,
    },
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 5,
            height: 5,
        },
        shadowOpacity: 0.08,
        shadowRadius: 40,
        elevation: 3,
    },
    textJustify: {
        textAlign: 'justify',
    },
    cardWrapper: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        marginTop: 10,
        alignItems: 'center',
        justifyContent: Breakpoints_1.isDesktop ? 'space-between' : 'center',
        marginBottom: Breakpoints_1.isDesktop ? 0 : 30,
    },
    cardCommonStyle: {
        paddingVertical: 20,
        paddingHorizontal: 20,
        marginBottom: 20,
    },
    backgroundGreen: {
        backgroundColor: GlobalStyles_1.COLORS.green,
    },
    backgroundWhite: {
        backgroundColor: '#fff',
    },
    backgroundTransparent: {
        backgroundColor: 'transparent',
    },
    backgroundGray: {
        backgroundColor: '#B8B8B8',
    },
    whiteBorder: {
        borderColor: '#fff',
    },
    greenBorder: {
        borderColor: GlobalStyles_1.COLORS.green,
    },
    commonBorderStyle: {
        borderRadius: 50,
        borderStyle: 'solid',
        borderWidth: 2,
        width: 30,
        height: 30,
        marginRight: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    greenText: {
        color: GlobalStyles_1.COLORS.green,
    },
    whiteText: {
        color: '#fff',
    },
    blackText: {
        color: '#000',
    },
    redText: {
        color: 'red',
    },
    commonCardHeaderStyle: {
        fontWeight: '900',
        fontSize: 16,
        fontFamily: 'Montserrat-Regular',
        paddingRight: 20,
    },
    descriptionBox: {
        alignItems: 'flex-end',
        marginTop: 20,
        marginLeft: Breakpoints_1.isDesktop ? 0 : 25,
    },
    descriptionInnerWidth: {
        width: Breakpoints_1.isDesktop ? '91.5%' : '94%',
    },
    optionCommonStyle: {
        fontSize: 13,
        width: '96%',
    },
    commonHrStyle: {
        width: '95%',
        height: 1,
        opacity: 0.3,
        marginVertical: 15,
    },
    flexRowCenter: {
        flexDirection: 'row',
    },
    textCenter: {
        textAlign: 'center',
    },
    nextButtonStyle: {
        width: 300,
        alignItems: 'center',
        marginTop: 40,
    },
    right5: {
        position: 'relative',
        right: 5,
    },
    marginNegativeTop50: {
        marginTop: -50,
    },
    buttonKostenfrei: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    buttonLoading: {
        backgroundColor: 'rgba(61, 179, 132, .5)',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
});
