"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectPrescriptionInfo = exports.setPrescriptionInfo = exports.followUpPrescriptionSlice = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
exports.initialState = {
    generalSideEffects: [],
    preparationQuestion: [],
    psychologicalSideEffects: [],
};
exports.followUpPrescriptionSlice = (0, toolkit_1.createSlice)({
    name: 'prescriptionInfo',
    initialState: exports.initialState,
    reducers: {
        setPrescriptionInfo: (state, action) => {
            /* eslint-disable */
            return action.payload;
            /* eslint-enable */
        },
    },
});
exports.setPrescriptionInfo = exports.followUpPrescriptionSlice.actions.setPrescriptionInfo;
const selectPrescriptionInfo = (state) => state.followUpPrescription;
exports.selectPrescriptionInfo = selectPrescriptionInfo;
