"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reactReducer = exports.initialState = void 0;
exports.initialState = {
    firstPresentationIndex: 1,
    secondaryDiagnosisIndex: 1,
    medicationIndex: 1,
    furtherQuestionsIndex: 1,
    onlineAppointmentIndex: 1,
    offlineFirstAppointmentIndex: 1,
    onlineFirstAppointmentIndex: 1,
    followUpPrescriptionsIndex: 1,
};
const reactReducer = (state, action) => {
    let { type, payload } = action;
    let keys = Object.keys(payload);
    let newState = JSON.parse(JSON.stringify(state));
    switch (type) {
        case '[UPDATE]':
            keys.forEach(key => (newState[key] = payload[key]));
            break;
        case '[RESET]':
            newState = resetState();
            break;
        default:
            break;
    }
    return newState;
};
exports.reactReducer = reactReducer;
const resetState = () => ({
    firstPresentationIndex: 1,
    secondaryDiagnosisIndex: 1,
    medicationIndex: 1,
    furtherQuestionsIndex: 1,
    onlineAppointmentIndex: 1,
    offlineFirstAppointmentIndex: 1,
    onlineFirstAppointmentIndex: 1,
    followUpPrescriptionsIndex: 1,
});
