"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    scrollview: {
        height: '100%',
        backgroundColor: 'white',
    },
    container: {
        flex: 1,
        alignItems: 'center',
        marginVertical: Breakpoints_1.isDesktop || Breakpoints_1.isPhablet ? '2rem' : 80,
        width: '100%',
    },
    header: {
        flexDirection: 'row',
        width: Breakpoints_1.isDesktop ? '60%' : '80%',
    },
    headerContent: {
        flex: 1,
    },
    headerTitle: {
        fontSize: Breakpoints_1.isDesktop ? 24 : 18,
        fontWeight: 'bold',
        marginBottom: Breakpoints_1.isDesktop ? '1rem' : 4,
    },
    headerLabel: {
        fontSize: 14,
    },
    bodyBackground: {
        backgroundColor: '#3DB384',
        width: '100%',
        alignItems: 'center',
        marginTop: Breakpoints_1.isDesktop ? '3em' : 24,
    },
    body: {
        flex: 1,
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        width: Breakpoints_1.isDesktop ? '60%' : '80%',
    },
    bodyContent: {
        flex: 1,
        marginTop: Breakpoints_1.isDesktop ? '2em' : 24,
    },
    bodyText: {
        color: '#ffffff',
        marginBottom: Breakpoints_1.isDesktop ? '1em' : 8,
    },
    bodyContentTitle: {
        fontSize: 16,
        fontWeight: '800',
    },
    bodyContentText: {
        fontSize: 14,
    },
    nachweisContainer: {
        flexDirection: Breakpoints_1.isDesktop ? 'row' : 'column',
        justifyContent: Breakpoints_1.isLargeScreen ? undefined : 'space-between',
        marginTop: Breakpoints_1.isDesktop ? '1em' : 12,
        marginBottom: Breakpoints_1.isDesktop ? '3em' : 24,
    },
    flexRow: {
        flexDirection: 'row',
    },
    nachweisTitle: {
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        fontWeight: '800',
        marginLeft: Breakpoints_1.isDesktop ? '0.6em' : 8,
        marginBottom: Breakpoints_1.isDesktop ? '0.6em' : 0,
        marginTop: Breakpoints_1.isDesktop ? '0.2rem' : 4,
    },
    nachweisText: {
        marginLeft: Breakpoints_1.isDesktop ? '2.4em' : 36,
        fontSize: 14,
    },
    footer: {
        alignItems: 'center',
        margin: Breakpoints_1.isDesktop ? '3em' : 36,
        width: Breakpoints_1.isDesktop ? '40%' : '80%',
    },
    footerText: {
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
        textAlign: 'center',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 24,
        paddingVertical: Breakpoints_1.isDesktop ? 12 : 8,
        marginTop: Breakpoints_1.isDesktop ? '2em' : 24,
    },
    buttonText: {
        color: '#fff',
        fontSize: Breakpoints_1.isDesktop ? 16 : 14,
    },
    marginTop4px: {
        marginTop: 4,
    },
    marginBottom2: {
        marginBottom: Breakpoints_1.isDesktop ? '2em' : 24,
    },
    textUnderline: {
        textDecorationLine: 'underline',
        fontSize: 14,
        fontWeight: '700',
        marginLeft: Breakpoints_1.isDesktop ? '1rem' : 12,
        marginBottom: Breakpoints_1.isDesktop ? '1rem' : 12,
    },
    textBold: {
        fontWeight: 'bold',
    },
    textBoldWeight: {
        fontWeight: 'bold',
        color: '#fff',
    },
    marginLeft3: {
        marginLeft: '3rem',
    },
    sendMailModal: {
        marginVertical: 2,
        marginHorizontal: 2,
    },
    sendMailModalWeb: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '80%',
        margin: 'auto',
    },
    sendMailModalTitle: {
        fontSize: Breakpoints_1.isDesktop ? 24 : 20,
        fontWeight: 'bold',
        marginBottom: Breakpoints_1.isDesktop ? 24 : 16,
        color: '#000',
    },
    sendMailModalText: {
        fontSize: Breakpoints_1.isDesktop ? 16 : 12,
        marginBottom: Breakpoints_1.isDesktop ? 10 : 16,
    },
    sendMailButton: {
        borderRadius: 24,
        borderWidth: 1,
    },
    sendMailButtonSuccess: {
        backgroundColor: '#3db384',
        borderColor: '#3db384',
        paddingHorizontal: Breakpoints_1.isDesktop ? 35 : 24,
        paddingVertical: Breakpoints_1.isDesktop ? 10 : 6,
        marginRight: 16,
    },
    sendMailButtonPrimaryText: {
        color: '#fff',
        fontSize: Breakpoints_1.isDesktop ? undefined : 12,
        fontWeight: '700',
    },
    sendMailButtons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: Breakpoints_1.isDesktop ? 24 : 16,
    },
});
