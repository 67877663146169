"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginTop: 80,
        alignItems: 'center',
        marginVertical: 55,
    },
    containerMobile: {
        flex: 1,
        height: '100%',
        flexDirection: 'column',
        marginHorizontal: 10,
        width: '95%',
        marginTop: 50,
        alignItems: 'center',
    },
    alignCenter: {
        alignItems: 'center',
    },
    scrollview: {
        backgroundColor: '#fff',
        height: '100%',
        width: '100%',
    },
    heading3Green: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 28 : 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#3db384',
    },
    heading3Black: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 25 : 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    heading4: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 20 : 18,
        color: '#000',
        fontFamily: 'Montserrat-Regular',
    },
    textBold: {
        fontWeight: 'bold',
    },
    buttonContainer: {
        alignItems: 'center',
        justifyContent: 'space-around',
        marginVertical: 30,
        marginTop: Breakpoints_1.isDesktop ? '5%' : '15%',
        marginBottom: Breakpoints_1.isDesktop ? '2%' : '5%',
        flexDirection: 'column',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        fontSize: Breakpoints_1.isDesktop ? 20 : 15,
        fontWeight: 'bold',
        color: '#fff',
    },
    morePharmaciesText: {
        color: '#3db384',
        textDecorationStyle: 'solid',
        textDecorationLine: 'underline',
        marginTop: '1%',
        fontFamily: 'Montserrat-Regular',
    },
});
