"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserHeader = void 0;
/* eslint-disable react-native/no-inline-styles */
const react_1 = __importStar(require("react"));
const atome_1 = require("../../atome");
const react_native_1 = require("react-native");
const UserHeader_style_1 = require("./UserHeader.style");
const hooks_1 = require("../../../store/hooks");
const reducers_1 = require("../../../store/reducers");
const UtilityFunctions_1 = require("../../../helper/UtilityFunctions");
const index_1 = require("../index");
const Breakpoints_1 = require("../../../helper/Breakpoints");
const initialState_1 = require("../../../store/reducers/initialState");
function UserHeader({ navigation }) {
    const auth = (0, hooks_1.useAppSelector)(reducers_1.selectAuth);
    const dispatch = (0, hooks_1.useAppDispatch)();
    const [showUserMenu, setShowUserMenu] = (0, react_1.useState)(false);
    const [isMyAccountHovered, setIsMyAccountHovered] = (0, react_1.useState)(false);
    const [isLogoutHovered, setIsLogoutHovered] = (0, react_1.useState)(false);
    return (react_1.default.createElement(react_1.default.Fragment, null, react_native_1.Platform.OS === 'web' && (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.View, { style: UserHeader_style_1.styles.header },
            react_1.default.createElement(react_native_1.View, { style: UserHeader_style_1.styles.logo },
                react_1.default.createElement(index_1.UserNavigation, { navigation: navigation }),
                react_1.default.createElement(react_native_1.View, { style: {
                        marginTop: -10,
                        marginLeft: Breakpoints_1.isMobile ? -20 : -5,
                    } },
                    react_1.default.createElement(react_native_1.Pressable, { onPress: () => navigation.navigate('Dashboard') },
                        react_1.default.createElement(atome_1.Icon, { icon: "nowomed", size: Breakpoints_1.isMobile ? 120 : 200 })))),
            react_1.default.createElement(react_native_1.View, { style: [UserHeader_style_1.styles.row, UserHeader_style_1.styles.user] },
                !Breakpoints_1.isMobile && react_1.default.createElement(atome_1.Icon, { icon: "user" }),
                react_1.default.createElement(atome_1.Text, { style: [UserHeader_style_1.styles.userText] }, auth.name),
                react_1.default.createElement(react_native_1.Pressable, { style: [
                        UserHeader_style_1.styles.chevron,
                        showUserMenu ? UserHeader_style_1.styles.chevronRotate : null,
                    ], onPress: () => setShowUserMenu(!showUserMenu) },
                    react_1.default.createElement(atome_1.Icon, { icon: "chevron" })),
                react_1.default.createElement(react_native_1.View, { style: [
                        UserHeader_style_1.styles.userMenu,
                        showUserMenu ? UserHeader_style_1.styles.userMenuDisplay : null,
                    ] },
                    react_1.default.createElement(react_native_1.Pressable, { style: {
                            backgroundColor: isMyAccountHovered ? 'gainsboro' : 'white',
                        }, 
                        // @ts-ignore
                        onHoverIn: () => {
                            setIsMyAccountHovered(true);
                        }, onHoverOut: () => {
                            setIsMyAccountHovered(false);
                        } },
                        react_1.default.createElement(atome_1.Text, { style: [UserHeader_style_1.styles.padding10], onPress: () => {
                                setShowUserMenu(false);
                                navigation.navigate('MyAccount');
                            } }, "Mein Profil")),
                    react_1.default.createElement(react_native_1.Pressable, { style: {
                            backgroundColor: isLogoutHovered ? 'gainsboro' : 'white',
                        }, 
                        // @ts-ignore
                        onHoverIn: () => {
                            setIsLogoutHovered(true);
                        }, onHoverOut: () => {
                            setIsLogoutHovered(false);
                        } },
                        react_1.default.createElement(atome_1.Text, { style: [UserHeader_style_1.styles.padding10], onPress: () => {
                                setShowUserMenu(false);
                                const reduxState = {
                                    isAuthenticated: false,
                                    isTermsAgree: false,
                                    name: '',
                                    isPrivacyPolicyAgree: false,
                                };
                                dispatch((0, reducers_1.setAuth)(reduxState));
                                dispatch((0, reducers_1.setUserInformation)(initialState_1.initialUserInformation));
                                (0, UtilityFunctions_1.signOff)({
                                    auth: auth,
                                    nav: () => navigation.navigate('Login'),
                                });
                            } }, "Abmelden")))))))));
}
exports.UserHeader = UserHeader;
