"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    heading4Modal: {
        textAlign: 'center',
        fontSize: 14,
        color: '#000',
    },
    heading3Modal: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 16 : 15,
        fontWeight: 'bold',
        color: '#000',
        marginVertical: 5,
    },
    modalWeb: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        width: 400,
        height: 'auto'
    },
    modalTitle: {
        fontSize: Breakpoints_1.isDesktop ? 20 : 18,
        fontWeight: 'bold',
        marginBottom: Breakpoints_1.isDesktop ? 10 : 5,
        color: '#3db384',
    },
    modalText: {
        textAlign: 'center',
        fontSize: Breakpoints_1.isDesktop ? 16 : 12,
        marginVertical: 20
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: Breakpoints_1.isDesktop ? 24 : 16,
    },
    buttonPrimary: {
        borderRadius: 15,
        borderWidth: 1,
        backgroundColor: '#3db384',
        borderColor: '#3db384',
        paddingHorizontal: Breakpoints_1.isDesktop ? 15 : 24,
        paddingVertical: Breakpoints_1.isDesktop ? 5 : 6,
        marginRight: 16,
    },
    buttonPrimaryText: {
        color: '#fff',
        fontSize: Breakpoints_1.isDesktop ? 13 : 12,
        fontWeight: '700',
    },
    cancelButton: {
        borderRadius: 15,
        borderWidth: 1,
        backgroundColor: '#fff',
        borderColor: '#3db384',
        paddingHorizontal: Breakpoints_1.isDesktop ? 15 : 24,
        paddingVertical: Breakpoints_1.isDesktop ? 5 : 6,
        marginRight: 16,
    },
    cancelButtonText: {
        color: '#3db384',
        fontSize: Breakpoints_1.isDesktop ? 13 : 12,
        fontWeight: '700',
    },
});
