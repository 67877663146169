"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Kontakt = void 0;
const React = __importStar(require("react"));
const react_native_svg_1 = __importStar(require("react-native-svg"));
function Kontakt({ size = 25, color }) {
    return (React.createElement(react_native_svg_1.default, { width: size, height: size, viewBox: "0 0 26 25", fill: color },
        React.createElement(react_native_svg_1.Defs, null,
            React.createElement(react_native_svg_1.Path, { id: "a", d: "M0 0.506802869L25.9055691 0.506802869 25.9055691 23.7674514 0 23.7674514z" }),
            React.createElement(react_native_svg_1.Path, { id: "c", d: "M0.1857 0.037L11.9937 0.037 11.9937 10 0.1857 10z" })),
        React.createElement(react_native_svg_1.G, { stroke: "none", strokeWidth: 1, fillRule: "evenodd" },
            React.createElement(react_native_svg_1.Mask, { id: "b", fill: "#fff" },
                React.createElement(react_native_svg_1.Use, { xlinkHref: "#a" })),
            React.createElement(react_native_svg_1.Path, { d: "M9.79 19.432c-.302-.075-.603.037-.828.225-1.77 1.773-3.878 2.262-5.347 2.376.941-1.168 1.392-2.413 1.732-3.242.037-.15.112-.265.15-.377.152-.376.037-.791-.3-1.056C2.974 15.662 1.77 13.4 1.77 11.025c0-4.826 5.008-8.747 11.183-8.747S24.136 6.2 24.136 11.025c0 1.357-.415 2.677-1.13 3.845.528.301 1.017.641 1.43 1.019.942-1.471 1.47-3.13 1.47-4.864C25.943 5.22 20.106.507 12.953.507 5.8.507 0 5.22 0 11.025c0 2.715 1.32 5.354 3.616 7.276C3.2 19.394 2.56 20.978.979 22.033a.863.863 0 00-.376.904.89.89 0 00.677.679c.076 0 .716.151 1.695.151 1.732 0 4.518-.415 6.89-2.564.677.15 1.394.227 2.071.265-.227-.565-.377-1.169-.377-1.81-.602 0-1.204-.113-1.77-.226", mask: "url(#b)" }),
            React.createElement(react_native_svg_1.Path, { d: "M7.5 9a1.5 1.5 0 110 3 1.5 1.5 0 010-3M13.5 9a1.5 1.5 0 110 3 1.5 1.5 0 010-3M19.5 9a1.5 1.5 0 110 3 1.5 1.5 0 010-3" }),
            React.createElement(react_native_svg_1.G, { transform: "translate(13 15)" },
                React.createElement(react_native_svg_1.Mask, { id: "d", fill: "#fff" },
                    React.createElement(react_native_svg_1.Use, { xlinkHref: "#c" })),
                React.createElement(react_native_svg_1.Path, { d: "M10.156 7.746c1.134-.819 1.838-1.975 1.838-3.24 0-1.118-.548-2.123-1.407-2.905A6.377 6.377 0 009.414.818C8.474.336 7.302.037 6.09.037 2.844.037.186 2.048.186 4.506c0 .484.117.968.312 1.378.783 1.787 2.971 3.09 5.592 3.09.586 0 1.134-.074 1.642-.186 1.682 1.602 3.95 1.155 3.95 1.155-1.017-.67-1.33-1.713-1.526-2.197", mask: "url(#d)" })))));
}
exports.Kontakt = Kontakt;
