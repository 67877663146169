"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    primaryButton: {
        backgroundColor: '#3db384',
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 20,
    },
    primaryText: {
        color: '#fff',
        fontWeight: '700',
        fontSize: 15,
    },
    linkButton: {
    // empty for future expanding
    },
    linkText: {
        color: '#3db384',
        textDecorationLine: 'underline',
        textDecorationColor: '#3db384',
        fontWeight: '700',
        fontSize: 15,
    },
    disabledButton: {
        backgroundColor: '#c1c1c1',
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 20,
    },
    buttonContentWrapper: {
        flexDirection: 'row',
    },
});
