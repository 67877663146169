"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    calendar: {
        width: '100%',
    },
    row: {
        flexDirection: 'row',
    },
    alignCenter: {
        alignItems: 'center',
    },
    dayContainer: {
        width: `${100 / 7}%`,
        alignItems: 'center',
    },
    day: {
        textAlign: 'center',
        // userSelect: 'none', Error on Mobile
        paddingVertical: 3,
        paddingHorizontal: 8,
        borderRadius: 5,
        color: react_native_1.Platform.OS === 'web' ? '#ccc' : '#000',
    },
    passedDay: {
        color: '#ccc',
    },
    slotDay: {
        borderColor: '#3db384',
        borderWidth: 1.75,
        borderStyle: 'solid',
    },
    selectedDay: {
        backgroundColor: '#3db384',
        color: '#fff',
    },
    daysRow: {
        flexDirection: 'row',
        marginTop: 20,
    },
    monthText: {
        width: '70%',
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#000',
        // userSelect: 'none', Error on Mobile
    },
    changeMonthButton: {
        width: '15%',
        paddingVertical: react_native_1.Platform.OS === 'web' ? 'none' : 20,
        alignItems: 'center',
        justifyContent: 'center',
        height: 7.5,
    },
    changeMonthButtonDecrease: {
        transform: [{ rotate: '90deg' }],
    },
    changeMonthButtonIncrease: {
        transform: [{ rotate: '-90deg' }],
    },
    absenceTitle: {
        color: 'orange',
        fontWeight: 'bold',
        marginVertical: 10,
        fontSize: 12,
        marginLeft: 20,
    },
    absenceDescription: {
        color: 'orange',
        fontSize: 12,
        marginBottom: 50,
    },
});
