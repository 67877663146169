"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    linkWithoutIcon: {
        fontSize: 14,
        marginVertical: 10,
        marginHorizontal: 35,
        fontFamily: 'Montserrat-Regular',
        color: 'black',
        minWidth: 250,
    },
    labelGreen: {
        fontFamily: 'Montserrat-Regular',
        color: '#3db384',
        fontSize: 14,
        marginVertical: 10,
    },
    label: {
        color: 'black',
        fontFamily: 'Montserrat-Regular',
        fontSize: 14,
        marginVertical: 10,
    },
    versionText: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 13,
        color: '#c2c2c2',
    },
    divider: {
        marginVertical: 5,
    },
});
