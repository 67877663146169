"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    modalContent: {
        position: "relative",
        top: 10
    },
    modalPrompt: {
        fontSize: 15
    },
    modalTitle: {
        fontWeight: "900",
        textAlign: "center",
        fontSize: 17,
        marginBottom: 15
    }
});
