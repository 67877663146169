"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Calendly = void 0;
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const Calendly_style_1 = require("./Calendly.style");
const GlobalStyles_1 = require("../../../helper/GlobalStyles");
const atome_1 = require("../../atome");
const organisms_1 = require("../../organisms");
const hooks_1 = require("../../../store/hooks");
const reducers_1 = require("../../../store/reducers");
const react_calendly_1 = require("react-calendly");
const native_1 = require("@react-navigation/native");
// @ts-ignore
function Calendly({ navigation }) {
    const [booked, setBooked] = (0, react_1.useState)(false);
    const auth = (0, hooks_1.useAppSelector)(reducers_1.selectAuth);
    const userInformation = (0, hooks_1.useAppSelector)(reducers_1.selectUserInformation);
    const [url, setUrl] = (0, react_1.useState)('');
    (0, native_1.useFocusEffect)((0, react_1.useCallback)(() => {
        setBooked(false);
        setUrl(`https://calendly.com/nowomed/support-callback?name=${userInformation.firstname + ' ' + userInformation.lastname}&email=${userInformation.email}&a1=${userInformation.mobile
            ? userInformation.mobile
            : userInformation.landline}&primary_color=3db384&hide_event_type_details=1&hide_gdpr_banner=1`);
        return () => {
            setUrl('');
        };
    }, [url]));
    (0, react_calendly_1.useCalendlyEventListener)({
        onEventScheduled: () => setBooked(true),
    });
    return (react_1.default.createElement(react_native_1.SafeAreaView, { style: { backgroundColor: 'white' } },
        react_1.default.createElement(react_native_1.ScrollView, { style: [Calendly_style_1.styles.whiteBackground] },
            auth.isAuthenticated && react_1.default.createElement(organisms_1.UserHeader, { navigation: navigation }),
            react_1.default.createElement(react_native_1.View, { style: Calendly_style_1.styles.calendlyContainer },
                react_1.default.createElement(react_calendly_1.InlineWidget, { url: url, prefill: {
                        email: `${userInformation.email}`,
                        name: `${userInformation.firstname} ${userInformation.lastname}`,
                        customAnswers: {
                            a1: `${userInformation.mobile
                                ? userInformation.mobile
                                : userInformation.landline}`,
                        },
                    }, styles: { height: 800 } }),
                booked ? (react_1.default.createElement(react_native_1.View, { style: [Calendly_style_1.styles.centerButton] },
                    react_1.default.createElement(react_native_1.Pressable, { style: Calendly_style_1.styles.greenButton, onPress: () => navigation.navigate('Dashboard') },
                        react_1.default.createElement(atome_1.Text, { style: [Calendly_style_1.styles.greenButtonText] }, "Zum Dashboard")))) : (react_1.default.createElement(react_native_1.View, { style: [Calendly_style_1.styles.centerButton] },
                    react_1.default.createElement(react_native_1.Pressable, { style: GlobalStyles_1.globalStyles.marginTop20, onPress: () => navigation.navigate('Contact') },
                        react_1.default.createElement(atome_1.Text, { style: [Calendly_style_1.styles.backButtonText] }, "\u2190 Zur\u00FCck"))))))));
}
exports.Calendly = Calendly;
