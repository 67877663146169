"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
exports.styles = react_native_1.StyleSheet.create({
    scrollview: {
        height: '100%',
        backgroundColor: 'white',
    },
    greenDotsContainer: {
        position: 'absolute',
        marginVertical: '70%',
        marginHorizontal: '40%',
    },
    container: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: Breakpoints_1.isDesktop ? 40 : 80,
        width: Breakpoints_1.isDesktop ? '50%' : '100%',
    },
    header: {
        width: Breakpoints_1.isDesktop ? '32%' : '72%',
        marginTop: Breakpoints_1.isDesktop ? 32 : 16,
    },
    headerTitle: {
        textAlign: 'center',
    },
    locationsContainer: {
        marginTop: 48,
        width: Breakpoints_1.isDesktop ? '60%' : '90%',
    },
    locationBox: {
        height: 54,
        width: '100%',
        borderColor: '#00000030',
        borderWidth: 1,
        overflow: 'hidden',
        shadowColor: '#00000030',
        shadowRadius: 10,
        shadowOpacity: 1,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: Breakpoints_1.isDesktop ? 16 : 8,
        paddingVertical: Breakpoints_1.isDesktop ? 16 : undefined,
        marginBottom: 8,
    },
    locationDetails: {
        borderColor: '#00000030',
        borderWidth: 1,
        overflow: 'hidden',
        shadowColor: '#00000030',
        shadowRadius: 10,
        shadowOpacity: 1,
        backgroundColor: '#3DB384',
        padding: 16,
        marginBottom: 8,
    },
    greenText: {
        color: '#3DB384',
        fontSize: 14,
        fontWeight: '700',
    },
    whiteText: {
        color: '#fff',
        fontSize: 12,
        marginBottom: 6,
        marginLeft: 6,
        marginTop: 4,
    },
    detailsTitle: {
        color: '#fff',
        fontSize: 14,
        fontWeight: '700',
        marginBottom: 12,
    },
    buttonContainer: {
        alignItems: 'flex-start',
        justifyContent: 'space-around',
    },
    buttonWeiter: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonTextWeiter: {
        fontWeight: 'bold',
        color: '#fff',
    },
    button: {
        backgroundColor: '#fff',
        borderRadius: 24,
        paddingHorizontal: 24,
        paddingVertical: 8,
        marginVertical: 10,
    },
    buttonText: {
        color: '#3DB384',
        fontSize: 12,
        lineHeight: 21,
        letterSpacing: 0.25,
    },
    flexRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    marginTop4px: {
        marginTop: 4,
    },
    whiteBackground: {
        height: '100%',
        backgroundColor: '#fff',
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    lineHeight20: {
        lineHeight: 20,
    },
    calendarIconContainer: {
        height: 90,
    },
    noAppointmentsText: {
        position: 'absolute',
        bottom: 110,
        left: '25%',
    },
    calendarContainer: {
        padding: 16,
    },
    disabledButton: {
        opacity: 0.75,
    },
    confirmationBox: {
        width: '100%',
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: 24,
        paddingVertical: 54,
    },
    shadowContainer: {
        borderColor: '#00000010',
        borderWidth: 1,
        overflow: 'hidden',
        shadowColor: '#00000010',
        shadowRadius: 10,
        shadowOpacity: 1,
    },
    blackText: {
        color: '#000000',
        fontSize: 14,
        textAlign: 'center',
    },
    boldText: {
        fontWeight: '700',
    },
    greenButton: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 24,
        paddingVertical: 8,
        marginTop: 24,
    },
    greenButtonText: {
        color: '#fff',
        fontSize: 14,
    },
    selectedDateDay: {
        left: 16,
        top: 18,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#3db384',
        fontSize: 24,
        fontWeight: 'bold',
    },
    positionRelative: {
        position: 'relative',
    },
    successButton: {
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: 24,
        marginTop: 24,
        marginBottom: 45,
    },
    timeContainer: {
        marginTop: 48,
        width: Breakpoints_1.isDesktop ? '72%' : '90%',
    },
    timeDiv: {
        height: 100,
        paddingVertical: 15,
        paddingHorizontal: 20,
        width: '100%',
        shadowColor: '#00000010',
        shadowOpacity: 1,
        borderWidth: 1,
        borderColor: '#00000010',
        borderRadius: 3,
    },
    timeDivClicked: {
        height: 170,
        marginVertical: 20,
    },
    timeDivText: {
        color: 'black',
        fontWeight: 'bold',
    },
    timeDivTextClicked: {
        color: 'white',
        fontWeight: 'bold',
    },
    greenLinkText: {
        color: '#3db384',
        textDecorationLine: 'underline',
    },
    backButtonText: {
        fontWeight: 'normal',
        color: '#3db384',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textNoAppointment: {
        textAlign: 'center',
        marginVertical: 10,
    },
    helpButton: {
        backgroundColor: '#3db384',
        alignItems: 'center',
        paddingVertical: 8,
        paddingHorizontal: 30,
        textTransform: 'none',
        marginVertical: 10,
        borderRadius: 25,
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        width: react_native_1.Platform.OS === 'web' ? 'fit-content' : undefined,
        overflow: 'hidden',
    },
    helpButtonText: {
        color: '#fff',
    },
});
