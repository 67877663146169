"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    row: {
        flexDirection: 'row',
        marginBottom: 15,
    },
    text: {
        alignSelf: 'center',
        marginLeft: 10,
    },
});
