"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    input: {
        backgroundColor: '#EFEFEE',
        color: '#000',
        borderRadius: 12,
        padding: 12,
        marginTop: 6,
        marginRight: 25,
        flex: 1,
        fontFamily: 'Montserrat-Regular',
    },
    error: {
        borderColor: '#ff0000',
        borderWidth: 1,
    },
});
