"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterSuccess = void 0;
/* eslint-disable react-hooks/exhaustive-deps */
const react_native_1 = require("react-native");
const RegisterSuccess_style_1 = require("./RegisterSuccess.style");
const react_1 = __importStar(require("react"));
const hooks_1 = require("../../../../store/hooks");
const reducers_1 = require("../../../../store/reducers");
const organisms_1 = require("../../../organisms");
const Breakpoints_1 = require("../../../../helper/Breakpoints");
const atome_1 = require("../../../atome");
const GlobalStyles_1 = require("../../../../helper/GlobalStyles");
// @ts-ignore
function RegisterSuccess({ navigation, route }) {
    const auth = (0, hooks_1.useAppSelector)(reducers_1.selectAuth);
    (0, react_1.useEffect)(() => {
        if (auth.isAuthenticated) {
            navigation.navigate('Dashboard');
        }
        return () => { };
    }, [auth.isAuthenticated]);
    (0, react_1.useEffect)(() => {
        if (react_native_1.Platform.OS === 'web') {
            window.dataLayer.push({
                event: 'GTMevent',
                eventProps: {
                    category: 'Conversion',
                    action: 'RegStep1',
                },
            });
        }
    });
    const toLogin = () => navigation.navigate('Login');
    return (react_1.default.createElement(react_native_1.SafeAreaView, { style: { backgroundColor: 'white' } },
        react_1.default.createElement(react_native_1.ScrollView, { style: RegisterSuccess_style_1.styles.scrollView },
            react_1.default.createElement(organisms_1.BaseHeader, { navigation: navigation }),
            react_1.default.createElement(react_native_1.View, { style: RegisterSuccess_style_1.styles.container },
                react_1.default.createElement(atome_1.Text, { style: [RegisterSuccess_style_1.styles.heading3, { marginTop: 60 }] }, "Vielen Dank f\u00FCr Ihre Registrierung"),
                react_1.default.createElement(atome_1.Label, { style: [
                        RegisterSuccess_style_1.styles.introText,
                        GlobalStyles_1.globalStyles.textBold,
                        Breakpoints_1.isDesktop ? [{ width: '70%' }] : [],
                    ] }, "\u00DCberpr\u00FCfen Sie Ihren Posteingang, um sich einzuloggen."),
                react_1.default.createElement(atome_1.Label, { style: [RegisterSuccess_style_1.styles.introText, Breakpoints_1.isDesktop ? [{ width: '70%' }] : []] },
                    "Um die Einrichtung abzuschlie\u00DFen und sich einzuloggen, klicken Sie bitte auf den Verifizierungslink in der E-Mail, die wir an folgende Adresse gesendet haben: ",
                    route.params.email),
                react_1.default.createElement(react_native_1.View, { style: [RegisterSuccess_style_1.styles.row, RegisterSuccess_style_1.styles.flexRowAlignLeft, RegisterSuccess_style_1.styles.marginRow] },
                    react_1.default.createElement(react_native_1.Pressable, { style: RegisterSuccess_style_1.styles.button, onPress: toLogin },
                        react_1.default.createElement(atome_1.Text, { style: [RegisterSuccess_style_1.styles.buttonText] }, "Zum Login")))))));
}
exports.RegisterSuccess = RegisterSuccess;
