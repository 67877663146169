"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    separator: {
        borderColor: '#E0E0E0',
        borderTopWidth: 1,
        paddingTop: 20,
        width: 'auto',
    },
    textAreaView: {
        paddingBottom: 20,
    },
    checked: {
        height: 20,
        width: 20,
        borderWidth: 2,
        borderRadius: 4,
        borderColor: '#3db384',
        backgroundColor: '#f8f8f8',
    },
    unchecked: {
        height: 20,
        width: 20,
        borderWidth: 2,
        borderRadius: 4,
        borderColor: '#3db384',
        backgroundColor: '#f8f8f8',
    },
});
