"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
const Breakpoints_1 = require("../../../helper/Breakpoints");
const boxSize = 250;
exports.styles = react_native_1.StyleSheet.create({
    documentBox: {
        width: react_native_1.Platform.OS === 'web' ? boxSize : '100%',
        height: react_native_1.Platform.OS === 'web' ? boxSize : 100,
        backgroundColor: react_native_1.Platform.OS === 'web' ? '#EFEFEE' : 'white',
        justifyContent: react_native_1.Platform.OS === 'web' ? 'center' : 'space-between',
        alignItems: react_native_1.Platform.OS === 'web' ? 'center' : undefined,
        position: react_native_1.Platform.OS === 'web' ? 'relative' : undefined,
        marginRight: react_native_1.Platform.OS === 'web' ? 20 : 0,
        marginTop: 20,
        flexDirection: 'row',
    },
    shadow: {
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.08,
        shadowRadius: 20.22,
        elevation: Breakpoints_1.isMobile ? 8 : 3,
    },
    marginVertical5: {
        marginVertical: 5,
    },
    documentName: {
        marginTop: react_native_1.Platform.OS === 'web' ? 15 : 10,
        marginLeft: 0,
        width: react_native_1.Platform.OS === 'web' ? boxSize - 20 : 120,
        overflow: 'hidden',
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        fontWeight: react_native_1.Platform.OS === 'web' ? undefined : 'bold',
        fontSize: react_native_1.Platform.OS === 'web' ? undefined : 12,
        color: react_native_1.Platform.OS === 'web' ? undefined : 'black',
    },
    documentCenter: {
        justifyContent: react_native_1.Platform.OS === 'web' ? undefined : 'center',
    },
    documentCreated: {
        marginTop: react_native_1.Platform.OS === 'web' ? 15 : 5,
        marginLeft: 0,
        width: react_native_1.Platform.OS === 'web' ? boxSize - 20 : 120,
        overflow: 'hidden',
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
        fontSize: react_native_1.Platform.OS === 'web' ? undefined : 12,
        color: react_native_1.Platform.OS === 'web' ? undefined : 'black',
    },
    image: {
        position: react_native_1.Platform.OS === 'web' ? 'absolute' : undefined,
        top: react_native_1.Platform.OS === 'web' ? 20 : undefined,
        marginLeft: react_native_1.Platform.OS === 'web' ? undefined : 5,
        marginRight: react_native_1.Platform.OS === 'web' ? undefined : 5,
        marginTop: react_native_1.Platform.OS === 'web' ? undefined : 5,
    },
    noImage: {
        width: react_native_1.Platform.OS === 'web' ? undefined : 90,
        backgroundColor: react_native_1.Platform.OS === 'web' ? undefined : '#EFEFEE',
        marginHorizontal: react_native_1.Platform.OS === 'web' ? undefined : 5,
        marginVertical: react_native_1.Platform.OS === 'web' ? undefined : 5,
    },
    text: {
        position: 'absolute',
        left: 0,
        right: 0,
        textAlign: 'center',
        bottom: 50,
        color: '#3db384',
    },
    row: {
        flexDirection: 'row',
    },
    iconBubbleContainer: {
        position: react_native_1.Platform.OS === 'web' ? 'absolute' : undefined,
        bottom: react_native_1.Platform.OS === 'web' ? 10 : undefined,
        marginTop: react_native_1.Platform.OS === 'web' ? 10 : 0,
        marginLeft: react_native_1.Platform.OS === 'web' ? 0 : 10,
        marginRight: react_native_1.Platform.OS === 'web' ? undefined : 10,
        width: react_native_1.Platform.OS === 'web' ? 90 : 60,
        justifyContent: react_native_1.Platform.OS === 'web' ? 'space-evenly' : 'flex-end',
        alignSelf: react_native_1.Platform.OS === 'web' ? undefined : 'center',
    },
    iconBubble: {
        backgroundColor: '#fff',
        borderRadius: 25,
        padding: 7.5,
    },
    textAlignCenter: {
        textAlign: 'center',
    },
    textBold: {
        fontWeight: 'bold',
    },
});
