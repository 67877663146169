"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styles = void 0;
const react_native_1 = require("react-native");
exports.styles = react_native_1.StyleSheet.create({
    container: {
        flex: 1,
        height: react_native_1.Platform.OS === 'web' ? '100vh' : '100%',
        marginHorizontal: 'auto',
        marginVertical: 20,
        paddingStart: 25,
        paddingEnd: 25,
        width: react_native_1.Platform.OS === 'web' ? '100vw' : '100%',
        maxWidth: 1080,
        padding: react_native_1.Platform.OS === 'web' ? 0 : 10,
    },
    heading2: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 40,
        textAlign: 'center',
    },
    heading3: {
        fontSize: 20,
        fontWeight: 'bold',
        marginVertical: 20,
        color: '#000',
    },
    row: {
        flexDirection: 'row',
    },
    column: {
        flexDirection: 'column',
    },
    marginTop20: {
        marginTop: 20,
    },
    marginLeft20: {
        marginLeft: 20,
    },
    alignCenter: {
        alignItems: 'center',
    },
    justifyCenter: {
        justifyContent: 'center',
    },
    colorWhite: {
        color: '#fff',
    },
    containerBox: {
        backgroundColor: '#3db384',
        padding: 30,
        borderRadius: 5,
    },
    wrapper: {
        width: '60%',
    },
    button: {
        backgroundColor: '#3db384',
        borderRadius: 24,
        paddingHorizontal: 50,
        paddingVertical: 10,
    },
    buttonText: {
        color: '#fff',
    },
});
